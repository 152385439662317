<adapt-configuration-page-base [configGroups]="configGroups"
                               configTitle="Organisation configuration"
                               (selectedItemChange)="selectedItem = $event">
    <ng-container [ngSwitch]="selectedItem?.area">
        <adapt-configure-organisation-profile *ngSwitchCase="ConfigArea.OrganisationProfile"
                                              [configItem]="selectedItem">
        </adapt-configure-organisation-profile>
        <adapt-configure-custom-welcome-email *ngSwitchCase="ConfigArea.CustomWelcomeEmail"
                                              [configItem]="selectedItem"></adapt-configure-custom-welcome-email>
        <adapt-configure-calendar-integration *ngSwitchCase="ConfigArea.CalendarIntegration"
                                              [configItem]="selectedItem"></adapt-configure-calendar-integration>
        <adapt-configure-external-dashboard *ngSwitchCase="ConfigArea.ExternalLink"
                                            [configItem]="selectedItem">
        </adapt-configure-external-dashboard>
        <adapt-configure-api *ngSwitchCase="ConfigArea.Api"
                             [configItem]="selectedItem">
        </adapt-configure-api>
        <adapt-configure-objectives *ngSwitchCase="ConfigArea.ObjectivesAndKeyResults"
                                    [configItem]="selectedItem">
        </adapt-configure-objectives>
        <adapt-configure-values-constitution *ngSwitchCase="ConfigArea.ValuesConstitution"
                                             [configItem]="selectedItem">
        </adapt-configure-values-constitution>
        <adapt-configure-purpose-and-vision *ngSwitchCase="ConfigArea.PurposeAndVision"
                                            [configItem]="selectedItem">
        </adapt-configure-purpose-and-vision>
        <adapt-configure-career-valuation *ngSwitchCase="ConfigArea.CareerValuation"
                                          [configItem]="selectedItem">
        </adapt-configure-career-valuation>
        <adapt-configure-cultural-index *ngSwitchCase="ConfigArea.CulturalIndex"
                                        [configItem]="selectedItem">
        </adapt-configure-cultural-index>
        <adapt-configure-cultural-leadership *ngSwitchCase="ConfigArea.CulturalLeadership"
                                             [configItem]="selectedItem">
        </adapt-configure-cultural-leadership>
        <adapt-configure-tier1 *ngSwitchCase="ConfigArea.Tier1"
                               [configItem]="selectedItem">
        </adapt-configure-tier1>
        <adapt-configure-tier2 *ngSwitchCase="ConfigArea.Tier2"
                               [configItem]="selectedItem">
        </adapt-configure-tier2>
        <adapt-configure-peer-catch-up *ngSwitchCase="ConfigArea.PeerCatchUp"
                                       [configItem]="selectedItem">
        </adapt-configure-peer-catch-up>
        <adapt-configure-stakeholders *ngSwitchCase="ConfigArea.Stakeholders"
                                      [configItem]="selectedItem">
        </adapt-configure-stakeholders>
        <adapt-configure-work *ngSwitchCase="ConfigArea.Kanban"
                              [configItem]="selectedItem">
        </adapt-configure-work>
        <adapt-configure-meetings *ngSwitchCase="ConfigArea.Meetings"
                                  [configItem]="selectedItem">
        </adapt-configure-meetings>
        <adapt-configure-labels *ngSwitchCase="ConfigArea.Labels"
                                [configItem]="selectedItem"></adapt-configure-labels>
        <adapt-configure-personal-profile *ngSwitchCase="ConfigArea.PersonalProfileCategories"
                                          [configItem]="selectedItem">
        </adapt-configure-personal-profile>
        <adapt-configure-teams *ngSwitchCase="ConfigArea.Team"
                               [configItem]="selectedItem">
        </adapt-configure-teams>
        <adapt-configure-engagement *ngSwitchCase="ConfigArea.EmployeeEngagement"
                                    [configItem]="selectedItem">
        </adapt-configure-engagement>
        <adapt-configure-team-assessment *ngSwitchCase="ConfigArea.TeamAssessment"
                                         [configItem]="selectedItem">
        </adapt-configure-team-assessment>
        <adapt-configure-resilient-business-assessment *ngSwitchCase="ConfigArea.ResilientBusinessAssessment"
                                                       [configItem]="selectedItem">
        </adapt-configure-resilient-business-assessment>
        <adapt-configure-resilient-business-goals *ngSwitchCase="ConfigArea.ResilientBusinessGoals"
                                                  [configItem]="selectedItem">
        </adapt-configure-resilient-business-goals>

        <adapt-configure-generic-feature *ngSwitchDefault
                                         [configItem]="selectedItem"></adapt-configure-generic-feature>
    </ng-container>
</adapt-configuration-page-base>
