import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Feature } from "./feature";
import { Module } from "./module";

export class ModuleFeature extends BaseEntity<ModuleFeature> {
    public moduleId!: number;
    public featureId!: number;

    public module!: Module;
    public feature!: Feature;
}

export const ModuleFeatureBreezeModel = new BreezeModelBuilder("ModuleFeature", ModuleFeature)
    .hasSource()
    .build();
