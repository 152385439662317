import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Account, SubscriptionStatus, SubscriptionSubStatus } from "@common/ADAPT.Common.Model/account/account";
import { BillingPeriod, BillingPeriodButtons } from "@common/ADAPT.Common.Model/account/account-extensions";
import { AccountModule } from "@common/ADAPT.Common.Model/account/account-module";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { ICardPartialDetails } from "@common/payment-processing/card-partial-details.interface";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { IUpdateCreditCardInput, UpdateCreditCardDetailsComponent } from "@common/payment-processing/update-credit-card-details/update-credit-card-details.component";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { EulaService } from "@org-common/lib/eula/eula.service";
import { catchError, EMPTY, forkJoin, Observable, of, switchMap, tap } from "rxjs";
import { finalize } from "rxjs/operators";
import { OrganisationService } from "../../organisation.service";
import { AccountService } from "../account.service";

export interface ISetInitialSubscriptionDialogData {
    account: Account;
    showIntroOnly?: boolean;
    skipIntro?: boolean;
    disallowCancel?: boolean;
}

@Component({
    selector: "adapt-set-initial-subscription-dialog",
    templateUrl: "./set-initial-subscription-dialog.component.html",
    styleUrls: ["./set-initial-subscription-dialog.component.scss"],
})
export class SetInitialSubscriptionDialogComponent extends BaseDialogComponent<ISetInitialSubscriptionDialogData, SubscriptionStatus> implements OnInit {
    public readonly dialogName = "SetInitialSubscriptionDialogComponent";
    public readonly StartSubscriptionArticle = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto
        ? ImplementationKitArticle.StartSubscription
        : ImplementationKitArticle.StartSubscriptionEmbed;
    public readonly BillingPeriodButtons = BillingPeriodButtons;
    public readonly BillingPeriod = BillingPeriod;
    public readonly ProductLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public readonly Now = new Date().getTime();

    public cardSetCorrectly = false;
    public selectedBillingPeriod: BillingPeriod[];

    @ViewChild(UpdateCreditCardDetailsComponent) public updateCreditCardDetailsComponent?: UpdateCreditCardDetailsComponent;

    public creditCardInput: IUpdateCreditCardInput;
    public cardDetails?: ICardPartialDetails;
    public cardDetailsLoading = true;
    public organisation?: Organisation;

    public isIntroPage = true;
    public promptingEula = false;
    public eulaAccepted = false;
    public eulaLoaded = false;
    public account: Account;
    private originalStatus: SubscriptionStatus;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: ISetInitialSubscriptionDialogData,
        private commonDataService: CommonDataService,
        private paymentService: PaymentProcessingService,
        private userService: UserService,
        private directoryService: DirectorySharedService,
        private eulaService: EulaService,
        private accountService: AccountService,
        organisationService: OrganisationService,
    ) {
        super();
        this.account = dialogData.account;
        this.originalStatus = this.account.status;
        this.creditCardInput = {
            cardDetails: undefined,
            organisationIdentifier: {
                organisationId: this.account.organisationId,
                eulaToken: undefined,
            },
        };

        this.isIntroPage = !!dialogData.showIntroOnly || !dialogData.skipIntro;

        this.selectedBillingPeriod = [this.account.billingPeriod];
        organisationService.promiseToGetOrganisation()
            .then((org) => {
                this.organisation = org;
                if (org) {
                    this.eulaService.getEulaIsAcceptedForOrgId(org.organisationId).pipe(
                        this.takeUntilDestroyed(),
                    ).subscribe((isAccepted) => this.promptingEula = !isAccepted && !this.userService.currentPerson!.isCoach());
                }

                // prime invoices so it can figure out the paidUntilDate for the account
                return paymentService.getInvoices(org?.organisationId);
            });
    }

    public ngOnInit() {
        this.getCardDetails().subscribe();
    }

    public get titleAction() {
        if (this.account?.extensions.isPendingCancellation || this.account?.subStatus === SubscriptionSubStatus.SubscriptionCancelled) {
            return "Resume";
        } else if (this.account?.extensions.isActive) {
            return "Update";
        } else {
            return "Start";
        }
    }

    @Autobind
    public acceptEula() {
        // need to reject changes for account first or the entity won't be updated after accept Eula
        // call getCardDetails() again after that to re-init
        return this.commonDataService.rejectChanges(this.account).pipe(
            switchMap(() => this.eulaService.acceptEulaAsLoggedInPerson()),
            switchMap(() => this.getCardDetails()),
            tap(() => this.promptingEula = false),
        );
    }

    @Autobind
    public saveAndClose() {
        if (!this.cardDetails && !this.updateCreditCardDetailsComponent && this.account.extensions.hasSubscriptionCost) {
            this.setErrorMessage("Updating credit card failed. Please contact us.");
            return EMPTY;
        }

        // take a backup of the changed entities to restore to if failing on the server side
        const changedEntities = this.accountService.getChanges();
        const [changedAccount] = changedEntities
            .filter((i) => i instanceof Account && i.organisationId === this.account.organisationId)
            .map((i: Account) => this.cloneAccountEntity(i));
        const changedAccountModules = changedEntities
            .filter((i) => i instanceof AccountModule)
            .map((i) => this.cloneAccountEntity(i));

        // save all first before update credit card or update of account from server when CreateConfirmIntent in saveCreditCard
        // will be overwritten by the outdated account changes
        return this.paymentService.saveEntities().pipe(
            switchMap(() => this.cardSetCorrectly
                ? this.updateCreditCardDetailsComponent?.saveCreditCard() ?? of(undefined)
                : of(undefined)),
            switchMap(() => this.account.extensions.isActive
                ? this.paymentService.updateSubscription(this.account.organisationId)
                : this.paymentService.commenceSubscription(this.account.organisationId)),
            // re-prime invoices as that's not managed by entity sync - needs this to avoid overlapping invoicing
            switchMap(() => this.paymentService.getInvoices(this.account.organisationId, true)),
            tap(() => super.resolve(this.originalStatus)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return this.restoreChangedEntities(changedAccountModules, changedAccount);
            }),
        );
    }

    // only clone the entityAspect fields that I need to restore
    private cloneAccountEntity<T extends IBreezeEntity>(entity: T) {
        const clone = ObjectUtilities.nativeShallowClone(entity);
        (clone as any).entityAspect = {
            originalValues: { ...entity.entityAspect.originalValues },
            entityState: entity.entityAspect.entityState,
        };
        return clone;
    }

    private restoreChangedEntities(changedAccountModules: AccountModule[], changedAccount?: Account) {
        // restoring changed entities
        const compensatingSaveActions: Observable<unknown>[] = [];
        if (changedAccount?.entityAspect?.originalValues) {
            ObjectUtilities.forEach(changedAccount.entityAspect.originalValues, (value, key) => (this.account as any)[key] = value);
        }

        changedAccountModules.forEach((am) => {
            if (am.entityAspect.entityState.isAdded()) {
                const removeAdded = this.account.accountModules.find((i) => i.moduleId === am.moduleId && i.organisationId === am.organisationId);
                if (removeAdded) {
                    compensatingSaveActions.push(this.accountService.remove(removeAdded));
                }
            } else if (am.entityAspect.entityState.isDeleted()) {
                if (!this.account.accountModules.find((i) => i.moduleId === am.moduleId && i.organisationId === am.organisationId)) {
                    // this was previously a delete. not found as it is deleted, so add it back
                    const module = this.account.pricingModel?.pricingModelModules.find((i) => i.moduleId === am.moduleId);
                    if (module) {
                        compensatingSaveActions.push(this.accountService.createAccountModule(this.account, module.module));
                    }
                }
            } else if (am.entityAspect.entityState.isModified() && am.entityAspect?.originalValues) {
                const modifiedModule = this.account.accountModules.find((i) => i.accountModuleId === am.accountModuleId);
                if (modifiedModule) {
                    ObjectUtilities.forEach(am.entityAspect.originalValues, (value, key) => (modifiedModule as any)[key] = value);
                }
            }
        });

        if (changedAccount || compensatingSaveActions.length || this.account.accountModules.some((am) => !am.entityAspect.entityState.isUnchanged())) {
            return forkJoin(compensatingSaveActions.length ? compensatingSaveActions : [of(undefined)]).pipe(
                switchMap(() => this.accountService.saveEntities()),
                switchMap(() => {
                    // redoing changes without saving
                    if (changedAccount?.entityAspect?.originalValues) {
                        Object.keys(changedAccount.entityAspect.originalValues).forEach((key) => (this.account as any)[key] = (changedAccount as any)[key]);
                    }

                    const restoreActions: Observable<unknown>[] = [];
                    changedAccountModules.forEach((am) => {
                        if (am.entityAspect.entityState.isAdded()) {
                            if (!this.account.accountModules.find((i) => i.moduleId === am.moduleId && i.organisationId === am.organisationId)) {
                                const module = this.account.pricingModel?.pricingModelModules.find((i) => i.moduleId === am.moduleId);
                                if (module) {
                                    const recreateAccountModule = this.accountService.createAccountModule(this.account, module.module).pipe(
                                        tap((newAm) => newAm.quantity = am.quantity),
                                    );
                                    restoreActions.push(recreateAccountModule);
                                }
                            }
                        } else if (am.entityAspect.entityState.isDeleted()) {
                            const removeModule = this.account.accountModules.find((i) => i.moduleId === am.moduleId && i.organisationId === am.organisationId);
                            if (removeModule) {
                                restoreActions.push(this.accountService.remove(removeModule));
                            }
                        } else if (am.entityAspect.entityState.isModified() && am.entityAspect?.originalValues) {
                            const modifiedModule = this.account.accountModules.find((i) => i.accountModuleId === am.accountModuleId);
                            if (modifiedModule) {
                                Object.keys(am.entityAspect.originalValues).forEach((key) => (modifiedModule as any)[key] = (am as any)[key]);
                            }
                        }
                    });

                    return restoreActions.length ? forkJoin(restoreActions) : EMPTY;
                }),
            );
        } else {
            return EMPTY;
        }
    }

    private getCardDetails() {
        this.setErrorMessage(undefined);
        this.cardDetailsLoading = true;

        return this.paymentService.getCreditCardPartialDetails(this.creditCardInput.organisationIdentifier)
            .pipe(
                switchMap(async (cardDetails) => {
                    if (!this.account.contactEmail || !this.account.contactName) {
                        if (this.userService.currentPerson) {
                            // need to prime person contacts there is no email
                            await this.directoryService.promiseToGetContactDetailsByPersonId(this.userService.currentPerson.personId);
                            this.account.contactName = this.userService.currentPerson.fullName;
                            this.account.contactEmail = this.userService.currentPerson.getLoginEmail()?.value;
                        }
                    }

                    return cardDetails;
                }),
                tap((cardDetails) => {
                    this.cardDetails = cardDetails;
                    this.cardSetCorrectly = !!cardDetails;
                }),
                catchError((errorMessage: string) => {
                    this.setErrorMessage(errorMessage);
                    return EMPTY;
                }),
                finalize(() => this.cardDetailsLoading = false),
            );
    }

    public onSelect(billingPeriod: BillingPeriod) {
        this.account.billingPeriod = billingPeriod;
    }
}
