<ng-container *adaptLoading="!runData">
    <div [froalaView]="runData!.text"
         class="mb-3"></div>

    <dx-text-box #passwordInput
                 id="password"
                 mode="password"
                 [inputAttr]="{ 'aria-label': 'password' }"
                 [(value)]="password"
                 (onValueChanged)="validatePassword(passwordInput)"
                 placeholder="Password"
                 data-test="password-input">
        <dx-validator>
            <dxi-validation-rule type="required"
                                 message="Password is required"></dxi-validation-rule>
        </dx-validator>
    </dx-text-box>
</ng-container>
