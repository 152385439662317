<div class="alert alert-info"
     role="alert">
    <p>Secure your {{projectLabel}} account with two-factor authentication via a mobile app.</p>
    <a *ngIf="articleUrl"
       [href]="articleUrl"
       rel="noreferrer noopener"
       target="_blank">Find out more about two-factor authentication (2FA/MFA) in {{projectLabel}}</a>
</div>

<p>When two factor authentication is enabled, you will be prompted for a secure, random token when you log into your {{projectLabel}}
    account.
    You may retrieve this token from the Google Authenticator, Microsoft Authenticator, or similar mobile apps.</p>

<ng-container *adaptLoading="!manageTwoFactorStatus">
    @if (twoFactorDisabledAlert) {
        <bs-alert type="warning"
                  [dismissible]="true"
                  (onClosed)="twoFactorDisabledAlert = false">
            Two-factor authentication has been disabled for your {{projectLabel}} account.
        </bs-alert>
    }

    @if (twoFactorEnabledAlert) {
        <bs-alert type="success"
                  [dismissible]="true"
                  (onClosed)="twoFactorEnabledAlert = false">
            Two-factor authentication has been enabled for your {{projectLabel}} account.
        </bs-alert>
    }

    @if (!manageTwoFactorStatus!.isTwoFactorEnabled) {
        <p><strong>Two-factor authentication is not currently enabled for your account.</strong></p>

        <button adaptButtonIcon="fal fa-shield-keyhole"
                (click)="setUpTwoFactor()"
                data-test="two-factor-set-up">Set up</button>
    } @else {
        <p><strong>Two-factor authentication is currently enabled for your account.</strong></p>

        <div class="d-flex flex-column flex-md-row gap-2">
            <button class="btn btn-secondary"
                    adaptButtonIcon="fal fa-sync"
                    (click)="regenerateRecoveryCodes()"
                    data-test="two-factor-regenerate-recovery-codes">Regenerate recovery codes</button>

            <!-- ms-0 here as common-button.scss will add a left margin of 0.25rem - gap here is managed by the container's gap -->
            <button class="btn btn-danger ms-0"
                    adaptButtonIcon="fal fa-key"
                    (click)="disableTwoFactor()"
                    data-test="two-factor-disable">Disable two-factor authentication</button>
        </div>
    }
</ng-container>
