import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Person } from "../../ADAPT.Common.Model/person/person";
import { IdentityService } from "../../identity/identity.service";
import { IIdentityViewModel } from "../../identity/identity-view-model.interface";
import { ErrorHandlingUtilities } from "../../lib/utilities/error-handling-utilities";
import { RouteService } from "../../route/route.service";
import { UserService } from "../../user/user.service";
import { AdaptCommonDialogService } from "../../ux/adapt-common-dialog/adapt-common-dialog.service";
import { IdentityStorageService, ISessionData } from "../identity-storage.service";

export interface IReason {
    type: string;
    message: string;
}

export interface ILoginPayload extends IIdentityViewModel {
    userName: string;
    password: string;
    twoFactorCode?: string;
    twoFactorRecoveryCode?: string;
    grant_type?: string;
}

export interface IForgotPasswordPayload extends IIdentityViewModel {
    email: string;
}

export interface IResetPasswordPayload extends IIdentityViewModel {
    email: string;
    newPassword: string;
    token: string;
}

export interface IChangePasswordPayload extends IIdentityViewModel {
    oldPassword: string;
    newPassword: string;
}

export enum ReasonCode {
    resetPassword = "resetPassword",
    requireLogin = "requireLogin",
    externalLoginFailed = "externalLoginFailed",
    externalLoginDenied = "externalLoginDenied",
    externalLoginConsent = "externalLoginConsent",
}

@Injectable({
    providedIn: "root",
})
export class IdentityUxService {
    private anotherTabSubscription?: Subscription;

    constructor(
        public identityService: IdentityService,
        public userService: UserService,
        public routeService: RouteService,
        private dialogService: AdaptCommonDialogService,
        private identityStorage: IdentityStorageService,
    ) {
    }

    public registerForAnotherTabEvents() {
        if (!this.anotherTabSubscription) {
            this.anotherTabSubscription = this.identityService.identityChangedInAnotherTab$.pipe(
                switchMap((i) => {
                    const title = `You logged ${i.isLoggedIn ? "in" : "out"} with another tab`;
                    const message = `Refresh the page to continue using the ADAPT Platform.`;
                    return this.dialogService.showMessageDialog(title, message, "Refresh");
                }),
            ).subscribe(() => {
                location.reload();
            });
        }
    }

    public getReason(reasonCode: string) {
        const result: IReason = {
            type: "danger",
            message: `Unknown error. Reason code: '${reasonCode}'`,
        };

        switch (reasonCode) {
            case ReasonCode.resetPassword:
                result.type = "success";
                result.message = "Password successfully reset. Login to continue.";
                break;
            case ReasonCode.requireLogin:
                result.type = "warning";
                result.message = "This page requires authentication. Login to continue.";
                break;
            case ReasonCode.externalLoginFailed:
                result.type = "danger";
                result.message = "Unable to load external account information. Please try logging in again.";
                break;
            case ReasonCode.externalLoginConsent:
                result.type = "danger";
                result.message = "You must consent to the required permissions for your external account. Please try logging in again.";
                break;
            case ReasonCode.externalLoginDenied:
                result.type = "danger";
                result.message = "There was a problem authorising your external account. Please try logging in again.";
                break;
        }

        return result;
    }

    public async promiseToLogin(data: ILoginPayload, redirectController?: string) {
        try {
            const sessionData = await this.identityService.login(data);
            return this.promiseToLoginWithResponse(sessionData.body ?? undefined, redirectController);
        } catch (e) {
            return this.interpretError(e as HttpErrorResponse);
        }
    }

    public async promiseToLoginWithResponse(data?: ISessionData, redirectController?: string) {
        const handler = (person?: Person) => {
            if (person) {
                subscription.unsubscribe();

                if (redirectController) {
                    this.routeService.gotoControllerRoute(redirectController);
                } else {
                    this.routeService.gotoRedirect();
                }
            }
        };

        const subscription = this.userService.userChanged$.subscribe(handler);

        try {
            this.identityStorage.sessionData = data || undefined;
            await this.identityService.validateAuthenticationToken();
        } catch (e) {
            subscription.unsubscribe();
            return this.interpretError(e as HttpErrorResponse);
        }
    }

    public async promiseToSendForgotPassword(data: IForgotPasswordPayload) {
        try {
            await this.identityService.forgotPassword(data);
        } catch (e) {
            return this.interpretError(e as HttpErrorResponse);
        }
    }

    public async promiseToResetPassword(data: IResetPasswordPayload) {
        try {
            await this.identityService.resetPassword(data);
        } catch (e) {
            return this.interpretError(e as HttpErrorResponse);
        }
    }

    private interpretError(data: HttpErrorResponse) {
        return Promise.reject(ErrorHandlingUtilities.getHttpResponseMessage(data.error));
    }
}
