import { NgModule } from "@angular/core";
import { mapToCanActivate, RouterModule, Routes } from "@angular/router";
import { IdentityUxModule } from "@common/identity/ux/identity-ux.module";
import { AdaptCommonPagesModule } from "@common/lib/pages/pages.module";
import { AdaptBullseyeModule } from "@org-common/lib/bullseye/bullseye.module";
import { AdaptCommonConfigurationModule } from "@org-common/lib/configuration/configuration.module";
import { AdaptErrorPageModule } from "@org-common/lib/error-page/error-page.module";
import { AdaptEulaModule } from "@org-common/lib/eula/eula.module";
import { AdaptGuidingPhilosophyModule } from "@org-common/lib/guiding-philosophy/guiding-philosophy.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { provideTeamMeetingsPageRoute } from "@org-common/lib/meetings/meetings.service";
import { teamMeetingsPageRoute } from "@org-common/lib/meetings/team-meetings-page/team-meetings-page.route";
import { AdaptMyOrganisationsModule } from "@org-common/lib/my-organisations/my-organisations.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptOrgCommonPagesModule } from "@org-common/lib/pages/pages.module";
import { AdaptResilientBusinessGoalsModule } from "@org-common/lib/rb-goals/rb-goals.module";
import { BaseGuard } from "@org-common/lib/route/base.guard";
import { AdaptSearchCommonModule } from "@org-common/lib/search/search.module";
import { AdaptStrategicAnchorsModule } from "@org-common/lib/strategic-anchors/strategic-anchors.module";
import { AdaptStrategicGoalsModule } from "@org-common/lib/strategic-goals/strategic-goals.module";
import { AdaptStrategyDashboardPageModule } from "@org-common/lib/strategy-dashboard-page/strategy-dashboard-page.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { environment } from "environments/environment";
import { AdaptCumulusAboutModule } from "./about/about.module";
import { AppComponent } from "./app.component";
import { AdaptIntegratedArchitectureModule } from "./features/architecture/integrated-architecture/integrated-architecture.module";
import { AdaptKeyFunctionsModule } from "./features/architecture/key-functions/key-functions.module";
import { AdaptProcessMapModule } from "./features/architecture/process-map/process-map.module";
import { AdaptRoleModule } from "./features/architecture/role/role.module";
import { AdaptTier1ArchitectureModule } from "./features/architecture/tier1-architecture/tier1-architecture.module";
import { AdaptValueStreamsModule } from "./features/architecture/value-streams/value-streams.module";
import { AdaptCulturalLeadershipModule } from "./features/culture/cultural-leadership/cultural-leadership.module";
import { AdaptCareerValuationModule } from "./features/people/career-valuation/career-valuation.module";
import { AdaptCulturalIndexModule } from "./features/people/cultural-index/cultural-index.module";
import { AdaptDirectoryModule } from "./features/people/directory/directory.module";
import { AdaptPeerCatchupModule } from "./features/people/peer-catchup/peer-catchup.module";
import { AdaptPersonModule } from "./features/people/person/person.module";
import { AdaptTeamsModule } from "./features/people/teams/teams.module";
import { AdaptProductCatalogueModule } from "./features/product/product-catalogue/product-catalogue.module";
import { AdaptStakeholderModule } from "./features/stakeholders/stakeholder.module";
import { AdaptSystemisationModule } from "./features/systemisation/systemisation.module";
import { AdaptAppOrganisationModule } from "./organisation/app.organisation.module";
import { AdaptConfigurationModule } from "./platform/configuration/configuration.module";
import { AdaptPersonalDashboardModule } from "./platform/personal-dashboard/personal-dashboard.module";
import { AdaptTeamDashboardModule } from "./platform/team-dashboard/team-dashboard.module";

const routes: Routes = [
    ...AdaptCommonPagesModule.Routes,
    ...AdaptOrgCommonPagesModule.Routes,
    ...AdaptCommonConfigurationModule.Routes,
    ...IdentityUxModule.Routes,
    ...AdaptCumulusAboutModule.Routes,
    ...AdaptIntegratedArchitectureModule.Routes,
    ...AdaptKeyFunctionsModule.Routes,
    ...AdaptProcessMapModule.Routes,
    ...AdaptRoleModule.Routes,
    ...AdaptTier1ArchitectureModule.Routes,
    ...AdaptValueStreamsModule.Routes,
    ...AdaptCulturalLeadershipModule.Routes,
    ...AdaptValuesConstitutionModule.Routes,
    ...AdaptGuidingPhilosophyModule.Routes,
    ...AdaptObjectivesModule.Routes,
    ...AdaptResilientBusinessGoalsModule.Routes,
    ...AdaptCareerValuationModule.Routes,
    ...AdaptCulturalIndexModule.Routes,
    ...AdaptDirectoryModule.Routes,
    ...AdaptPeerCatchupModule.Routes,
    ...AdaptPersonModule.Routes,
    ...AdaptTeamsModule.Routes,
    ...AdaptProductCatalogueModule.Routes,
    ...AdaptStakeholderModule.Routes,
    ...AdaptKanbanModule.Routes,
    ...AdaptCommonMeetingsModule.Routes,
    ...AdaptSystemisationModule.Routes,
    ...AdaptAppOrganisationModule.Routes,
    ...AdaptConfigurationModule.Routes,
    ...AdaptPersonalDashboardModule.Routes,
    ...AdaptTeamDashboardModule.Routes,
    ...AdaptMyOrganisationsModule.Routes,
    ...AdaptEulaModule.Routes,
    ...AdaptSearchCommonModule.Routes,
    ...AdaptSurveyModule.CommonRoutes,
    ...AdaptSurveyModule.EngagementRoutes,
    ...AdaptSurveyModule.TeamAssessmentRoutes,
    ...AdaptSurveyModule.ResilientAssessmentRoutes,
    ...AdaptErrorPageModule.Routes,
    ...AdaptBullseyeModule.Routes,
    ...AdaptStrategicGoalsModule.Routes,
    ...AdaptStrategyDashboardPageModule.Routes,
    ...AdaptStrategicAnchorsModule.Routes,
    { path: "", pathMatch: "full", children: [], canActivate: mapToCanActivate([BaseGuard]) }, // BaseGuard will determine where to forward depends on login or not
    AppComponent.DefaultOrganisationRoute,
    { path: "**", redirectTo: "" }, // any other pages -> go to default
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: !environment.production })],
    exports: [RouterModule],
    providers: [
        provideTeamMeetingsPageRoute(teamMeetingsPageRoute),
    ],
})
export class AppRoutingModule { }
