<adapt-dialog [maxWidth]="dialogWidth"
              [expandable]="true"
              [fitHeight]="true"
              (fullscreenChange)="onFullscreenToggled($event)"
              (shown)="updateDimensions()">
    <div adapt-dialog-title>
        <h3 *ngIf="!currentWorkflowStep?.hideTitle && dialogTitle as title"
            data-test="workflow-run-dialog-title">
            <i *ngIf="data.workflow.iconStyle"
               class="me-3"
               [ngClass]="[data.workflow.iconStyle]"></i>
            {{title}}
        </h3>
    </div>

    <ng-container adapt-dialog-menu>
        <span *ngIf="stepLocation as location"
              class="step-count me-3 d-none d-sm-inline">{{location}}</span>

        <ng-container *ngIf="currentWorkflowStep?.titleRightButtons">
            <button *ngFor="let buttonDef of currentWorkflowStep!.titleRightButtons"
                    class="btn btn-primary btn-pill"
                    [adaptButtonIcon]="buttonDef.icon"
                    (click)="handleButtonClick(buttonDef.onClick)">{{buttonDef.buttonText}}</button>
        </ng-container>

        <button *ngIf="currentWorkflowStep && !currentWorkflowStep.isConfirmationStep"
                type="button"
                data-test="workflow-run-dialog-close"
                class="ms-2 btn-close order-last"
                (click)="data.dismissDialog ? showDismissDialog() : cancel()"
                aria-label="Close"></button>
    </ng-container>

    <div adapt-dialog-content
         class="h-100 d-flex flex-column">
        <div class="workflow-step-content">
            <ng-container *ngIf="isCompleted && !!wrapUpSlug">
                <div class="wrap-up-content">
                    <h1 class="wrap-up-heading text-center pt-3 mb-0">
                        <i class="fal fa-circle-check fa-4x"></i>
                    </h1>

                    <adapt-styled-implementation-kit [attr.data-test]="'workflow-run-wrapup-slug-' + wrapUpSlug"
                                                     [articleId]="wrapUpSlug"
                                                     contentStyle="embedded"></adapt-styled-implementation-kit>
                </div>
                <div class="my-auto step-component">
                    <h3>Please rate this pathway</h3>
                    <div>
                        <adapt-rating-stars [(rating)]="workflowRating.rating"></adapt-rating-stars>
                    </div>

                    <div class="form-group">
                        <label>What did you like about this pathway?</label>
                        <dx-text-area [(value)]="workflowRating.liked"></dx-text-area>
                    </div>

                    <div class="form-group">
                        <label>How could we improve this pathway?</label>
                        <dx-text-area [(value)]="workflowRating.improveOn"></dx-text-area>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="currentWorkflowStep && !isCompleted">
                <div *ngIf="currentWorkflowStep!.simpleHtml"
                     class="mb-3"
                     [froalaView]="currentWorkflowStep!.simpleHtml"></div>

                <div *ngIf="currentWorkflowStep!.isOutcomeStep || currentWorkflowStep!.isActivityStep; else showKit">
                    <adapt-display-workflow-outcome-header [style]="currentWorkflowStep!.isOutcomeStep ? 'burgundy' : 'wise-lime'"
                                                           [small]="true"
                                                           data-test="workflow-dialog-outcome"
                                                           [workflow]="currentWorkflowStep!.workflow.extensions.rootWorkflow"></adapt-display-workflow-outcome-header>
                    <div *ngIf="currentWorkflowStep!.articleSlug"
                         class="mt-3"
                         data-test="workflow-dialog-article-slug">
                        <adapt-implementation-kit-content [attr.data-test]="'workflow-run-article-slug-' + currentWorkflowStep!.articleSlug"
                                                          [articleId]="currentWorkflowStep!.articleSlug"></adapt-implementation-kit-content>
                    </div>
                </div>
                <ng-template #showKit>
                    <div *ngIf="currentWorkflowStep!.articleSlug"
                         [adaptCollapsible]="currentWorkflowStep!.articleSlug"
                         (adaptCollapsibleExpanded)="updateDimensions()"
                         class="implementation-kit"
                         [ngClass]="{'implementation-kit-top': currentWorkflowStep!.articlePosition === WorkflowStepGuidancePosition.Top,
                            'not-full-width': !fullWidthImplementationKit}">
                        <dx-scroll-view #implementationKitScrollView
                                        [height]="isMobile ? 'auto' : undefined"
                                        showScrollbar="always">
                            <adapt-styled-implementation-kit [articleId]="currentWorkflowStep!.articleSlug"
                                                             [contentStyle]="'embedded'"
                                                             [attr.data-test]="'workflow-run-scroll-article-slug-' + currentWorkflowStep!.articleSlug"
                                                             (contentLoaded)="updateDimensions()"></adapt-styled-implementation-kit>
                        </dx-scroll-view>
                    </div>
                </ng-template>

                <div *ngIf="currentWorkflowStep!.componentSelector || currentWorkflowStep!.deepDiveId || errorMessage"
                     class="step-component"
                     [ngClass]="{'overflow-hidden': !!currentWorkflowStep!.componentMinWidth,
                        'not-full-width': !fullWidthImplementationKit && !(currentWorkflowStep!.isOutcomeStep || currentWorkflowStep!.isActivityStep)}"
                     [style.min-width]="currentWorkflowStep!.componentMinWidth">
                    <dx-scroll-view #stepComponentScrollView
                                    [height]="isMobile ? 'auto' : undefined"
                                    showScrollbar="always">
                        <ng-container *ngIf="currentWorkflowStep!.componentSelector">
                            <ng-container #container></ng-container>
                        </ng-container>

                        <ng-container *ngIf="currentWorkflowStep!.deepDiveId">
                            <adapt-deep-dive [deepDiveId]="currentWorkflowStep!.deepDiveId"
                                             (opened)="currentStepCompleted = true"></adapt-deep-dive>
                        </ng-container>

                        <div *ngIf="errorMessage"
                             class="error-message alert alert-danger">{{errorMessage}}</div>
                    </dx-scroll-view>
                </div>
            </ng-container>
        </div>
    </div>

    <div adapt-dialog-footer
         class="workflow-step-footer"
         *ngIf="currentWorkflowStep">
        <!-- <p class="mb-0 text-transform-none">{{stepLocation}}</p> -->

        <!-- <div *ngIf="data.workflow.showBreadcrumbs">
           <adapt-stepped-progress-bar [showTitles]="data.workflow.showBreadcrumbTitles"
                                       [steps]="progressSteps"
                                       [currentStepIdx]="currentWorkflowStep.ordinal"></adapt-stepped-progress-bar>
       </div> -->

        <ng-container *ngIf="!isCompleted; else completedButtons">
            <button class="btn btn-outline-primary"
                    data-test="workflow-run-dialog-back"
                    *ngIf="!isFirstStep && currentWorkflowStep!.allowBack"
                    [disabled]="!currentWorkflowStep!.allowBack || isBusy"
                    [adaptBlockingClick]="blockingPrevious">
                <i class="fal fa-fw fa-chevron-left me-2"></i>
                <ng-container *ngIf="currentWorkflowStep.backButtonText; else defaultBackContent">
                    {{currentWorkflowStep.backButtonText}}</ng-container>
                <ng-template #defaultBackContent>
                    Back
                    <span *ngIf="previousWorkflowStep as prevStep" class="d-none d-sm-inline"> - {{prevStep.name}}</span>
                </ng-template>
            </button>

            <ng-container *ngIf="footerLeft as items">
                <div class="d-flex gap-1 me-auto">
                    <ng-container *ngFor="let item of items"
                                  [ngTemplateOutlet]="item.template"></ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="footerCenter as items">
                <div class="d-flex gap-1 mx-auto">
                    <ng-container *ngFor="let item of items"
                                  [ngTemplateOutlet]="item.template"></ng-container>
                </div>
            </ng-container>

            <div class="d-flex gap-1 ms-auto">
                <ng-container *ngIf="footerRight as items">
                    <ng-container *ngFor="let item of items"
                                  [ngTemplateOutlet]="item.template"></ng-container>
                </ng-container>

                <button *ngIf="!isLastStep && currentWorkflowStep!.canSkip && !currentStepCompleted"
                        class="btn btn-outline-secondary border-0"
                        [disabled]="isBusy || !currentStepSkippable"
                        (click)="skip()"
                        data-test="workflow-skip">
                    Skip <i class="fal fa-fw fa-arrow-right-to-line ms-2"></i>
                </button>

                <button class="btn btn-secondary"
                        *ngIf="currentWorkflowStep?.showCancelButton"
                        (click)="cancel()">
                    {{currentWorkflowStep.cancelButtonText ?? "Close"}}</button>
                <button [ngClass]="nextStepClass"
                        *ngIf="!isLastStep"
                        [disabled]="!currentStepCompleted || isBusy"
                        [adaptBlockingClick]="blockingNext"
                        data-test="workflow-next">
                    {{nextStepText}}
                    <i *ngIf="!currentWorkflowStep!.closeAfterNext"
                       class="ms-2"
                       [ngClass]="currentWorkflowStep!.workflowStepNextIcon ?? 'fal fa-fw fa-chevron-right'"></i>
                </button>
                <button [ngClass]="nextStepClass"
                        *ngIf="isLastStep"
                        [disabled]="(!currentStepCompleted && !currentWorkflowStep!.canSkip) || isBusy"
                        [adaptBlockingClick]="complete"
                        [unsubscribeOnDestroy]="false"
                        data-test="workflow-close">
                    {{closeText}}
                    <i *ngIf="currentWorkflowStep!.workflowStepNextIcon as icon"
                       class="ms-2"
                       [ngClass]="icon"></i>
                </button>
            </div>
        </ng-container>
        <ng-template #completedButtons>
            <div class="d-flex ms-auto gap-3">
                <button class="btn btn-primary"
                        *ngIf="!!wrapUpTour && !isMobile"
                        (click)="runWrapUpTour()">{{canSubmitRating() ? 'Send feedback & ' : ''}}Start tour
                </button>

                <button class="btn btn-primary"
                        *ngIf="!wrapUpTour || isMobile"
                        (click)="closeDialog()"
                        data-test="workflow-close">{{canSubmitRating() ? 'Send feedback & ' : ''}}Close
                </button>
            </div>
        </ng-template>
    </div>
</adapt-dialog>
