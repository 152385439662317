<ng-container *adaptLoading="!runData?.response">
    @if (runData?.successMessage) {
        <div class="alert alert-success">{{runData?.successMessage}}</div>
    }

    <h3>Review and save your two-factor authentication recovery codes</h3>

    @if (runData?.response?.recoveryCodes) {
        <p>If you get locked out of your account, you can restore access with any of the following one-time use codes.
            It is important to save these now and keep them in a safe place.</p>

        <dx-text-area #recoveryCodes
                      class="font-monospace"
                      [inputAttr]="{ rows: 10 }"
                      [value]="(runData?.response?.recoveryCodes ?? []).join('\n')"
                      [readOnly]="true"
                      data-test="two-factor-recovery-codes"></dx-text-area>

        <div class="d-flex gap-3 mt-3">
            <button class="btn btn-link"
                    adaptButtonIcon="fal fa-fw fa-download"
                    (click)="downloadCodes(recoveryCodes.value)">Download</button>
            <button class="btn btn-link"
                    adaptButtonIcon="fal fa-fw fa-print"
                    (click)="print()">Print</button>
            <button class="btn btn-link"
                    adaptButtonIcon="fal fa-fw fa-copy"
                    (click)="copyToClipboard(recoveryCodes.value)">{{copiedRecoveryCodes ? "Copied to clipboard" : "Copy"}}</button>
        </div>
    } @else {
        <p>Your recovery codes have already been shown to you.</p>
        <p>If you have lost them, you can regenerate them from the "Account login & security" configuration page.</p>
    }
</ng-container>
