<div class="row"
     *ngIf="invoices.length > 0">
    <div class="col-12">
        <div class="card">
            <div class="card-header"
                 *ngIf="showHeader">
                <h3 class="card-title">Payment history</h3>
                <div class="card-header-buttons">
                    <div class="dx-datagrid-export-button ">
                        <button adaptButtonIcon="dx-icon dx-icon-xlsxfile"
                                title="Export all data"
                                (click)="exportAllData()"></button>
                    </div>
                    <div>
                        <button adaptButtonIcon="dx-icon dx-icon-column-chooser"
                                title="Column Chooser"
                                (click)="showColumnChooser()"
                                [hidden]="!displayAdvancedOptions"></button>
                    </div>
                </div>
            </div>

            <div class="card-body"
                 *adaptLoading="!organisation">
                <dx-data-grid [dataSource]="invoices"
                              [allowColumnReordering]="displayAdvancedOptions"
                              [allowColumnResizing]="true"
                              [columnAutoWidth]="true"
                              [rowAlternationEnabled]="true">

                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true"
                               [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    <dxo-filter-row [visible]="displayAdvancedOptions"></dxo-filter-row>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>

                    <dxi-column dataField="invoiceId"
                                caption="InvoiceId"
                                sortOrder="desc"
                                [visible]="displayAdvancedOptions"
                                alignment="left"></dxi-column>
                    <dxi-column dataField="invoiceString"
                                caption="Invoice #"
                                alignment="left"></dxi-column>
                    <dxi-column dataField="accountId"
                                caption="Account Id"
                                [visible]="false"
                                alignment="left"></dxi-column>
                    <dxi-column dataField="date"
                                caption="Date issued"
                                [format]="dateFormat"
                                alignment="left"></dxi-column>
                    <dxi-column dataField="periodStartDate"
                                caption="Period Start"
                                [format]="dateFormat"
                                [visible]="displayAdvancedOptions"
                                alignment="left"></dxi-column>
                    <dxi-column dataField="periodEndDate"
                                caption="Period End"
                                [format]="dateFormat"
                                [visible]="displayAdvancedOptions"
                                alignment="left"></dxi-column>
                    <dxi-column caption="Payment method"
                                [visible]="displayAdvancedOptions"
                                cellTemplate="paymentMethodCellTemplate"
                                alignment="left"></dxi-column>
                    <dxi-column caption="Stripe Transaction Id"
                                cellTemplate="transactionCellTemplate"
                                [visible]="displayAdvancedOptions"
                                alignment="left"></dxi-column>
                    <dxi-column dataField="type"
                                [calculateCellValue]="calculateTypeCellValue"
                                caption="Type"
                                alignment="left"></dxi-column>
                    <dxi-column cellTemplate="statusCellTemplate"
                                caption="Status"
                                alignment="left"></dxi-column>
                    <dxi-column dataField="total"
                                caption="Total due"
                                format="currency"
                                alignment="left"></dxi-column>
                    <dxi-column caption="Download"
                                cellTemplate="downloadCellTemplate"
                                fixedPosition="right"
                                [allowReordering]="false"
                                [allowHiding]="false"
                                alignment="left">
                    </dxi-column>

                    <div *dxTemplate="let item of 'downloadCellTemplate'">
                        <button *ngIf="item.data.invoiceItems.length > 0; else noInvoice"
                                adaptButton="downloadLink"
                                [adaptBlockingClick]="onClickExport"
                                [adaptBlockingClickParam]="item.data"
                                data-test="export-data-button"></button>
                        <ng-template #noInvoice>
                            <!-- This is only shown in nimbus as all lines with invalid invoices will be filtered out on cumulus -->
                            <span>N/A</span>
                        </ng-template>
                    </div>

                    <div *dxTemplate="let item of 'paymentMethodCellTemplate'">
                        {{getPaymentMethod(item.data.paymentMethod)}}
                    </div>

                    <div *dxTemplate="let item of 'transactionCellTemplate'">
                        {{getTransactionId(item.data)}}
                    </div>

                    <div *dxTemplate="let item of 'statusCellTemplate'">
                        <div class="status badge"
                             [ngClass]="{'bg-success': item.data.status === 'Paid', 'bg-warning': item.data.status === 'Pending', 'bg-danger': item.data.status === 'Unpaid', 'bg-secondary': item.data.status === 'Archived'}">
                            {{item.data.status}}
                        </div>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>
</div>
