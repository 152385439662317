import { PricingModel } from "./pricing-model";

export class PricingModelExtensions {

    public constructor(private pricingModel: PricingModel) { }

    /**
     * Whether this pricing model is free - based on all components having a zero fee (fixed, user & module)
     */
    public get isFree() {
        return this.pricingModel.monthlyFeeDollars === 0
            && this.pricingModel.pricingModelUsers.length === 0
            && this.pricingModel.pricingModelModules.filter((pmm) => pmm.monthlyFeeDollars > 0).length === 0;
    }

    /**
     * Whether this pricing model is based on a module type model (ie. HQ)
     */
    public get hasModules() {
        return this.pricingModel.pricingModelModules.length > 0;
    }
}
