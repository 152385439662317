import { Directive, OnDestroy, OnInit } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ConfigurationService, ICustomConfigurationHandler } from "@org-common/lib/configuration/configuration.service";
import { ObservableInput, of } from "rxjs";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ConfigureFeatureBase extends BaseComponent implements OnInit, OnDestroy, ICustomConfigurationHandler {

    constructor(
        public configurationService: ConfigurationService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.configurationService.registerCustomHandler(this);

        await this.initialiseData();
        this.configurationService.initialiseConfigPage$
            .pipe(this.takeUntilDestroyed())
            .subscribe(() => this.initialiseData());
    }

    public ngOnDestroy() {
        this.configurationService.removeCustomHandler(this);
        super.ngOnDestroy();
    }

    public abstract initialiseData(): Promise<any>;

    public shouldShowSaveButtons() {
        return true;
    }

    public hasChanges() {
        return false;
    }

    public changesAreValid() {
        return true;
    }

    public onSave(): ObservableInput<unknown> {
        return of();
    }

    public onCancel(): ObservableInput<unknown> {
        return of();
    }
}
