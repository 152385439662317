import { Component, ElementRef, Inject, InjectionToken, Injector, Input, OnInit, Optional, Provider } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { ShowBullseyeDialogComponent } from "@org-common/lib/bullseye/show-bullseye-dialog/show-bullseye-dialog.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { forkJoin, Observable, of, tap } from "rxjs";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { CommonPurposeVisionAuthService } from "../../purpose-vision/common-purpose-vision-auth.service";
import { StrategyAuthService } from "../strategy-auth.service";

// do these provider injections as we can't call PurposeVisionUiService directly which is still in alto!
type ShowGuidanceAction = (injector: Injector) => Observable<unknown>;
const SHOW_OWNERS_WHY_ACTION = new InjectionToken<ShowGuidanceAction>("SHOW_OWNERS_WHY_ACTION");
const SHOW_OWNERS_ALIGNMENT_ACTION = new InjectionToken<ShowGuidanceAction>("SHOW_OWNERS_ALIGNMENT_ACTION");

export function provideShowOwnersWhyAction(action: ShowGuidanceAction): Provider {
    return {
        provide: SHOW_OWNERS_WHY_ACTION,
        useValue: action,
        multi: false,
    };
}

export function provideShowOwnersAlignmentAction(action: ShowGuidanceAction): Provider {
    return {
        provide: SHOW_OWNERS_ALIGNMENT_ACTION,
        useValue: action,
        multi: false,
    };
}

@Component({
    selector: "adapt-strategic-guidance",
    templateUrl: "./strategic-guidance.component.html",
    styleUrls: ["./strategic-guidance.component.scss"],
})
export class StrategicGuidanceComponent extends BaseComponent implements OnInit {
    public readonly FeatureName = FeatureName;

    @Input() public articleId?: ImplementationKitArticle;
    @Input() public area?: string;

    public hidePurposeVision = false;
    public guidingPhilosophy?: GuidingPhilosophy;
    public purpose?: Purpose;
    public bullseye?: Bullseye;

    public hasOwnerAlignFeature = false;
    private hasBullseyeRead = false;

    public constructor(
        elementRef: ElementRef,
        featuresService: FeaturesService,
        private commonPurposeVisionService: CommonPurposeVisionService,
        private dialogService: AdaptCommonDialogService,
        private bullseyeService: BullseyeService,
        private injector: Injector,
        private authorisationService: AuthorisationService,
        @Optional() @Inject(SHOW_OWNERS_WHY_ACTION) public showOwnersWhyAction?: ShowGuidanceAction,
        @Optional() @Inject(SHOW_OWNERS_ALIGNMENT_ACTION) public showOwnersAlignmentAction?: ShowGuidanceAction,

    ) {
        super(elementRef);
        this.hidePurposeVision = !featuresService.isFeatureActive(FeatureName.PurposeAndVision);
        this.hasOwnerAlignFeature = featuresService.isFeatureEnabledForAccount(FeatureName.CompassOwnerAlignment);
    }

    public async ngOnInit() {
        this.hasBullseyeRead = await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadBullseye);
        const hasPurposeVisionRead = await this.authorisationService.promiseToGetHasAccess(CommonPurposeVisionAuthService.ReadPurposeVision);

        forkJoin([
            hasPurposeVisionRead ? this.commonPurposeVisionService.getVision() : of(undefined),
            hasPurposeVisionRead ? this.commonPurposeVisionService.getPurpose() : of(undefined),
            this.hasBullseyeRead ? this.bullseyeService.getBullseye() : of(undefined),
        ]).pipe(
            tap(([guidingPhilosophy, purpose, bullseye]) => {
                this.guidingPhilosophy = guidingPhilosophy;
                this.purpose = purpose;
                this.bullseye = bullseye?.extensions.areQuadrantsCompleted() ? bullseye : undefined;
            }),
        ).subscribe();
    }

    public get hideBullseye() {
        return this.area === "bullseye" || !this.hasBullseyeRead;
    }

    public get hidePurpose() {
        return this.area === "purpose" || this.hidePurposeVision;
    }

    public get hideVision() {
        return this.area === "vision" || this.hidePurposeVision;
    }

    public showMessageDialog(title: string, message: string) {
        this.dialogService.showMessageDialog(title, message).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public showBullseye() {
        this.dialogService.open(ShowBullseyeDialogComponent).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public showOwnersWhy() {
        this.showOwnersWhyAction?.(this.injector).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public showOwnersAlignment() {
        this.showOwnersAlignmentAction?.(this.injector).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}
