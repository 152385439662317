<div *adaptIfAlto="true"
     class="d-flex pt-5 w-100 flex-column align-items-center h-100 justify-content-center">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</div>
<adapt-page-with-splash-image *adaptIfAlto="false">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</adapt-page-with-splash-image>

<ng-template #pageContent>
    <img src="/content/shell/images/adaptplatformlogo.svg"
         width="140"
         alt="adapt logo"
         class="mb-4"/>

    <div class="row w-100 justify-content-center">
        <div *adaptIfAlto="true"
             class="col-12 col-sm-9 col-lg-6 col-xl-3 text-center">
            <h5>{{inviter}} has invited you to {{organisationName}} on {{projectLabel}}.</h5>
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
        <div *adaptIfAlto="false">
            <h1>Set Password</h1>
            <h3>{{inviter}} has invited you to {{organisationName}} on {{projectLabel}}.</h3>
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
    </div>

    <ng-template #formContent>
        <div *ngIf="tokenHasExpired else tokenInvalid">
            <p>The invitation to join {{organisationName}} has expired.</p>
            <p>Please request another invitation from {{inviter}}.</p>
        </div>

        <ng-template #tokenInvalid>
            <div *ngIf="tokenIsInvalid">
                <p>The invitation link is invalid.</p>
                <p>Please request another invitation.</p>
            </div>
        </ng-template>
        <div *ngIf="!tokenHasExpired && !tokenIsInvalid">
            <p>To proceed, please set a password
                <ng-container *ngIf="ExternalLoginProviders.length > 0">or use one of the external login options below</ng-container>.</p>

            <form>
                <dx-validation-group #validationGroup>
                    <div class="form-group">
                        <label class="visually-hidden"
                               for="passwordInput">Password</label>

                        <dx-text-box mode="password"
                                     id="passwordInput"
                                     [(value)]="password"
                                     (onValueChanged)="validateGroup()"
                                     data-test="password-input"
                                     placeholder="Password">
                            <dx-validator>
                                <dxi-validation-rule type="required"
                                                     message="Password is required"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength"
                                                     [min]="5"
                                                     message="Password must be at least five characters"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                    <div class="form-group">
                        <label class="visually-hidden"
                               for="confirmPasswordInput">Confirm Password</label>

                        <dx-text-box mode="password"
                                     id="confirmPasswordInput"
                                     [(value)]="confirmPassword"
                                     (onValueChanged)="validateGroup()"
                                     data-test="confirm-password-input"
                                     placeholder="Confirm password">
                            <dx-validator>
                                <dxi-validation-rule type="compare"
                                                     [comparisonTarget]="passwordComparison"
                                                     message="Passwords do not match"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>

                    <button [adaptBlockingClick]="submit"
                            [passClickEvent]="true"
                            type="submit"
                            class="btn btn-primary w-100"
                            data-test="submit-button"
                            [disabled]="isDisabled">Set Password
                    </button>
                </dx-validation-group>
            </form>

            <div *ngIf="ExternalLoginProviders.length > 0"
                 class="my-3 text-center">
                <strong class="text-muted">Or login with:</strong>

                <div class="d-flex flex-row w-100 align-items-center gap-2 my-2">
                    <div *ngFor="let provider of ExternalLoginProviders"
                         class="flex-fill">
                        <button type="button"
                                class="btn btn-outline-secondary w-100 d-flex align-items-center justify-content-center gap-2"
                                [attr.data-test]="'sign-in-' + provider.provider.toLowerCase()"
                                [adaptBlockingClick]="signInProvider"
                                [adaptBlockingClickParam]="provider">
                            <img [src]="provider.iconPath"
                                 [alt]="provider.providerDisplayName"
                                 style="height: 24px; width: 24px;"/>
                            <strong>{{provider.providerDisplayName}}</strong>
                        </button>
                    </div>
                </div>

                <p class="text-muted">You will still be able to set a password afterwards via your profile page.</p>
            </div>

            <div role="alert"
                 *ngIf="message"
                 class="mt-2 alert alert-danger">{{message}}</div>
        </div>
    </ng-template>
</ng-template>
