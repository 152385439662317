import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { ExternalLoginProviders, IExternalLoginProvider } from "@common/identity/external-login/external-login-provider.interface";
import { IExternalLoginProviderStatus } from "@common/identity/external-login/external-login-status.interface";
import { IdentityService } from "@common/identity/identity.service";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { RouteEventsService } from "@common/route/route-events.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { switchMap } from "rxjs";
import { catchError } from "rxjs/operators";

@Component({
    selector: "adapt-configure-personal-single-sign-on",
    templateUrl: "./configure-personal-single-sign-on.component.html",
    styleUrl: "./configure-personal-single-sign-on.component.scss",
})
export class ConfigurePersonalSingleSignOnComponent extends BaseComponent implements OnInit {
    public readonly ExternalLoginProviders = Object.fromEntries(Object.entries(ExternalLoginProviders)
        .filter(([_, v]) => v.enabled));

    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public readonly articleUrl = ImplementationKitService.GetArticleLink(ImplementationKitArticle.SingleSignOnOverview);

    @Input({ required: true }) public person!: Person;
    @Output() public userHasPassword = new EventEmitter<boolean>();

    public profileUrl?: string;

    public hasPassword = true;
    public configuredProviders?: IExternalLoginProviderStatus[];
    public otherProviders?: IExternalLoginProviderStatus[];

    public errorMessage?: string;

    public constructor(
        private httpClient: HttpClient,
        private identityService: IdentityService,
        private dialogService: AdaptCommonDialogService,
        private directorySharedService: DirectorySharedService,
        private routeEventsService: RouteEventsService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super();

        // errors can be passed in from login-external-page
        this.routeEventsService.userNavigationError$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((errorMessage) => {
            this.errorMessage = errorMessage;
        });
    }

    public get canRemoveProvider() {
        return this.hasPassword || (this.configuredProviders?.length ?? 0) > 1;
    }

    public async ngOnInit() {
        await this.initialiseData();
    }

    private async initialiseData() {
        this.profileUrl = await this.directorySharedService.promiseToGetProfileUrl(this.person);

        const result = await this.identityService.getExternalLoginStatus();
        if (result.body) {
            const { providers, hasPassword } = result.body;

            const enabledProviders = providers.filter((provider) => ExternalLoginProviders[provider.provider].enabled);
            const [configuredProviders, otherProviders] = ArrayUtilities.partition(enabledProviders, (l) => l.linked);
            this.configuredProviders = configuredProviders;
            this.otherProviders = otherProviders;

            this.hasPassword = hasPassword;
            this.userHasPassword.emit(hasPassword);
        }
    }

    @Autobind
    public async signInProvider(provider: IExternalLoginProvider) {
        // remove the origin from the href to get the path/query/hash
        // we want to redirect the user back to this config page after logging in with the external login
        const pathWithQuery = this.document.location.href.replace(this.document.location.origin, "");
        const signInUrl = UrlUtilities.setQueryParam(provider.signInUrl, "returnUrl", pathWithQuery);
        this.document.location.assign(signInUrl);
    }

    @Autobind
    public removeProvider(provider: IExternalLoginProvider) {
        return this.dialogService.openConfirmationDialog({
            title: "Remove access",
            message: `<p>If you remove access, logging in using your <b>${provider.providerDisplayName}</b> account will no longer sign you into ${this.projectLabel}.</p>
                      <p>You can link another <b>${provider.providerDisplayName}</b> account again at any time.</p>`,
            confirmButtonText: "Remove",
        }).pipe(
            switchMap(() => this.httpClient.post(`${ServiceUri.AccountServiceUri}/UnlinkExternalLogin`, null, {
                params: { provider: provider.provider },
            })),
            switchMap(() => this.initialiseData()),
            catchError((err) => this.dialogService.showMessageDialog("Failed to remove access", ErrorHandlingUtilities.getHttpResponseMessage(err))),
        );
    }
}
