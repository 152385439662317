import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Module } from "../embed/module";
import { Account } from "./account";

export class AccountModule extends BaseEntity<AccountModule> {
    public accountModuleId!: number;
    public organisationId!: number;
    public moduleId!: number;
    public quantity!: number;

    public account!: Account;
    public module!: Module;
}

export const AccountModuleBreezeModel = new BreezeModelBuilder("AccountModule", AccountModule)
    .hasSource()
    .isOrganisationEntity()
    .build();
