<div class="d-flex flex-column align-items-stretch gap-2 px-3"
     *adaptLoading="!account">
    <div class="row mb-3">
        <adapt-configure-payment-method class="flex-grow-1 col-12 col-lg-6 pe-2"></adapt-configure-payment-method>
        <adapt-configure-billing-details class="flex-grow-1 col-12 col-lg-6"></adapt-configure-billing-details>
    </div>

    @if (account?.invoices?.length) {
        <adapt-display-historical-invoices [organisation]="account!.organisation"
                                           [displayAdvancedOptions]="false"></adapt-display-historical-invoices>
    }
</div>
