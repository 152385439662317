import { Component, Inject } from "@angular/core";
import { IMeetingCustomData, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact } from "@common/ADAPT.Common.Model/person/person-contact";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { IDxSchedulerContentReadyEvent } from "@common/ux/dx.types";
import { Event } from "@microsoft/microsoft-graph-types-beta";
import { CalendarIntegrationUtilities } from "@org-common/lib/calendar/calendar-integration-utilities";
import { MicrosoftCalendarService } from "@org-common/lib/calendar/microsoft-calendar.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { OAuthService } from "@org-common/lib/oauth/oauth.service";
import { LoadOptions } from "devextreme/data";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { AppointmentClickEvent, AppointmentFormOpeningEvent } from "devextreme/ui/scheduler";
import moment from "moment";
import { EMPTY, lastValueFrom } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

type EventWithDisable = Event & { disabled: boolean };

export interface IMeetingIntegrationImportOptions {
    teamId: number;
    linkToMeeting?: Meeting;
}

@Component({
    selector: "adapt-meeting-integration-import-dialog",
    templateUrl: "./meeting-integration-import-dialog.component.html",
    styleUrls: ["./meeting-integration-import-dialog.component.scss"],
})
export class MeetingIntegrationImportDialogComponent extends BaseDialogComponent<IMeetingIntegrationImportOptions, Meeting> {
    public readonly dialogName = "meetingIntegrationImportDialog";
    public readonly AdaptProjectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    public loading = true;
    public eventPeople = new Map<string, Person[]>();

    public selectedEvent?: EventWithDisable;
    public now = new Date();

    public dataSource = new DataSource({
        store: new CustomStore({
            load: (loadOptions: LoadOptions) => this.getCalendarMeetings(loadOptions.startDate!, loadOptions.endDate!)
                .catch((e) => {
                    this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                    throw e;
                }),
        }),
    });

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IMeetingIntegrationImportOptions,
        private commonDataService: CommonDataService,
        private oauthService: OAuthService,
        private microsoftCalendarService: MicrosoftCalendarService,
        private calendarIntegrationUtilities: CalendarIntegrationUtilities,
        private directorySharedService: DirectorySharedService,
        private meetingsService: MeetingsService,
        private userService: UserService,
    ) {
        super();
    }

    public onContentReady(e: IDxSchedulerContentReadyEvent) {
        if (this.data.linkToMeeting?.meetingDateTime) {
            e.component?.scrollTo(this.data.linkToMeeting.meetingDateTime);
        }
    }

    public onSelectionChanged(event: AppointmentClickEvent) {
        this.selectedEvent = event.appointmentData as EventWithDisable;
    }

    public onAppointmentFormOpening(event: AppointmentFormOpeningEvent) {
        event.cancel = true;
        this.selectedEvent = event.appointmentData as EventWithDisable;
    }

    @Autobind
    public importMeeting() {
        if (!this.selectedEvent) {
            return EMPTY;
        }

        if (this.data.linkToMeeting) {
            this.calendarIntegrationUtilities.attachMicrosoftUniqueIdToMeeting(this.data.linkToMeeting, this.selectedEvent!, undefined, true);
            return this.commonDataService.saveEntities([this.data.linkToMeeting]).pipe(
                tap(() => this.resolve(this.data.linkToMeeting!)),
            );
        }

        return this.meetingsService.createMeeting(this.data.teamId).pipe(
            switchMap(async (meeting) => this.populateMeetingFromEvent(meeting, this.selectedEvent!)),
            tap((meeting) => this.resolve(meeting)),
        );
    }

    private async getCalendarMeetings(startDate: Date, endDate: Date) {
        const [customDataMeetings, allContacts, calendarEvents] = await Promise.all([
            lastValueFrom(this.meetingsService.getTeamMeetingsWithCustomData(this.data.teamId)),
            await this.directorySharedService.promiseToGetAllPersonEmailContacts(),
            this.microsoftCalendarService.getCalendarView(startDate, endDate),
        ]);

        // disable importing events that are already in our platform
        const customDataMeetingIds = customDataMeetings.map((meeting) =>
            this.calendarIntegrationUtilities.getProviderMeetingId(meeting, CalendarIntegrationProvider.Microsoft));

        const filteredCalendarEvents = calendarEvents
            // all day events are broken because we don't allow meetings that long
            .filter((evt) => !evt.isAllDay)
            .map((evt) => ({
                ...evt,
                // need to transform the date to proper ISO so that the events show properly
                start: evt.start ? { ...evt.start, dateTime: evt.start?.dateTime + "Z" } : evt.start,
                end: evt.end ? { ...evt.end, dateTime: evt.end?.dateTime + "Z" } : evt.end,
                disabled: customDataMeetingIds.includes(evt.iCalUId!) || customDataMeetingIds.includes(evt.uid!),
            } as EventWithDisable));

        for (const calendarEvent of filteredCalendarEvents) {
            const people = this.getPeopleForEvent(calendarEvent, allContacts);
            this.eventPeople.set(calendarEvent.id!, people);
        }

        return filteredCalendarEvents;
    }

    private async populateMeetingFromEvent(meeting: Meeting, event: Event) {
        if (!event) {
            return meeting;
        }

        meeting.name = event.subject ?? "No name provided";
        meeting.createdDateTime = moment(event.lastModifiedDateTime).toDate();

        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        customData.imported = true;
        customData.invitationsSent = true;
        customData.microsoftUniqueId = event.iCalUId!;

        if (event.isOrganizer) {
            customData.microsoftUserId = this.oauthService.user?.userId;
        } else if (event.organizer?.emailAddress?.address) {
            // fetch the user id for the organizer
            try {
                const user = await this.microsoftCalendarService.getUserByUpn(event.organizer.emailAddress.address);
                customData.microsoftUserId = user?.id;
            } catch (e) {
                console.warn(`Failed to get user for calendar event: ${e}`);
            }
        }

        if (event.location?.displayName) {
            meeting.location = event.location.displayName;
            customData.microsoftLocation = event.location.uniqueId ?? undefined;
        }

        if (event.start?.dateTime) {
            meeting.meetingDateTime = moment.utc(event.start.dateTime).toDate();
        }

        if (event.end?.dateTime) {
            meeting.endTime = moment.utc(event.end.dateTime).toDate();
        }

        // TODO: decide if need to import body (duplicated when the event is updated by us)
        // if (event.body?.content) {
        //     if (!meeting.supplementaryData) {
        //         const suppData = await lastValueFrom(this.meetingsService.createMeetingSupplementaryData(meeting));
        //         meeting.supplementaryData = suppData;
        //     }
        //
        //     meeting.supplementaryData!.purpose = event.body.content;
        // }

        // add all the matching people as attendees
        const people = this.eventPeople.get(event.id!) ?? [];

        // add current person (as first attendee) if not already an attendee
        const currentPerson = await this.userService.getCurrentPerson();
        if (currentPerson && !people.includes(currentPerson)) {
            people.unshift(currentPerson);
        }

        await Promise.all(people.map((person) =>
            lastValueFrom(this.meetingsService.createMeetingAttendee(meeting.meetingId, person.personId))));

        meeting.extensions.updateCustomData(customData);

        return meeting;
    }

    private getPeopleForEvent(event: Event, contacts: PersonContact[]) {
        const personEmails = event.attendees
            ?.filter((attendee) => attendee.type !== "resource" && !!attendee.emailAddress?.address)
            ?.map((attendee) => attendee.emailAddress!.address!.toLowerCase()) ?? [];

        return contacts.reduce((people, email) => {
            if (personEmails.includes(email.value.toLowerCase())) {
                people.push(email.person);
            }

            return people;
        }, [] as Person[]);
    }
}
