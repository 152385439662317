import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";

@Component({
    selector: "adapt-configure-team-access-permissions",
    templateUrl: "./configure-team-access-permissions.component.html",
})
export class ConfigureTeamAccessPermissionsComponent extends ConfigureFeatureBase {

    @Input() public team!: Team;
    @Input() public configItem!: IConfigItem;

    public constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public shouldShowSaveButtons() {
        return false;
    }

    @Autobind
    public async initialiseData() {
        return Promise.resolve();
    }
}
