import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UserService } from "@common/user/user.service";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { OrganisationAuthService } from "@org-common/lib/organisation/organisation-auth.service";
import { ConfigureFeatureBase } from "../../base-components/configure-feature-base";
import { IConfigItem } from "../../configuration.interfaces";

@Component({
    selector: "adapt-configure-personal-account-security",
    templateUrl: "./configure-personal-account-security.component.html",
})
export class ConfigurePersonalAccountSecurityComponent extends ConfigureFeatureBase {
    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public readonly ChangePasswordForPerson = OrganisationAuthService.ChangePasswordForPerson;

    @Input() public configItem?: IConfigItem;

    public person?: Person;
    public errorMessage?: string;

    public userHasPassword = true;

    public constructor(
        configurationService: ConfigurationService,
        private userService: UserService,
    ) {
        super(configurationService);
    }

    public shouldShowSaveButtons() {
        return false;
    }

    @Autobind
    public async initialiseData() {
        this.person = await this.userService.getCurrentPerson();
    }
}
