import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { ICardPartialDetails } from "@common/payment-processing/card-partial-details.interface";
import { DisplayHistoricalInvoicesComponent } from "@common/payment-processing/display-historical-invoices/display-historical-invoices.component";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ConfigurationAuthService } from "../configuration-auth.service";

@Component({
    selector: "adapt-billing-page",
    templateUrl: "./billing-page.component.html",
    styleUrl: "./billing-page.component.scss",
})
export class BillingPageComponent extends BaseRoutedComponent implements OnInit {
    public account?: Account;
    public cardDetails?: ICardPartialDetails;

    @ViewChild(DisplayHistoricalInvoicesComponent) public displayHistoricalInvoiceComponent?: DisplayHistoricalInvoicesComponent;

    public constructor(
        public accountService: AccountService,
        private paymentProcessingService: PaymentProcessingService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.accountService.getAccount().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((account) => {
            this.account = account;
            if (account) {
                // prime invoices to determine if to show payment history
                this.paymentProcessingService.getInvoices(account.organisationId);
            }

            this.notifyActivated();
        });
    }
}

export const BillingPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-billing-page", BillingPageComponent)
    .atOrganisationUrl("/billing")
    .withTitle("Billing")
    .verifyingAccess(ConfigurationAuthService.ConfigureOrganisationBilling)
    .build();
