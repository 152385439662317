import { Workflow, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStep } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";

export interface IWorkflowRunData<IRunDataType = any> {
    // need both, workflow is defined in embed for all, connection is the current one connected to a user, which will have status
    workflowConnection?: WorkflowConnection;
    workflow: Workflow;
    skipOutcomes?: boolean;
    titleOverride?: string;
    dismissDialog?: IConfirmationDialogData;
    runData?: IRunDataType;
}

export interface IWorkflowPersistenceConfig<TInputData = any, TEncodedData = TInputData> {
    id: string;

    // takes runData and returns a serialisable object
    encode: (runData: IWorkflowRunData<TInputData>) => TEncodedData | undefined;

    // takes serialisable object and returns the runData to use
    decode: (encodedData?: TEncodedData) => IWorkflowRunData<TInputData> | undefined;
}

export type LocalWorkflowStep = Partial<WorkflowStep>;

export type LocalWorkflow = Omit<Partial<Workflow>, "parentWorkflow" | "workflows" | "steps"> & {
    workflowId: string;
    parentWorkflow?: Workflow;
    workflows?: LocalWorkflow[];
    steps?: LocalWorkflowStep[];
}

export function buildLocalWorkflow({ parentWorkflow, workflows, steps, ...localWorkflow }: LocalWorkflow) {
    const workflow = Object.assign(new Workflow(), localWorkflow) as Workflow;

    if (parentWorkflow) {
        workflow.parentWorkflow = parentWorkflow;
    }

    if (workflow.type === WorkflowType.Journey && workflows) {
        // add outcome step to the first workflow in the journey
        const firstWorkflow = workflows[0];
        if (firstWorkflow && firstWorkflow.steps && workflow.articleSlug) {
            const outcomeStep = {
                isOutcomeStep: true,
                canSkip: false,
                articleSlug: workflow.articleSlug,
                workflowStepNextText: "Start",
                name: workflow.extensions.rootWorkflowName + ": Outcomes",
            } as LocalWorkflowStep;
            firstWorkflow.steps = [outcomeStep, ...firstWorkflow.steps];
        }
    }

    if (workflows) {
        workflow.workflows = workflows.map((flow) => buildLocalWorkflow({ ...flow, parentWorkflow: workflow }));
    }

    if (steps) {
        workflow.steps = steps.map((step, index) => buildLocalWorkflowStep(step, workflow, index));
    }

    return workflow;
}

function buildLocalWorkflowStep(step: LocalWorkflowStep, workflow: Workflow, ordinal: number) {
    const workflowStep = Object.assign(new WorkflowStep(), step) as WorkflowStep;
    workflowStep.workflowStepId = `${workflow.workflowId}_${ordinal + 1}`;
    workflowStep.workflow = workflow; // nav prop
    workflowStep.ordinal = ordinal;
    return workflowStep;
}

export enum WorkflowActionState {
    NoPermission,
    NotInOrganisation,
    PrerequisitesNotSatisfied,
    MissingRoles,
    NotInLeadershipTeam,
    NotInStartedTeam,
    ComingSoon,
    Disabled,
    NotStarted,
    Current,
    Completed,
    AccountFeatureNotEnabled,
}

export const WorkflowActionableStates = [
    WorkflowActionState.NotStarted,
    WorkflowActionState.Current,
    WorkflowActionState.Completed,
];
