<div class="d-flex flex-column h-100">
    <adapt-styled-implementation-kit *ngIf="articleId"
                                     [articleId]="articleId"
                                     contentStyle="embedded"
                                     class="mb-4"></adapt-styled-implementation-kit>

    <div *ngIf="(!hidePurposeVision && (guidingPhilosophy?.vision || purpose?.content || guidingPhilosophy?.seeds || purpose?.seeds)) || bullseye"
         class="mt-auto sticky-bottom px-2"
         [ngClass]="{'sticky-bottom': !!articleId}">
        <h4>Strategic guidance</h4>
        <p *ngIf="area">Before setting your {{area}}, click on the buttons below to see your strategic guidance.</p>
        <div class="d-flex gap-1 flex-wrap border-bottom">
            <button *ngIf="guidingPhilosophy?.seeds && !hidePurposeVision && showOwnersWhyAction"
                    class="btn btn-info flex-fill"
                    (click)="showOwnersWhy()">Owners ‘why’</button>
            <ng-container *ngIf="purpose?.content && !hidePurpose"
                          [ngTemplateOutlet]="showInformationalDialog"
                          [ngTemplateOutletContext]="{title: 'Purpose statement', message: purpose!.content}"></ng-container>
            <!-- Vision is crafted from align owners & vision - don't show if account disallows -->
            <button *ngIf="purpose?.seeds && !hidePurposeVision && showOwnersAlignmentAction && hasOwnerAlignFeature"
                    class="btn btn-info flex-fill"
                    (click)="showOwnersAlignment()">Vision</button>
            <ng-container *ngIf="guidingPhilosophy?.vision && !hideVision"
                          [ngTemplateOutlet]="showInformationalDialog"
                          [ngTemplateOutletContext]="{title: 'Vision statement', message: guidingPhilosophy!.vision}"></ng-container>

            <button *ngIf="bullseye && !hideBullseye"
                    class="btn btn-info flex-fill"
                    (click)="showBullseye()">Bullseye</button>
            <ng-template #showInformationalDialog
                         let-title="title"
                         let-message="message">
                <button class="btn btn-info flex-fill"
                        (click)="showMessageDialog(title, message)">{{title}}</button>
            </ng-template>
        </div>
    </div>
</div>
