import { Component, Input, OnChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { OrganisationCategoryValue, Workflow, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { SurveyStatus } from "@common/ADAPT.Common.Model/organisation/survey";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, lastValueFrom, Observable, of } from "rxjs";
import { debounceTime, filter, map, switchMap, tap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { IOrganisationOutcomeDetails, OrganisationOutcomes } from "../organisation-outcomes";
import { WorkflowActionableStates, WorkflowActionState } from "../workflow.interface";
import { WorkflowService } from "../workflow.service";
import { WorkflowAuthService } from "../workflow-auth.service";
import { IWorkflowStepOverviewComponent, WorkflowStepOverviewComponentRegistry } from "../workflow-component-registry";

// the previous display-started/new-workflow-card are used in cumulus only - this one
// will be used in alto and possibly replacing the other 2.
// This card can contain custom components defined for the workflow to show in-progress workflow differently.
@Component({
    selector: "adapt-display-workflow-card",
    templateUrl: "./display-workflow-card.component.html",
    styleUrls: ["./display-workflow-card.component.scss"],
})
export class DisplayWorkflowCardComponent extends BaseComponent implements OnChanges {
    public readonly WorkflowType = WorkflowType;
    public readonly WorkflowState = WorkflowActionState;
    public readonly WorkflowStatusEnum = WorkflowStatusEnum;
    public readonly OrganisationCategoryValue = OrganisationCategoryValue;

    @Input() public connection?: WorkflowConnection;
    @Input() public workflow?: Workflow;
    @Input() public showOutcomeIcon = true;

    @ViewChild("overviewContainer", { read: ViewContainerRef }) public overviewContainer!: ViewContainerRef;

    public currentStatus?: WorkflowStatusEnum;
    public showStepOverviewComponent = false;
    public canContinueWorkflow = false;
    public hasWorkflowAccess = true;
    public workflowState = WorkflowActionState.NotStarted;

    public categoryMetadata?: IOrganisationOutcomeDetails;

    public workflowStatus$: Observable<WorkflowStatusEnum> = of(WorkflowStatusEnum.Incomplete);
    private triggerUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private authorisationService: AuthorisationService,
        private dialogService: AdaptCommonDialogService,
        private workflowService: WorkflowService,
        rxjsBreezeService: RxjsBreezeService,
        userService: UserService,
    ) {
        super();

        rxjsBreezeService.entityTypeChanged(WorkflowStatus).pipe(
            filter((status) => status.workflowConnection === this.connection || status.workflowId === this.workflow?.workflowId),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());

        rxjsBreezeService.entityTypeChanged(Connection).pipe(
            filter((connection) => connection.personId === userService.getCurrentPersonId()),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());
    }

    public get isActionable() {
        return WorkflowActionableStates.includes(this.workflowState);
    }

    public async ngOnChanges() {
        if (!this.workflow) {
            this.workflow = this.workflowService.getWorkflow(this.connection);
        } else if (!this.connection) {
            this.connection = await lastValueFrom(this.workflowService.getLatestWorkflowConnectionForWorkflow(this.workflow));
        }

        this.hasWorkflowAccess = await this.authorisationService.promiseToGetHasAccess(WorkflowAuthService.EditWorkflow);

        if (this.workflow?.category) {
            this.categoryMetadata = OrganisationOutcomes[this.workflow.category];
        }

        if (this.connection) {
            this.workflowStatus$ = this.triggerUpdate$.pipe(
                switchMap(() => this.workflowService.getStatusForWorkflow(this.connection!)),
                map((workflowStatus) => workflowStatus?.status ?? WorkflowStatusEnum.Incomplete),
            );
        } else {
            this.workflowStatus$ = this.triggerUpdate$.pipe(
                switchMap(() => this.workflowService.getLatestStatusForWorkflow(this.workflow!)),
                map((statusEntity) => statusEntity?.status ?? WorkflowStatusEnum.Incomplete),
            );
        }

        this.workflowStatus$.pipe(
            tap((status) => this.currentStatus = status),
            filter((status) => status === WorkflowStatusEnum.Current && !!this.connection && !!this.workflowService.getEnabledWorkflowById(this.connection.workflowId)),
            switchMap(() => this.workflowService.canContinueWorkflow(this.workflow!)),
            tap((canContinue) => this.canContinueWorkflow = canContinue),
            switchMap(() => this.workflowService.getOrSetCurrentStepForJourney(this.connection!, false)),
            this.takeUntilDestroyed(),
        ).subscribe((step) => {
            this.showStepOverviewComponent = false;
            if (step?.overviewComponentSelector && this.overviewContainer) {
                this.showStepOverviewComponent = true;
                const type = WorkflowStepOverviewComponentRegistry.get(step.overviewComponentSelector);
                if (type) {
                    this.overviewContainer.clear();
                    const stepOverviewComponent = this.overviewContainer.createComponent<IWorkflowStepOverviewComponent>(type);
                    stepOverviewComponent.instance.workflowStep = step;
                    stepOverviewComponent.instance.workflowConnection = this.connection;
                    if (stepOverviewComponent.instance.onDataChanged) {
                        stepOverviewComponent.instance.onDataChanged();
                    }
                } else {
                    throw new Error(`Overview component is not registered - ${step.overviewComponentSelector}`);
                }
            } else if (this.overviewContainer) {
                this.overviewContainer.clear();
            }
        });
    }

    public deleteConnection(connection: WorkflowConnection) {
        this.workflowService.getAssociatedMeetingSurvey(connection).pipe(
            switchMap(([meeting, survey]) => {
                const incompleteEntityTypes: string[] = [];
                if (meeting && meeting.status !== MeetingStatus.Ended) {
                    incompleteEntityTypes.push("meeting");
                }
                if (survey && survey.status !== SurveyStatus.Ended) {
                    incompleteEntityTypes.push("survey");
                }
                const surveyMeetingImpactStatement = incompleteEntityTypes.length > 0
                    ? `<p>Ending the pathway will delete the incomplete ${incompleteEntityTypes.join(" and ")} initiated by it.</p>`
                    : "";
                const dialogData: IConfirmationDialogData = {
                    title: "End pathway",
                    message: `<p>You are about to end the following pathway:</p>
                        <blockquote><b>${this.workflow?.name}</b></blockquote>
                        ${surveyMeetingImpactStatement}
                        <p>You can restart the pathway at any time.</p>
                        <p>Are you sure you want to continue?</p>`,
                    confirmButtonText: "End pathway",
                    cancelButtonText: "Cancel",
                    // need to tick checkbox if survey or meeting will be deleted
                    checkboxMessage: surveyMeetingImpactStatement ? "Check to confirm that you understand the deleted data will not be recoverable" : undefined,
                };

                return this.dialogService.openConfirmationDialog(dialogData);
            }),
            switchMap(() => this.workflowService.deleteWorkflowConnection(connection)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}
