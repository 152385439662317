import { Component, Inject } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { Observable, timer } from "rxjs";
import { finalize, map, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";

enum ImportType {
    FromTemplate,
    FromMeeting,
}

interface IImportTypeOption {
    type: ImportType;
    text: string;
}

export interface IImportMeetingAgendaData {
    currentMeeting?: Meeting;
    saveOnClose: boolean;
}

@Component({
    selector: "adapt-import-agenda-dialog",
    templateUrl: "./import-agenda-dialog.component.html",
    styleUrl: "./import-agenda-dialog.component.scss",
})
export class ImportAgendaDialogComponent extends BaseDialogComponent<IImportMeetingAgendaData, MeetingAgendaItem[]> {
    public readonly dialogName = "ImportFromOtherMeetingAgendaDialog";

    public readonly ImportType = ImportType;
    public readonly ImportTypes: IImportTypeOption[] = [{
        type: ImportType.FromTemplate,
        text: "Existing meeting template",
    }, {
        type: ImportType.FromMeeting,
        text: "Recent meeting",
    }];

    public selectedImportType = ImportType.FromTemplate;

    public recentMeetingsDataSource$: Observable<DataSource>;
    public templatesDataSource$: Observable<DataSource>;

    public selectedMeeting?: Meeting;
    public selectedTemplate?: MeetingAgendaTemplate;

    public loadingItems = false;
    public agendaItems: MeetingAgendaItem[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IImportMeetingAgendaData,
        private meetingsService: MeetingsService,
    ) {
        super();

        this.recentMeetingsDataSource$ = this.meetingsService.getAllRecentMeetings().pipe(
            map((meetings) => {
                // excluding the current meeting
                meetings = meetings.filter((m) => m !== this.dialogData.currentMeeting);

                // sort the recent meetings by the current meeting's team, and then by alphabetical team name
                // note: DX forces you to pre-group data in this way to get custom sort
                // https://supportcenter.devexpress.com/ticket/details/t923260/selectbox-how-to-sort-a-grouped-field-based-on-another-field
                const groupedData = ArrayUtilities.groupArrayBy(meetings, (m) => m.team);
                groupedData.sort((a, b) => {
                    if (a.key?.teamId === this.dialogData.currentMeeting?.teamId) {
                        return -1;
                    } else if (b.key?.teamId === this.dialogData.currentMeeting?.teamId) {
                        return 1;
                    } else {
                        return a.key!.name.localeCompare(b.key!.name);
                    }
                });

                const sortedMeetings = groupedData.flatMap((g) => g.items);
                const mappedTeamNames: { [teamId: number]: string } = {};
                // fixed 6 digits padding in front of team name to keep the order
                groupedData.forEach((g, index) => mappedTeamNames[g.key?.teamId ?? 0] = `${index.toString().padStart(6, "0")}${g.key?.name}`);

                return new DataSource({
                    store: new ArrayStore({
                        data: sortedMeetings,
                        key: "meetingId",
                    }),
                    group: (meeting: Meeting) => mappedTeamNames[meeting.teamId],
                });
            }),
        );

        this.templatesDataSource$ = this.meetingsService.getMeetingAgendaTemplates().pipe(
            map((templates) => {
                return new DataSource({
                    store: new ArrayStore({
                        data: templates
                            .filter((t) => t.entityAspect.entityState.isUnchanged())
                            .sort(this.meetingsService.meetingAgendaTemplateSortFunction),
                        key: "meetingAgendaTemplateId",
                    }),
                    group: (template: MeetingAgendaTemplate) => {
                        if (template.team) {
                            return `Team - ${template.team.name}`;
                        }
                        return "Organisation";
                    },
                });
            }),
        );
    }

    public onImportTypeChange(importType: ImportType) {
        this.selectedTemplate = undefined;
        this.selectedMeeting = undefined;
        this.selectedImportType = importType;
    }

    public meetingSelected(meeting?: Meeting) {
        if (!meeting) {
            return;
        }

        this.selectedMeeting = meeting;
        this.loadingItems = true;
        this.meetingsService.getAgendaItemsForMeeting(meeting).pipe(
            this.takeUntilDestroyed(),
            finalize(() => this.loadingItems = false),
        ).subscribe((items) => this.agendaItems = items);
    }

    public templateSelected(template?: MeetingAgendaTemplate) {
        if (!template) {
            return;
        }

        this.selectedTemplate = template;
        this.loadingItems = true;
        this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(template.meetingAgendaTemplateId).pipe(
            this.takeUntilDestroyed(),
            finalize(() => this.loadingItems = false),
        ).subscribe((items) => this.agendaItems = items);
    }

    @Autobind
    public importItems() {
        // do resolve in the next digest cycle to workaround unfinished subscriber in blocking click destruction
        return timer(0).pipe(
            tap(() => this.resolve(this.agendaItems)),
        );
    }
}
