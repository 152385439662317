import { Component } from "@angular/core";
import { IdentityService } from "@common/identity/identity.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ITwoFactorWorkflowRunData } from "@org-common/lib/configuration/personal/two-factor-workflow/two-factor-workflow";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, firstValueFrom } from "rxjs";

@WorkflowStepComponent("adapt-two-factor-workflow-set-up")
@Component({
    selector: "adapt-two-factor-workflow-set-up",
    templateUrl: "./two-factor-workflow-set-up.component.html",
})
export class TwoFactorWorkflowSetUpComponent extends WorkflowStepComponentAdapter {
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public runData?: ITwoFactorWorkflowRunData;

    public secretCollapsed = true;
    public copiedSecret = false;
    public authenticatorUri?: string;

    public twoFactorCode = "";

    public constructor(private identityService: IdentityService) {
        super();
    }

    public async workflowStepOnInit() {
        if (this.workflowStep) {
            this.runData = this.workflowStep.workflow.runData as ITwoFactorWorkflowRunData;

            const response = await this.identityService.manageTwoFactor({});

            // store so can be used in next step
            this.runData.response = response.body ?? undefined;

            if (this.runData.response) {
                const sessionData = await firstValueFrom(this.identityService.identity$);
                if (sessionData) {
                    const userName = encodeURIComponent(sessionData.userName);
                    const issuer = "adapt";
                    this.authenticatorUri = `otpauth://totp/${issuer}:${userName}?secret=${this.runData.response.sharedKey}&issuer=${issuer}`;
                }
            }
        }
    }

    public onTwoFactorCodeChange(code: string) {
        this.twoFactorCode = code;
        this.workflowStepCompleted.next(code.length === 6);
    }

    public async workflowStepNext() {
        this.workflowStepErrorMessage.next(undefined);

        if (!this.runData || !this.workflowStep) {
            return;
        }

        const twoFactorCode = this.twoFactorCode.replace(/\s/g,"").trim();

        try {
            const response = await this.identityService.manageTwoFactor({ enable: true, twoFactorCode });

            this.runData.response = response.body ?? undefined;
            this.workflowStep.workflow.runData = this.runData;
        } catch (e) {
            const error = ErrorHandlingUtilities.getHttpResponseMessage(e);
            throw new Error(error);
        }
    }

    public async copyToClipboard(text: string) {
        await navigator.clipboard.writeText(text);
        this.copiedSecret = true;
        // reset back to uncopied so the button looks like it can be pressed again.
        setTimeout(() => this.copiedSecret = false, 5000);
    }
}
