import { Component, Input, OnInit } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";

// Unfortunately can't automatically determine this from the files
const IMAGE_COUNT = 4;

@Component({
    selector: "adapt-content-with-splash-image",
    templateUrl: "./content-with-splash-image.component.html",
    styleUrls: ["./content-with-splash-image.component.scss"],
})
export class ContentWithSplashImageComponent implements OnInit {
    public static readonly defaultLeftColumnWidthClasses = "col-xl-4 col-lg-6";
    public static readonly defaultRightColumnWidthClasses = "col";

    @Input() public fullPage = false;
    @Input() public image?: string;
    @Input() public imageStyles?: Partial<CSSStyleDeclaration>;
    @Input() public leftColumnWidthClasses = ContentWithSplashImageComponent.defaultLeftColumnWidthClasses;
    @Input() public rightColumnWidthClasses = ContentWithSplashImageComponent.defaultRightColumnWidthClasses;
    @Input() public fitHeight = false;

    // default to white background in cumulus
    @Input() public whiteBackground = AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto;

    public styles: Partial<CSSStyleDeclaration> = {};

    public ngOnInit() {
        if (!this.image) {
            const imageIndex = Math.floor(Math.random() * IMAGE_COUNT);
            this.image = this.getImageIndexPath(imageIndex);
        }

        this.styles = {
            backgroundImage: `url("${this.image}")`,
            ...this.imageStyles,
        };
    }

    private getImageIndexPath(index: number) {
        return `/content/ux/content-with-splash-image/assets/splash-image-${index}.jpg`;
    }
}
