<div class="d-flex justify-content-between align-items-baseline gap-3 container flex-grow-1"
     [ngClass]="{'current': isCurrent, 'new': isNew, 'has-upgrade-text': (isNew || (!required && isCurrent && !isSelected)) && addText}">
    <span *ngIf="isCurrent"
          class="label">{{currentText}} <i class="fas fa-circle-check"></i></span>
    <span *ngIf="isNew"
          class="label">{{newText}}</span>
    <div class="content">
        <ng-content></ng-content>
    </div>
    <span class="checkbox"
          role="button"
          (click)="toggleSelection()">
        <i class="fas fa-fw"
           [ngClass]="{'fa-check' : isSelected}"></i>
    </span>
    <span *ngIf="isNew && addText"
          class="upgrade-text">{{addText}}</span>
    <span *ngIf="!required && isCurrent && !isSelected && removeText"
          class="remove-text">{{removeText}}</span>
    <span *ngIf="isCurrent && isUpdated && updateText"
          class="remove-text">{{updateText}}</span>
</div>
