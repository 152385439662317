import { Component, ElementRef, Inject, Injector, OnInit, Optional, ViewChild } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Anchor, AnchorMetadata } from "@common/ADAPT.Common.Model/organisation/anchor";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { BaseScrollPersistingRoutedComponent } from "@common/ux/base-scroll-persisting-routed.component";
import { debounceTime, forkJoin, Observable, of, Subject } from "rxjs";
import { delay, filter } from "rxjs/operators";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { BullseyeService } from "../bullseye/bullseye.service";
import { BullseyePageRoute } from "../bullseye/bullseye-page/bullseye-page.component";
import { ObjectivesAuthService } from "../objectives/objectives-auth.service";
import { organisationObjectivesPageRoute } from "../objectives/objectives-page/objectives-page.route";
import { CommonPurposeVisionService } from "../purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "../purpose-vision/common-purpose-vision-auth.service";
import { OrganisationPageRouteBuilder } from "../route/organisation-page-route-builder";
import { StrategicAnchorsService } from "../strategic-anchors/strategic-anchors.service";
import { StrategicAnchorsComponent } from "../strategic-anchors/strategic-anchors/strategic-anchors.component";
import { StrategicAnchorsPageRoute } from "../strategic-anchors/strategic-anchors-page/strategic-anchors-page.component";
import { StrategicGoalsComponent } from "../strategic-goals/strategic-goals/strategic-goals.component";
import { StrategyGoalsPageRoute } from "../strategic-goals/strategic-goals-page/strategic-goals-page.component";
import { StrategyAuthService } from "../strategy/strategy-auth.service";
import { StrategicViewIcon } from "../strategy/strategy-view-constants";
import { ValuesConstitutionAuthService } from "../values-constitution/values-constitution-auth.service";
import { valuesConstitutionRoute } from "../values-constitution/values-constitution-page/values-constitution-page.route";
import { WorkflowService } from "../workflow/workflow.service";
import { WorkflowIdentifier } from "../workflow/workflow-identifier.enum";
import { WORKFLOWS } from "../workflow-provider";


interface IDisplayViewItem {
    key: DisplayViewKey;
    icon: string;
    text: string;
    initialised?: boolean;
    permissions?: string[];
    hasPermission?: boolean;
    workflow?: Workflow;
    workflowAllowedByAccount?: boolean;
    route?: Observable<string>;
    routeText?: string;
}

enum DisplayViewKey {
    ObjectivesView = "ObjectivesView",
    StrategyGoalsView = "StrategyGoalsView",
    StrategicAnchorsView = "StrategicAnchorsView",
    VisionView = "VisionView",
    BullseyeView = "BullseyeView",
    PurposeView = "PurposeView",
    ValuesView = "ValuesView",
}

enum DisplayGroupKey {
    GoalsObjectivesView = "GoalsObjectivesView",
    FoundationsView = "FoundationsView",
}

@Component({
    selector: "adapt-strategy-dashboard-page",
    templateUrl: "./strategy-dashboard-page.component.html",
    styleUrls: ["./strategy-dashboard-page.component.scss"],
})
export class StrategyDashboardPageComponent extends BaseScrollPersistingRoutedComponent implements OnInit {
    public static CollapseState: Record<DisplayViewKey | DisplayGroupKey, boolean> = {
        [DisplayViewKey.ObjectivesView]: false,
        [DisplayViewKey.StrategyGoalsView]: false,
        [DisplayViewKey.StrategicAnchorsView]: false,
        [DisplayViewKey.VisionView]: false,
        [DisplayViewKey.PurposeView]: false,
        [DisplayViewKey.ValuesView]: false,
        [DisplayViewKey.BullseyeView]: false,
        [DisplayGroupKey.GoalsObjectivesView]: false,
        [DisplayGroupKey.FoundationsView]: false,
    };

    public readonly ObjectiveType = ObjectiveType;
    public readonly DisplayViewKey = DisplayViewKey;
    public readonly DisplayGroupKey = DisplayGroupKey;
    public readonly CollapseState = StrategyDashboardPageComponent.CollapseState;

    public views: Record<DisplayViewKey, IDisplayViewItem> = {
        [DisplayViewKey.ObjectivesView]: {
            key: DisplayViewKey.ObjectivesView,
            icon: "fal fa-fw fa-tasks",
            text: "Objectives",
            permissions: [ObjectivesAuthService.ReadAnyObjectives],
            route: organisationObjectivesPageRoute.getRoute(),
            workflow: this.getWorkflow(WorkflowIdentifier.EstablishBusinessCadence),
            routeText: "View all objectives",
        },
        [DisplayViewKey.StrategyGoalsView]: {
            key: DisplayViewKey.StrategyGoalsView,
            icon: StrategicViewIcon.GoalIcon,
            text: "Strategic goals",
            permissions: [StrategyAuthService.ReadStrategicGoals],
            route: StrategyGoalsPageRoute.getRoute(),
            workflow: this.getWorkflow(WorkflowIdentifier.StrategicGoals),
            routeText: "View all strategic goals",
        },
        [DisplayViewKey.StrategicAnchorsView]: {
            key: DisplayViewKey.StrategicAnchorsView,
            icon: AnchorMetadata.iconClass,
            text: "Strategic anchors",
            permissions: [StrategyAuthService.ReadStrategicAnchors],
            route: StrategicAnchorsPageRoute.getRoute(),
            workflow: this.getWorkflow(WorkflowIdentifier.StrategicAnchors),
            routeText: "View all strategic anchors",
        },
        [DisplayViewKey.VisionView]: {
            key: DisplayViewKey.VisionView,
            icon: "fal fa-fw fa-binoculars",
            text: "Vision",
            permissions: [CommonPurposeVisionAuthService.ReadPurposeVision],
            route: of("/purpose-and-vision"),
            workflow: this.getWorkflow(WorkflowIdentifier.CraftVision),
            routeText: "View vision",
        },
        [DisplayViewKey.PurposeView]: {
            key: DisplayViewKey.PurposeView,
            icon: "fal fa-fw fa-compass",
            text: "Purpose",
            permissions: [CommonPurposeVisionAuthService.ReadPurposeVision],
            route: of("/purpose-and-vision"),
            workflow: this.getWorkflow(WorkflowIdentifier.Purpose),
            routeText: "View purpose",
        },
        [DisplayViewKey.ValuesView]: {
            key: DisplayViewKey.ValuesView,
            icon: "fal fa-fw fa-heart",
            text: "Values",
            permissions: [ValuesConstitutionAuthService.ReadValuesConstitution],
            route: valuesConstitutionRoute.getRoute(),
            workflow: this.getWorkflow(WorkflowIdentifier.SetValues),
            routeText: "View values",
        },
        [DisplayViewKey.BullseyeView]: {
            key: DisplayViewKey.BullseyeView,
            icon: "fal fa-fw fa-bullseye",
            text: "Bullseye statement",
            route: BullseyePageRoute.getRoute(),
            permissions: [StrategyAuthService.ReadBullseye],
            workflow: this.getWorkflow(WorkflowIdentifier.Bullseye),
            routeText: "View bullseye",
        },
    };

    @ViewChild(StrategicGoalsComponent) private strategicGoal?: StrategicGoalsComponent;
    @ViewChild(StrategicAnchorsComponent) private strategicAnchors?: StrategicAnchorsComponent;
    public purpose?: Purpose;
    public vision?: GuidingPhilosophy;
    public bullseye?: Bullseye;
    public anchors: Anchor[] = [];
    public hasGoals = false;

    public hasGoalsOrObjectives = false;
    public isPrinting: boolean = false;

    private checkViewsAllInitialised$ = new Subject<void>();

    private objectivesInitialisedCount = 0;

    public constructor(
        @Optional() @Inject(WORKFLOWS) public workflows: Record<WorkflowIdentifier, Workflow>,
        injector: Injector,
        elementRef: ElementRef,
        private authorisationService: AuthorisationService,
        private commonPurposeVisionService: CommonPurposeVisionService,
        private bullseyeService: BullseyeService,
        private anchorsService: StrategicAnchorsService,
        private workflowService: WorkflowService,
    ) {
        super(injector, elementRef);
        window.onbeforeprint = () => {
            this.isPrinting = true;
        };

        window.onafterprint = () => {
            this.isPrinting = false;
        };
    }

    public get hasCollapsed() {
        return Object.values(StrategyDashboardPageComponent.CollapseState).some((collapsed) => collapsed);
    }

    public get bullseyeRoute() {
        if (this.isPrinting) {
            return undefined;
        }

        // use dedicated bullseye page if feature is enabled
        const bullseyeView = this.views[DisplayViewKey.BullseyeView];
        if (bullseyeView.hasPermission) {
            return bullseyeView.route;
        }

        // hq should not fall back to purpose/vision
        // as you cannot have the old bullseye in hq
        if (this.isAlto) {
            return undefined;
        }

        // else use purpose/vision page (for old embed bullseye content)
        const visionView = this.views[DisplayViewKey.VisionView];
        if (visionView.hasPermission) {
            return visionView.route;
        }

        return undefined;
    }

    public getWorkflow(identifier: WorkflowIdentifier) {
        if (!this.workflows) {
            return;
        }
        return this.workflows[identifier];
    }

    public async ngOnInit() {
        this.rememberScrollPosition("StrategyDashboardPage");
        this.blockScrollPositionRestore();  // need to wait for the system to load first before changing the scroll position

        // populate hasPermission for each view
        const featurePromises = Object.values(this.views)
            .map(async (view) => {
                if (view.permissions) {
                    const hasPermissions = await Promise.all(view.permissions.map((p) => this.authorisationService.promiseToGetHasAccess(p)));
                    view.hasPermission = hasPermissions.every((hasPermission) => hasPermission);
                } else {
                    view.hasPermission = true;
                }

                view.workflowAllowedByAccount = this.workflowService.workflowIsEnabledByAccount(view.workflow);
            });
        await Promise.all(featurePromises);
        this.hasGoalsOrObjectives = !!this.views[DisplayViewKey.StrategyGoalsView].workflowAllowedByAccount ||
            !!this.views[DisplayViewKey.ObjectivesView].workflowAllowedByAccount;

        forkJoin([
            this.views[DisplayViewKey.BullseyeView].hasPermission ? this.bullseyeService.getBullseye() : of(undefined),
            this.views[DisplayViewKey.StrategicAnchorsView].hasPermission ? this.anchorsService.getAllAnchors() : of(undefined),
            this.views[DisplayViewKey.PurposeView].hasPermission ? this.commonPurposeVisionService.getPurpose() : of(undefined),
            this.views[DisplayViewKey.VisionView].hasPermission ? this.commonPurposeVisionService.getVision() : of(undefined),
        ]).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(([bullseye, anchors, purpose, vision]) => {
            this.bullseye = bullseye;
            this.anchors = anchors ?? [];
            this.purpose = purpose;
            this.vision = vision;

            // all the data for these has loaded so mark them initialised
            this.viewInitialised(DisplayViewKey.BullseyeView);
            this.viewInitialised(DisplayViewKey.StrategicAnchorsView);
            this.viewInitialised(DisplayViewKey.PurposeView);
            this.viewInitialised(DisplayViewKey.VisionView);

            this.notifyActivated();
        });

        this.checkViewsAllInitialised$.pipe(
            debounceTime(100),
            // only allow thru once all views are initialised
            filter(() => !Object.values(this.views).some(({ initialised }) => !initialised)),
            delay(250), // a bit of delay for the page to finish render before unblocking scroll
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            // only restore scroll position after all loads
            this.unblockScrollPositionRestore();
        });
    }

    public viewInitialised(view: DisplayViewKey) {
        // objectives should only be initialised once both have finished loading
        if (view === DisplayViewKey.ObjectivesView) {
            this.objectivesInitialisedCount++;
            if (this.objectivesInitialisedCount < 2) {
                return;
            }
        }

        this.views[view].initialised = true;
        this.checkViewsAllInitialised$.next();
    }

    public collapseAll() {
        this.setCollapsed(true);
    }

    public expandAll() {
        this.setCollapsed(false);
    }

    private async setCollapsed(collapsed: boolean) {
        for (const viewKey of Object.keys(StrategyDashboardPageComponent.CollapseState)) {
            const view = viewKey as keyof typeof StrategyDashboardPageComponent.CollapseState;
            StrategyDashboardPageComponent.CollapseState[view] = collapsed;
        }

        if (this.strategicAnchors) {
            this.strategicAnchors.collapsed = collapsed;
        }

        const zones = [Zone.HealthyCulture, Zone.FinanceAndOwnership, Zone.EconomicEngine, Zone.OrganisationDesign];

        for (const zone of zones) {
            await this.strategicGoal?.detectChanges(zone, !collapsed);
        }
    }
}

export const StrategyDashboardPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-strategy-dashboard-page", StrategyDashboardPageComponent)
    .atOrganisationUrl("/strategic-dashboard")
    .redirectToThisRouteFromOrganisationUrl("/strategy-dashboard")
    .reloadOnSearch(false)
    .withTitle("Strategic Dashboard")
    .build();
