<form *adaptLoading="!runData?.response"
      class="d-flex flex-column gap-3">
    <div>
        <strong>1.</strong> Install an authenticator app on your mobile device.
        Some examples are Google Authenticator or Microsoft Authenticator.
    </div>

    <div><strong>2.</strong> Scan the following QR code in your authenticator app</div>

    @if (authenticatorUri) {
        <div>
            <qrcode [qrdata]="authenticatorUri"
                    [width]="256"></qrcode>
        </div>
    } @else {
        <div class="alert alert-danger">Failed to load the authentication QR code. Please try again.</div>
    }

    <div><strong>3.</strong> Enter the code from your authenticator app below</div>

    <dx-text-box [value]="twoFactorCode"
                 (valueChange)="onTwoFactorCodeChange($event)"
                 [width]="150"
                 placeholder="123456"
                 data-test="two-factor-input"></dx-text-box>

    <div>
        <h3 class="btn btn-link"
            (click)="secretCollapsed = !secretCollapsed">
            Can't scan the QR code? Follow alternative steps
            <button [adaptCollapsibleButton]="secretCollapsed"></button>
        </h3>
        <div [collapse]="secretCollapsed"
             [isAnimated]="true">
            Configure your authenticator app manually using the following code:

            <div class="d-flex form-group">
                <dx-text-box #tokenInput
                             class="flex-grow-1"
                             [value]="runData!.response!.sharedKey"
                             [readOnly]="true"
                             data-test="two-factor-secret"></dx-text-box>
                <button type="button"
                        class="ms-2 btn border border-primary"
                        [ngClass]="{'btn-outline-primary': copiedSecret, 'btn-primary': !copiedSecret}"
                        [adaptTooltip]="copiedSecret ? 'Copied' : 'Copy to clipboard'"
                        [adaptButtonIcon]="'fal fa-fw ' + (copiedSecret ? 'fa-check' : 'fa-copy')"
                        (click)="copyToClipboard(tokenInput.value)"></button>
            </div>
        </div>
    </div>
</form>
