import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { IManageTwoFactorResponse, IManageTwoFactorViewModel } from "@common/identity/manage-two-factor.interface";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";

export interface ITwoFactorWorkflowRunData {
    text?: string;
    successMessage?: string;
    viewModel?: IManageTwoFactorViewModel;
    response?: IManageTwoFactorResponse;
}

const twoFactorWorkflowId = "TwoFactorWorkflow";
export const twoFactorWorkflow = buildLocalWorkflow({
    name: "Set up two-factor authentication",
    workflowId: twoFactorWorkflowId,
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.Medium,
    private: true,
    isStateless: true,
    runData: {
        successMessage: "Two-factor authentication has successfully been enabled for your account.",
    } as ITwoFactorWorkflowRunData,
    steps: [
        {
            name: "Set up two-factor authentication",
            ordinal: 0,
            allowBack: false,
            canSkip: false,
            componentSelector: "adapt-two-factor-workflow-set-up",
            workflowStepNextText: "Next",
        },
        {
            name: "Save your recovery codes",
            ordinal: 1,
            allowBack: false,
            canSkip: false,
            componentSelector: "adapt-two-factor-workflow-recovery-codes",
        },
    ],
});

export const twoFactorRecoveryCodeWorkflow = buildLocalWorkflow({
    name: "Regenerate your recovery codes",
    workflowId: twoFactorWorkflowId,
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.Medium,
    private: true,
    isStateless: true,
    runData: {
        text: `<p>Are you sure you want to regenerate the recovery codes for your account? Any previously saved recovery codes will no longer be valid.</p>
            <p>Please enter your password to confirm that you want to regenerate the recovery codes for your account.</p>`,
        successMessage: "New recovery codes have successfully been generated for your account.",
        viewModel: { resetRecoveryCodes: true },
    } as ITwoFactorWorkflowRunData,
    steps: [
        {
            name: "Regenerate your recovery codes",
            ordinal: 0,
            allowBack: false,
            canSkip: false,
            componentSelector: "adapt-two-factor-workflow-confirm-password",
            workflowStepNextText: "Regenerate codes",
        },
        {
            name: "Save your recovery codes",
            ordinal: 1,
            allowBack: false,
            canSkip: false,
            componentSelector: "adapt-two-factor-workflow-recovery-codes",
        },
    ],
});

export const twoFactorDisableWorkflow = buildLocalWorkflow({
    name: "Disable two-factor authentication",
    workflowId: twoFactorWorkflowId,
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.Medium,
    private: true,
    isStateless: true,
    runData: {
        text: `<p>Are you sure you want to disable two-factor authentication for your account?</p>
            <p>Please enter your password to confirm that you want to disable two-factor authentication.</p>`,
        viewModel: { resetSharedKey: true },
    } as ITwoFactorWorkflowRunData,
    steps: [
        {
            name: "Disable two-factor authentication",
            ordinal: 0,
            allowBack: false,
            canSkip: false,
            componentSelector: "adapt-two-factor-workflow-confirm-password",
            workflowStepNextText: "Disable two-factor authentication",
            workflowStepNextButtonClass: "btn btn-danger",
        },
    ],
});
