import { Component, OnInit } from "@angular/core";
import { OrganisationCategoryValue, Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { merge } from "rxjs";
import { OrganisationOutcomes } from "../organisation-outcomes";
import { WorkflowService } from "../workflow.service";
import { WorkflowTour } from "../workflow-tour";
import { CategoryWorkflowStatus, CategoryWorkflowStatuses } from "./category-workflow-status.enum";

@Component({
    selector: "adapt-workflow-map-dashboard-element",
    templateUrl: "./workflow-map-dashboard-element.component.html",
    styleUrls: ["./workflow-map-dashboard-element.component.scss"],
})
export class WorkflowMapDashboardElementComponent extends BaseComponent implements OnInit {
    public categories = Object.values(OrganisationCategoryValue)
        .filter((category: string) => category !== OrganisationCategoryValue.Others);
    public categoriesWorkflows: IGroupedData<OrganisationCategoryValue | undefined, Workflow>[] = [];

    public hiddenStatuses: CategoryWorkflowStatus[] = [];

    public workflowStatuses: IAdaptMenuItem[] = CategoryWorkflowStatuses.map((s) => {
        return {
            text: s.name,
            isChecked: !this.hiddenStatuses.includes(s.value),
            onIsCheckedChange: (isChecked) => {
                if (isChecked) {
                    this.hiddenStatuses.splice(this.hiddenStatuses.indexOf(s.value), 1);
                } else if (!this.hiddenStatuses.includes(s.value)) {
                    this.hiddenStatuses.push(s.value);
                }
                this.updateData();
            },
        };
    });
    public menuItems: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [...this.workflowStatuses, {
            text: "Tour",
            separatorTop: true,
            icon: "fal fa-location-arrow",
            onClick: () => this.runTour(),
        }],
    }];

    constructor(
        private workflowService: WorkflowService,
        rxjsBreezeService: RxjsBreezeService,
        private guidedTourService: GuidedTourService,
    ) {
        super();

        merge(
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public async ngOnInit() {
        await this.updateData();
    }

    public async updateData() {
        const workflows: Workflow[] = [];

        for (const category of this.categories) {
            const categoryWorkflows = this.workflowService.getAllWorkflowsByCategory(category)
                .filter((i) => !i.private && !i.isStateless)
                .sort(SortUtilities.getSortByFieldFunction<Workflow>("ordinal"));

            const filteredWorkflows = await this.workflowService.filterWorkflows(categoryWorkflows, this.hiddenStatuses);
            workflows.push(...filteredWorkflows);
        }

        this.categoriesWorkflows = ArrayUtilities.groupArrayBy(workflows, (w) => w.category);
    }

    public getOutcomeData(category: IGroupedData<OrganisationCategoryValue | undefined, Workflow>) {
        return OrganisationOutcomes[category.key!];
    }

    public runTour() {
        this.guidedTourService.run(WorkflowTour);
    }
}
