import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IManageTwoFactorResponse, IManageTwoFactorViewModel } from "@common/identity/manage-two-factor.interface";
import { firstValueFrom, lastValueFrom, Observable, throwError } from "rxjs";
import { catchError, debounceTime, filter, skip, switchMap, take, tap } from "rxjs/operators";
import { AdaptClientConfiguration } from "../configuration/adapt-client-configuration";
import { Autobind } from "../lib/autobind.decorator/autobind.decorator";
import { Cookie } from "../lib/cookie";
import { ErrorHandlingUtilities } from "../lib/utilities/error-handling-utilities";
import { FunctionUtilities } from "../lib/utilities/function-utilities";
import { BaseInitialisationService } from "../service/base-initialisation.service";
import { IAddPeopleBindingModel } from "./add-people-binding-model.interface";
import { IExternalLoginProviderStatus, IExternalLoginStatus } from "./external-login/external-login-status.interface";
import { IdentityActions, IIdentityAction } from "./identity.actions";
import { IdentityStorageService, ISessionData } from "./identity-storage.service";
import { IIdentityViewModel } from "./identity-view-model.interface";
import { IChangePasswordPayload, IForgotPasswordPayload, ILoginPayload, IResetPasswordPayload } from "./ux/identity-ux.service";

@Injectable({
    providedIn: "root",
})
export class IdentityService extends BaseInitialisationService {
    private static readonly cspEndpoint = "/ExternalServiceHook/ReportContentSecurityPolicyViolation";
    private static readonly accessTokenCookieName = "access_token";

    public promiseToGetAccessToken = this.promiseAfterInitialisation(() => this.identityStorage.accessToken);
    public promiseToCheckIsLoggedIn = this.promiseAfterInitialisation(() => this.identityStorage.isLoggedIn);
    public promiseToCheckIsAnonymous = this.promiseAfterInitialisation(() => !this.identityStorage.isLoggedIn);

    // added a way for logout to not emit to logout$, which will cause redirection to login page, e.g. quiet logout from health-check page before signing up
    private skipLogoutHandlers = false;

    public constructor(
        private http: HttpClient,
        private identityStorage: IdentityStorageService,
    ) {
        super();

        this.registerActions();
        this.identity$.subscribe((i) => {
            if (i) {
                Cookie.set({
                    name: IdentityService.accessTokenCookieName,
                    value: i.access_token,
                    path: IdentityService.cspEndpoint,
                });
            } else {
                Cookie.delete(IdentityService.accessTokenCookieName, IdentityService.cspEndpoint);
            }
        });
    }

    protected initialisationActions() {
        return [this.promiseToVerifyExistingToken()];
    }

    public login = (viewModel: ILoginPayload) => this.performAction<ISessionData>(IdentityActions.Actions.Login, viewModel);
    public validateAuthenticationToken = () => this.performAction(IdentityActions.Actions.ValidateAuthenticationToken);
    public registerOrganisation = (viewModel: IIdentityViewModel) => this.performAction(IdentityActions.Actions.RegisterOrganisation, viewModel);
    public forgotPassword = (viewModel: IForgotPasswordPayload) => this.performAction(IdentityActions.Actions.ForgotPassword, viewModel);
    public resetPassword = (viewModel: IResetPasswordPayload) => this.performAction(IdentityActions.Actions.ResetPassword, viewModel);
    public isRegistered = (viewModel: IIdentityViewModel) => this.performAction<boolean>(IdentityActions.Actions.IsRegistered, viewModel);
    public getExternalLoginStatus = () => this.performAction<IExternalLoginStatus>(IdentityActions.Actions.GetExternalLoginStatus);
    public externalLoginCallback = () => this.performAction<ISessionData | IExternalLoginProviderStatus>(IdentityActions.Actions.ExternalLoginCallback);
    public manageTwoFactor = (viewModel: IManageTwoFactorViewModel) => this.performAction<IManageTwoFactorResponse>(IdentityActions.Actions.ManageTwoFactor, viewModel);
    public logout = (skipLogoutHandlers = false) => {
        this.skipLogoutHandlers = skipLogoutHandlers;
        return this.performAction(IdentityActions.Actions.Logout);
    };
    public changePassword = (viewModel: IChangePasswordPayload) => this.performAction(IdentityActions.Actions.ChangePassword, viewModel);
    public getHelpjuiceToken = () => this.performAction<string>(IdentityActions.Actions.GetHelpjuiceToken);
    public getServerSecurityRoles = () => this.performAction<string[]>(IdentityActions.Actions.GetServerSecurityRoles);
    public addPeople = (viewModel: IAddPeopleBindingModel) => this.performAction(IdentityActions.Actions.AddPeople, viewModel);
    public sendWelcomeEmail = (viewModel: IIdentityViewModel) => this.performAction(IdentityActions.Actions.SendWelcomeEmail, viewModel);

    public async promiseToDoIfLoggedIn(fn: () => any, otherwise?: VoidFunction) {
        await firstValueFrom(this.initialisation$);

        if (this.identityStorage.isLoggedIn) {
            return fn();
        } else if (FunctionUtilities.isFunction(otherwise)) {
            return otherwise();
        }

        return Promise.reject({ requireLogin: true });
    }

    @Autobind
    public clearAuthenticationData() {
        this.identityStorage.clearSessionData();
    }

    /** Returns a hot observable that emits whenever a logout is performed */
    public get logout$() {
        return this.identity$.pipe(
            skip(1),
            debounceTime(100), // multiple paths can lead to here from clear or set session data from logout success and local clear of auth data - only need to emit once!
            filter((i) => {
                const passFilter = !i && !this.skipLogoutHandlers;
                if (!i && this.skipLogoutHandlers) {
                    this.skipLogoutHandlers = false; // reset this so next logout will emit
                }
                return passFilter;
            }),
        );
    }

    /** Returns a hot observable which will emit the current identity, and then each time
     * it changes (from log out/log in)
     */
    public get identity$() {
        return this.waitUntilInitialised().pipe(
            switchMap(() => this.identityStorage.identity$),
        );
    }

    public get identityChangedInAnotherTab$() {
        return this.identityStorage.identityChangedInAnotherTab$;
    }

    private promiseToVerifyExistingToken() {
        if (this.identityStorage.isLoggedIn) {
            // Don't log this - it is an expected part of normal operation
            return this.validateAuthenticationToken()
                .catch((e: HttpErrorResponse) => {
                    this.log.warn(`ValidateAuthenticationToken failed with status: ${e.statusText} - invalid token`);
                    this.clearAuthenticationData();
                });
        } else {
            return Promise.resolve();
        }
    }

    private performAction<T = any>(action: IIdentityAction, viewModel?: IIdentityViewModel): Promise<HttpResponse<T>> {
        if (action.redirectUrl && viewModel) {
            viewModel.redirectUrl = window.location.href;
            viewModel.redirectUrl = viewModel.redirectUrl!.replace(window.location.pathname, action.redirectUrl);
        }

        const uri = AdaptClientConfiguration.ApiBaseUri + action.partialUri;
        const model = FunctionUtilities.isFunction(action.dataTransform)
            ? action.dataTransform(viewModel)
            : viewModel;
        const config: any = {
            headers: action.headers,
            observe: "response" as const,
            responseType: "json" as const,
            withCredentials: action.withCredentials || false,
        };

        let request$: Observable<any>;
        if (action.httpMethod === "get") {
            request$ = this.http.get(uri, config);
        } else if (action.httpMethod === "post") {
            request$ = this.http.post(uri, model, config);
        } else if (action.httpMethod === "put") {
            request$ = this.http.put(uri, model, config);
        } else {
            // Don't simply call $http[method] as function arguments are different!
            request$ = throwError("Unexpected httpMethod " + action.httpMethod);
        }

        const action$ = request$.pipe(
            tap((response: HttpResponse<T>) => {
                this.log.debug("http " + action.httpMethod + " successful", response);
                if (FunctionUtilities.isFunction(action.onSuccess)) {
                    action.onSuccess(response);
                }
            }),
            catchError((response: HttpErrorResponse) => {
                this.log.info(ErrorHandlingUtilities.getHttpResponseMessage(response), response);

                // something went wrong, so clear session data - security precaution
                if (FunctionUtilities.isFunction(action.onError)) {
                    action.onError(response);
                }

                return throwError(() => response);
            }),
        );

        if (action.preInitialisation) {
            return lastValueFrom(action$);
        } else {
            return lastValueFrom(this.initialisation$.pipe(
                take(1),
                switchMap(() => action$),
            ));
        }
    }

    private registerActions() {
        IdentityActions.Actions.Logout.onSuccess = this.clearAuthenticationData;
        IdentityActions.Actions.Logout.onError = this.clearAuthenticationData;
    }
}
