import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { BillingPeriod, BillingPeriodButtons } from "@common/ADAPT.Common.Model/account/account-extensions";
import { AccountModule } from "@common/ADAPT.Common.Model/account/account-module";
import { Module, ModuleType, PlatformCategory } from "@common/ADAPT.Common.Model/embed/module";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { EulaService } from "@org-common/lib/eula/eula.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { DxButtonGroupComponent } from "devextreme-angular";
import { filter, finalize, lastValueFrom, of, switchMap, tap } from "rxjs";
import { ConfigurationAuthService } from "../configuration-auth.service";

const ShowSubscriptionDialogParamKey = "showSubscriptionDialog";

interface ICategorisedModules {
    category?: PlatformCategory,
    modules: Module[],
}

@Component({
    selector: "adapt-subscription-page",
    templateUrl: "./subscription-page.component.html",
    styleUrl: "./subscription-page.component.scss",
})
export class SubscriptionPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly BillingPeriodButtons = BillingPeriodButtons;
    public account?: Account;
    public platformModules?: Module[];
    public categorisedPlatformModules: ICategorisedModules[] = [];
    public addOnModules?: Module[];
    public initialPlatformModules: Module[] = [];
    public initialAddons: Module[] = [];

    // Capture these here as Min said Current subscription is showing the data before changes - not after
    public currentBillingPeriodLabel?: string;
    public currentMonthlySubscriptionCost?: number;
    public currentAnnualDiscountPercentage?: number;
    public currentAnnualSubscriptionCost?: number;
    public isCurrentAnnualPlan = false;

    public hasChanges = false;
    private hasChangesUpdater = this.createThrottledUpdater<boolean>((hasChanges) => this.hasChanges = hasChanges);
    private deletedAccountModules: AccountModule[] = [];
    private unregisterCleanupFunction: (() => void);
    private redirectedFromGuard = false;
    public allowsModuleSelection = false;

    @ViewChild("billingPeriodButtons") private billingPeriodButtons?: DxButtonGroupComponent;

    public constructor(
        public accountService: AccountService,
        private eulaService: EulaService,
        private commonDataService: CommonDataService,
        injector: Injector,
        changeManagerService: ChangeManagerService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPage: IAdaptRoute<{}>,
    ) {
        super(injector);
        // discard all changes without prompting as suggested by Steve as there are nothing much to lose
        // - changeManagerService is expecting cleanup to promise
        this.unregisterCleanupFunction = changeManagerService.registerCleanupFunction(() => lastValueFrom(this.discardChanges()));
    }

    public ngOnDestroy() {
        this.unregisterCleanupFunction();
        super.ngOnDestroy();

        // do these in separate
        this.accountService.updateSubscriptionBannerOnPageChange(this.account);
        this.eulaService.updateBannerOnPageChange();
    }

    public ngOnInit() {
        this.accountService.getAccount().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((account) => {
            if (account?.pricingModel?.pricingModelModules.length) {
                const modules = account.pricingModel.pricingModelModules
                    .map((m) => m.module)
                    .sort((m1, m2) => m1.ordinal - m2.ordinal);
                this.addOnModules = modules
                    .filter((m) => m.moduleType === ModuleType.AddOn);
                this.platformModules = modules
                    .filter((m) => m.moduleType === ModuleType.Platform);
                this.categorisedPlatformModules = this.platformModules.reduce((groups, module) => {
                    let categorisedGroup = groups.find((g) => g.category === module.platformCategory);
                    if (!categorisedGroup) {
                        categorisedGroup = {
                            category: module.platformCategory,
                            modules: [],
                        };
                        groups.push(categorisedGroup);
                    }

                    categorisedGroup.modules.push(module);
                    return groups;
                }, [] as ICategorisedModules[]);
            } else {
                this.addOnModules = [];
                this.platformModules = [];
            }

            this.account = account;
            this.allowsModuleSelection = this.addOnModules.length > 0 || this.platformModules.length > 0;
            this.initialPlatformModules = this.account!.extensions.platformModules;
            this.initialAddons = this.account!.extensions.addOnModules;
            this.updateCurrentSnapshot();
            this.validateBillingPeriod();
            this.notifyActivated();
            this.accountService.updateSubscriptionBanner(account);
            this.eulaService.updateEulaBanner();
        });

        this.searchParameterChanged.pipe(
            filter(() => this.getSearchParameterBoolValue(ShowSubscriptionDialogParamKey)),
            tap(() => {
                this.redirectedFromGuard = true;
                this.deleteSearchParameter(ShowSubscriptionDialogParamKey);
            }),
            switchMap(() => this.accountService.showSubscriptionDialog(true)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get annualDiscountPercentage() {
        return this.account?.extensions.annualDiscountPercentage ?? 0;
    }

    public get saveButtonText() {
        if (this.account?.extensions.isActive) {
            return "Update plan";
        } else {
            return (this.account?.extensions.isTrial || this.account?.extensions.isZeroDayTrial) ? "Start subscription" : "Resume subscription";
        }
    }

    @Autobind
    public saveChanges() {
        return this.accountService.showSubscriptionDialog(false, true).pipe(
            tap(() => {
                this.deletedAccountModules = [];
                this.initialPlatformModules = this.account!.extensions.platformModules;
                this.initialAddons = this.account!.extensions.addOnModules;
                this.updateCurrentSnapshot();
            }),
            switchMap(() => {
                if (this.redirectedFromGuard) {
                    this.redirectedFromGuard = false;
                    this.accountService.emitFirstPaymentReceived(true); // this will trigger the tag event

                    return this.personalDashboardPage.gotoRoute();
                }

                return of(undefined);
            }),
            finalize(() => this.updateHasChanges()),
        );
    }

    public onBillingPeriodChanged(newBillingPeriod: number, fromButtonEvent = false) {
        this.account!.billingPeriod = newBillingPeriod;
        this.commonDataService.rejectUnchangedModifiedEntity(this.account!); // don't save unchanged entity!
        this.updateCurrentSnapshotIfTrial(fromButtonEvent); // not going to validate billing period again if from billing period button selection
    }

    public async onPlatformModuleChanged(isSelected: boolean, module: Module) {
        if (isSelected && this.account) {
            const existingModule = this.account.accountModules.find((am) => am.module.platformCategory === module.platformCategory);
            if (existingModule) {
                existingModule.moduleId = module.moduleId;
            } else {
                await lastValueFrom(this.accountService.createAccountModule(this.account, module));
            }

            this.commonDataService.rejectUnchangedModifiedEntity(this.account); // don't save unchanged entity!
            this.updateCurrentSnapshotIfTrial();
        }
    }

    public isModuleSelected(module: Module) {
        return this.account!.accountModules.find((am) => am.moduleId === module.moduleId) !== undefined;
    }

    public isCurrentPlan(module: Module) {
        return !!this.initialPlatformModules.find((a) => a === module);
    }

    public isNewPlan(module: Module) {
        if (this.isCurrentPlan(module)) {
            return false;
        }

        return this.isModuleSelected(module);
    }

    public isInitialAddon(addon: Module) {
        if (this.initialAddons) {
            return !!this.initialAddons.find((a) => a === addon);
        }

        return false;
    }

    public isAddonSelected(addon: Module) {
        this.account?.extensions.addOnModules;
        return !!this.account?.extensions.addOnModules.find((a) => a.moduleId === addon.moduleId);
    }

    public getAccountModule(addon: Module) {
        return this.account?.accountModules.find((a) => a.moduleId === addon.moduleId);
    }

    public isNewAddon(addon: Module) {
        return (this.account!.extensions.isActive && this.isAddonSelected(addon) && !this.isInitialAddon(addon)) ||
            // this is for resume/start subscription for inactive/trial account
            (!this.account!.extensions.isActive && this.isAddonSelected(addon));
    }

    public isUpdatedAddon(addon: Module) {
        const accountModule = this.account?.accountModules.find((a) => a.moduleId === addon.moduleId);
        return accountModule?.entityAspect.entityState.isModified();
    }

    public onAddonSelectionChanged(selected: boolean, addonModel: Module) {
        if (this.account) {
            if (selected) {
                // check if there is a matching deleted entity - revert that instead
                const matchingDeletion = this.deletedAccountModules.find((a) => a.moduleId === addonModel.moduleId && !a.entityAspect.entityState.isDetached());
                if (matchingDeletion) {
                    this.accountService.rejectChanges(matchingDeletion).subscribe(() => {
                        ArrayUtilities.removeElementFromArray(matchingDeletion, this.deletedAccountModules);
                        this.updateCurrentSnapshotIfTrial();
                    });
                } else {
                    this.accountService.createAccountModule(this.account, addonModel).subscribe(() => this.updateCurrentSnapshotIfTrial());
                }
            } else {
                const deleteAddon = this.account.accountModules.find((a) => a.moduleId === addonModel.moduleId);
                if (deleteAddon) {
                    this.accountService.remove(deleteAddon).subscribe(() => {
                        // only add if not detached (detached -> newly created entity discarded)
                        if (!deleteAddon.entityAspect.entityState.isDetached()) {
                            this.deletedAccountModules.push(deleteAddon);
                        }

                        this.updateCurrentSnapshotIfTrial();
                    });
                }
            }
        }
    }

    @Autobind
    public cancelSubscription() {
        // need to discardChanges first before cancelling or the new breeze will stop the account entity from being updated
        // after cancelling, leaving account entity in a weird state!
        return this.discardChanges().pipe(
            switchMap(() => this.accountService.cancelSubscriptionDialog()),
        );
    }

    private updateCurrentSnapshotIfTrial(skipBillingPeriodValidation = false) {
        if (this.account?.extensions.isTrial || this.account?.extensions.isZeroDayTrial) {
            // showing currently selected modules as there was no previous plan
            this.updateCurrentSnapshot();
        }

        this.updateHasChanges();
        if (!skipBillingPeriodValidation) {
            this.validateBillingPeriod();
        }
    }

    private updateCurrentSnapshot() {
        this.currentBillingPeriodLabel = this.newBillingPeriodLabel;
        this.currentAnnualDiscountPercentage = this.newAnnualDiscountPercentage;
        this.currentMonthlySubscriptionCost = this.newMonthlySubscriptionCost;
        this.currentAnnualSubscriptionCost = this.newAnnualSubscriptionCost;
        this.isCurrentAnnualPlan = this.isNewAnnualPlan;
    }

    private validateBillingPeriod() {
        if ((!this.newMonthlySubscriptionCost || this.hasAddonModule) && this.isNewAnnualPlan) {
            // if not paying anything - cannot select annual (or you will see $0 you're saving 30%)
            this.onBillingPeriodChanged(BillingPeriod.Monthly);
            // this is required in a timeout to avoid being call from another user triggered event (clicking on another button)
            // which would cause the buttons to be broken sometimes
            setTimeout(() => this.billingPeriodButtons?.instance.repaint());
        }
    }

    public get isNewAnnualPlan() {
        return this.account!.billingPeriod === BillingPeriod.Annually;
    }

    public get newBillingPeriodLabel() {
        return StringUtilities.upperCaseFirstCharacter(this.account!.extensions.billingPeriodLabel());
    }

    public get newMonthlySubscriptionCost() {
        return Math.floor(this.account!.extensions.monthlySubscriptionCost ?? 0);
    }

    public get newAnnualSubscriptionCost() {
        return this.account!.extensions.annualSubscriptionCost ?? 0;
    }

    public get newAnnualDiscountPercentage() {
        return this.account!.extensions.appliedAnnualDiscountPercentage;
    }

    public get hasAddonModule() {
        return this.account!.accountModules.some((m) => m.module.moduleType === ModuleType.AddOn);
    }

    public updateHasChanges() {
        this.account?.accountModules.forEach((accountModule) => this.commonDataService.rejectUnchangedModifiedEntity(accountModule));

        // note: using throttle updater here to update save button state after adaptBlockingClick reenable it after observable completes
        // To test this: change pricing model/addon, then click on Update plan, and after save and all dialogs dismissed, the Update plan
        // button should be disabled.
        this.hasChangesUpdater.next(!this.account?.entityAspect.entityState.isUnchanged() ||
            this.deletedAccountModules.length > 0 ||
            this.account?.accountModules.some((a) => !a.entityAspect.entityState.isUnchanged()));
    }

    private discardChanges() {
        return this.accountService.rejectChanges([
            this.account!,
            ...this.account!.accountModules,
            ...this.deletedAccountModules,
        ]).pipe(
            tap(() => this.deletedAccountModules = []),
        );
    }
}

export const SubscriptionPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-subscription-page", SubscriptionPageComponent)
    .atOrganisationUrl("/subscription")
    .withTitle("Subscription")
    .verifyingAccess(ConfigurationAuthService.ConfigureOrganisationBilling)
    .build();
