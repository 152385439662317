import { Component, OnInit } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { IdentityService } from "@common/identity/identity.service";
import { IManageTwoFactorResponse } from "@common/identity/manage-two-factor.interface";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { switchMap, tap } from "rxjs/operators";
import { twoFactorDisableWorkflow, twoFactorRecoveryCodeWorkflow, twoFactorWorkflow } from "../two-factor-workflow/two-factor-workflow";

@Component({
    selector: "adapt-configure-personal-mfa",
    templateUrl: "./configure-personal-mfa.component.html",
})
export class ConfigurePersonalMfaComponent extends BaseComponent implements OnInit {
    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public readonly articleUrl = ImplementationKitService.GetArticleLink(ImplementationKitArticle.TwoFactorOverview);

    public manageTwoFactorStatus?: IManageTwoFactorResponse;

    public twoFactorDisabledAlert = false;
    public twoFactorEnabledAlert = false;

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private identityService: IdentityService,
    ) {
        super();
    }

    public async ngOnInit() {
        await this.updateData();
    }

    public setUpTwoFactor() {
        this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: twoFactorWorkflow,
            runData: twoFactorWorkflow.runData,
        }).pipe(
            tap(() => this.twoFactorEnabledAlert = true),
            switchMap(() => this.updateData()),
        ).subscribe();
    }

    public regenerateRecoveryCodes() {
        this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: twoFactorRecoveryCodeWorkflow,
            runData: twoFactorRecoveryCodeWorkflow.runData,
        }).pipe(
            switchMap(() => this.updateData()),
        ).subscribe();
    }

    public disableTwoFactor() {
        this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: twoFactorDisableWorkflow,
            runData: twoFactorDisableWorkflow.runData,
        }).pipe(
            tap(() => this.twoFactorDisabledAlert = true),
            switchMap(() => this.updateData()),
        ).subscribe();
    }

    private async updateData() {
        const response = await this.identityService.manageTwoFactor({});
        this.manageTwoFactorStatus = response.body ?? undefined;
    }
}
