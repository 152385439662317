<p>Update the password you use to log in to your {{projectLabel}} account.</p>

@if (submitMessage) {
    <div role="alert"
         class="alert"
         [ngClass]="{'alert-danger': isError, 'alert-success': !isError}"
         [innerHtml]="submitMessage"></div>
}

@if (userHasPassword) {
    <button adaptButtonIcon="fal fa-key"
            (click)="changePassword(person!)"
            data-test="change-password-button">Change password</button>
} @else {
    <div class="alert alert-warning m-0">
        <p>Your account does not have a password set.</p>
        <p>Clicking the button below will send a password reset email to your account's email address,
            which will allow you to set a password for your account.</p>

        <button class="btn btn-primary"
                [adaptBlockingClick]="resetPassword"
                data-test="send-password-reset-email">Send password reset email</button>
    </div>
}
