import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { provideSubscriptionPageRoute } from "@common/page-route-providers";
import { AdaptPaymentProcessingModule } from "@common/payment-processing/payment-processing.module";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { provideNavigationHierarchy } from "@common/route/navigation-hierarchy";
import { provideSidebarTab } from "@common/shell/common-sidebar/sidebar-tab";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { AdaptIncompleteWorkModule } from "@common/ux/incomplete-work/incomplete-work.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptUniversalDashboardElementModule } from "@common/ux/universal-dashboard-element/universal-dashboard-element.module";
import { ConfigurePersonalProfileComponent } from "@org-common/lib/configuration/organisation/configure-personal-profile/configure-personal-profile.component";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { QRCodeModule } from "angularx-qrcode";
import { DxButtonGroupModule, DxCheckBoxModule, DxDateBoxModule, DxListModule, DxNumberBoxModule, DxRadioGroupModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidationGroupModule } from "devextreme-angular";
import { AlertComponent } from "ngx-bootstrap/alert";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptAuthorisationModule } from "../authorisation/authorisation.module";
import { AdaptCalendarModule } from "../calendar/calendar.module";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "../labelling/labeling.module";
import { AdaptSurveyModule } from "../survey/survey.module";
import { AdaptCommonTeamsModule } from "../teams/common-teams.module";
import { AdaptTeamDashboardSharedModule } from "../teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptUserManagementSharedModule } from "../user-management/user-management-shared.module";
import { ConfigurationPageBaseComponent } from "./base-components/configuration-page-base/configuration-page-base.component";
import { FeatureConfigurationComponent } from "./base-components/feature-configuration/feature-configuration.component";
import { FeatureGuidanceComponent } from "./base-components/feature-guidance/feature-guidance.component";
import { BillingPageComponent, BillingPageRoute } from "./billing-page/billing-page.component";
import { ConfigureGenericFeatureComponent } from "./common/configure-generic-feature/configure-generic-feature.component";
import { ConfigureKanbanBoardsComponent } from "./common/configure-kanban-boards/configure-kanban-boards.component";
import { ConfigureMeetingAgendaTemplatesComponent } from "./common/configure-meeting-agenda-templates/configure-meeting-agenda-templates.component";
import { ConfigurationOrganisationHierarchyNavigationService } from "./configuration-tab-content/configuration-organisation-hierarchy-navigation.service";
import { ConfigurationPersonalHierarchyNavigationService } from "./configuration-tab-content/configuration-personal-hierarchy-navigation.service";
import { ConfigurationTab } from "./configuration-tab-content/configuration-tab";
import { ConfigurationTabContentComponent } from "./configuration-tab-content/configuration-tab-content.component";
import { ConfigurationTeamHierarchyNavigationService } from "./configuration-tab-content/configuration-team-hierarchy-navigation.service";
import { CancelSubscriptionDialogComponent } from "./organisation/cancel-subscription-dialog/cancel-subscription-dialog.component";
import { ChangePaymentFrequencyDialogComponent } from "./organisation/change-payment-frequency-dialog/change-payment-frequency-dialog.component";
import { ConfigureBillingDetailsComponent } from "./organisation/configure-billing-details/configure-billing-details.component";
import { ConfigureCalendarIntegrationComponent } from "./organisation/configure-calendar-integration/configure-calendar-integration.component";
import { ConfigureEngagementComponent } from "./organisation/configure-engagement/configure-engagement.component";
import { ConfigureEulaComponent } from "./organisation/configure-eula/configure-eula.component";
import { ConfigureExternalDashboardComponent } from "./organisation/configure-external-dashboard/configure-external-dashboard.component";
import { ConfigureFeatureFlagsComponent } from "./organisation/configure-feature-flags/configure-feature-flags.component";
import { ConfigureLabelsComponent } from "./organisation/configure-labels/configure-labels.component";
import { ConfigureMeetingsComponent } from "./organisation/configure-meetings/configure-meetings.component";
import { ConfigureObjectivesComponent } from "./organisation/configure-objectives/configure-objectives.component";
import { ConfigureOrganisationProfileComponent } from "./organisation/configure-organisation-profile/configure-organisation-profile.component";
import { ConfigurePaymentMethodComponent } from "./organisation/configure-payment-method/configure-payment-method.component";
import { ConfigurePurposeAndVisionComponent } from "./organisation/configure-purpose-and-vision/configure-purpose-and-vision.component";
import { ConfigureResilientBusinessGoalsComponent } from "./organisation/configure-rb-goals/configure-rb-goals.component";
import { ConfigureResilientBusinessAssessmentComponent } from "./organisation/configure-resilient-business-assessment/configure-resilient-business-assessment.component";
import { ConfigureTeamAssessmentComponent } from "./organisation/configure-team-assessment/configure-team-assessment.component";
import { ConfigureTeamsComponent } from "./organisation/configure-teams/configure-teams.component";
import { ConfigureValuesConstitutionComponent } from "./organisation/configure-values-constitution/configure-values-constitution.component";
import { ConfigureWorkComponent } from "./organisation/configure-work/configure-work.component";
import { ConfirmCommenceSubscriptionDialogComponent } from "./organisation/confirm-commence-subscription-dialog/confirm-commence-subscription-dialog.component";
import { DeleteLabelDialogComponent } from "./organisation/delete-label-dialog/delete-label-dialog.component";
import { DisplayImpactSurveysComponent } from "./organisation/display-impact-surveys/display-impact-surveys.component";
import { EditPersonalProfileCategoryDialogComponent } from "./organisation/edit-personal-profile-category-dialog/edit-personal-profile-category-dialog.component";
import { EditPersonalProfileItemDialogComponent } from "./organisation/edit-personal-profile-item-dialog/edit-personal-profile-item-dialog.component";
import { MergeLabelsDialogComponent } from "./organisation/merge-labels-dialog/merge-labels-dialog.component";
import { MigrateValuesDialogComponent } from "./organisation/migrate-values-dialog/migrate-values-dialog.component";
import { SelectPaymentFrequencyComponent } from "./organisation/select-payment-frequency/select-payment-frequency.component";
import { UpdateBillingDetailsComponent } from "./organisation/update-billing-details/update-billing-details.component";
import { UpdateBillingDetailsDialogComponent } from "./organisation/update-billing-details-dialog/update-billing-details-dialog.component";
import { UpdateInvoicePaymentMethodDialogComponent } from "./organisation/update-invoice-payment-method-dialog/update-invoice-payment-method-dialog.component";
import { ChangePasswordDialogComponent } from "./personal/change-password-dialog/change-password-dialog.component";
import { ConfigurePersonalAccountSecurityComponent } from "./personal/configure-personal-account-security/configure-personal-account-security.component";
import { ConfigurePersonalChangePasswordComponent } from "./personal/configure-personal-change-password/configure-personal-change-password.component";
import { ConfigurePersonalKanbanComponent } from "./personal/configure-personal-kanban/configure-personal-kanban.component";
import { ConfigurePersonalLoginEmailComponent } from "./personal/configure-personal-login-email/configure-personal-login-email.component";
import { ConfigurePersonalMfaComponent } from "./personal/configure-personal-mfa/configure-personal-mfa.component";
import { ConfigurePersonalNotificationsComponent } from "./personal/configure-personal-notifications/configure-personal-notifications.component";
import { ConfigurePersonalSingleSignOnComponent } from "./personal/configure-personal-single-sign-on/configure-personal-single-sign-on.component";
import { TwoFactorWorkflowConfirmPasswordComponent } from "./personal/two-factor-workflow/two-factor-workflow-confirm-password/two-factor-workflow-confirm-password.component";
import { TwoFactorWorkflowRecoveryCodesComponent } from "./personal/two-factor-workflow/two-factor-workflow-recovery-codes/two-factor-workflow-recovery-codes.component";
import { TwoFactorWorkflowSetUpComponent } from "./personal/two-factor-workflow/two-factor-workflow-set-up/two-factor-workflow-set-up.component";
import { ModelSelectorComponent } from "./subscription-page/model-selector/model-selector.component";
import { SubscriptionPageComponent, SubscriptionPageRoute } from "./subscription-page/subscription-page.component";
import { ConfigureTeamAccessPermissionsComponent } from "./team/configure-team-access-permissions.component/configure-team-access-permissions.component";
import { ConfigureTeamDetailsComponent } from "./team/configure-team-details.component/configure-team-details.component";
import { ConfigureTeamKanbanComponent } from "./team/configure-team-kanban.component/configure-team-kanban.component";
import { ConfigureTeamMeetingsComponent } from "./team/configure-team-meetings/configure-team-meetings.component";
import { ConfigureTeamMembershipComponent } from "./team/configure-team-membership.component/configure-team-membership.component";

@NgModule({
    declarations: [
        ConfigurationPageBaseComponent,
        FeatureConfigurationComponent,
        FeatureGuidanceComponent,
        ConfigureOrganisationProfileComponent,
        ConfigureLabelsComponent,
        ConfigureObjectivesComponent,
        ConfigurePurposeAndVisionComponent,
        ConfigureResilientBusinessGoalsComponent,
        ConfigureTeamsComponent,
        ConfigureValuesConstitutionComponent,
        ConfigureWorkComponent,
        ConfigureMeetingAgendaTemplatesComponent,
        ConfigureKanbanBoardsComponent,
        ConfigureEngagementComponent,
        ConfigureTeamAssessmentComponent,
        ConfigureResilientBusinessAssessmentComponent,
        ConfigurePersonalProfileComponent,
        ConfigureTeamDetailsComponent,
        ConfigureTeamKanbanComponent,
        ConfigureTeamMembershipComponent,
        ConfigurePersonalKanbanComponent,
        ConfigurePersonalNotificationsComponent,
        ConfigureCalendarIntegrationComponent,
        ConfirmCommenceSubscriptionDialogComponent,
        MergeLabelsDialogComponent,
        DeleteLabelDialogComponent,
        DisplayImpactSurveysComponent,
        EditPersonalProfileItemDialogComponent,
        EditPersonalProfileCategoryDialogComponent,
        ConfigureFeatureFlagsComponent,
        ConfigurationTabContentComponent,
        ConfigurePaymentMethodComponent,
        ConfigureBillingDetailsComponent,
        UpdateBillingDetailsDialogComponent,
        UpdateInvoicePaymentMethodDialogComponent,
        CancelSubscriptionDialogComponent,
        ConfigureTeamAccessPermissionsComponent,
        UpdateBillingDetailsComponent,
        MigrateValuesDialogComponent,
        ChangePaymentFrequencyDialogComponent,
        SelectPaymentFrequencyComponent,
        ConfigureMeetingsComponent,
        ConfigureTeamMeetingsComponent,
        ConfigureExternalDashboardComponent,
        ConfigureEulaComponent,
        ConfigurePersonalSingleSignOnComponent,
        ConfigurePersonalAccountSecurityComponent,
        ChangePasswordDialogComponent,
        ConfigurePersonalLoginEmailComponent,
        ConfigurePersonalChangePasswordComponent,
        ConfigureGenericFeatureComponent,
        SubscriptionPageComponent,
        ModelSelectorComponent,
        BillingPageComponent,
        ConfigurePersonalMfaComponent,
        TwoFactorWorkflowSetUpComponent,
        TwoFactorWorkflowRecoveryCodesComponent,
        TwoFactorWorkflowConfirmPasswordComponent,
    ],
    exports: [
        ConfigurationPageBaseComponent,
        FeatureConfigurationComponent,
        FeatureGuidanceComponent,
        ConfigureOrganisationProfileComponent,
        ConfigureLabelsComponent,
        ConfigureObjectivesComponent,
        ConfigurePurposeAndVisionComponent,
        ConfigureResilientBusinessGoalsComponent,
        ConfigureTeamsComponent,
        ConfigureValuesConstitutionComponent,
        ConfigureWorkComponent,
        ConfigureMeetingsComponent,
        ConfigureMeetingAgendaTemplatesComponent,
        ConfigureKanbanBoardsComponent,
        ConfigureEngagementComponent,
        ConfigureTeamAssessmentComponent,
        ConfigureResilientBusinessAssessmentComponent,
        ConfigurePersonalProfileComponent,
        ConfigurePersonalNotificationsComponent,
        ConfigureTeamDetailsComponent,
        ConfigureTeamKanbanComponent,
        ConfigureTeamMeetingsComponent,
        ConfigureTeamMembershipComponent,
        ConfigurePersonalKanbanComponent,
        ConfigureCalendarIntegrationComponent,
        ConfigureFeatureFlagsComponent,
        ConfigureTeamAccessPermissionsComponent,
        UpdateBillingDetailsComponent,
        SelectPaymentFrequencyComponent,
        ConfigureExternalDashboardComponent,
        ConfigurePersonalSingleSignOnComponent,
        ConfigurePersonalAccountSecurityComponent,
        ChangePasswordDialogComponent,
        ConfigureGenericFeatureComponent,
    ],
    providers: [
        provideNavigationHierarchy(ConfigurationPersonalHierarchyNavigationService),
        provideNavigationHierarchy(ConfigurationTeamHierarchyNavigationService),
        provideNavigationHierarchy(ConfigurationOrganisationHierarchyNavigationService),
        provideSidebarTab(ConfigurationTab),
        provideSubscriptionPageRoute(SubscriptionPageRoute),
    ],
    imports: [
        CommonModule,
        FormsModule,
        DxCheckBoxModule,
        DxListModule,
        DxTextAreaModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxRadioGroupModule,
        DxCheckBoxModule,
        DxNumberBoxModule,
        AdaptFeatureModule,
        AdaptAuthorisationModule,
        AdaptTwoColumnModule,
        AdaptButtonModule,
        AdaptDashboardElementModule,
        AdaptEntityValidatorModule,
        AdaptStorageModule,
        AdaptLabellingModule,
        AdaptCommonDialogModule,
        AdaptTooltipModule,
        AdaptCallToActionModule,
        AdaptSurveyModule,
        AdaptCommonTeamsModule,
        AdaptTeamDashboardSharedModule,
        AdaptDateModule,
        AdaptIncompleteWorkModule,
        AdaptDirectorySharedModule,
        AdaptLoadingSpinnerModule,
        AdaptCalendarModule,
        AdaptLinkDisabledModule,
        RouterLink,
        AdaptCommonRouteModule,
        AdaptPaymentProcessingModule,
        AdaptUserManagementSharedModule,
        AdaptCommonUserModule,
        DxButtonGroupModule,
        AdaptMeetingsSharedModule,
        AdaptUniversalDashboardElementModule,
        DxValidationGroupModule,
        AlertComponent,
        CollapseModule,
        QRCodeModule,
        AdaptEditorViewModule,
    ],
})
export class AdaptCommonConfigurationModule {
    public static readonly Routes = [
        ...SubscriptionPageRoute.routes,
        ...BillingPageRoute.routes,
    ];

    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        TwoFactorWorkflowSetUpComponent,
        TwoFactorWorkflowRecoveryCodesComponent,
        TwoFactorWorkflowConfirmPasswordComponent,
    ];
}
