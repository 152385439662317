import { Injectable, OnDestroy } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { forkJoin, from, ObservableInput, Subject } from "rxjs";
import { defaultIfEmpty, delay, switchMap, takeUntil } from "rxjs/operators";

export interface ICustomConfigurationHandler {
    /** Does this handler have any changes? */
    hasChanges(): boolean;

    /** Does this handler have valid changes that can be saved? */
    changesAreValid(): boolean;

    /** On a configuration save, do some work and complete */
    onSave(): ObservableInput<unknown>;

    /** On a configuration cancel, do some work and complete */
    onCancel(): ObservableInput<unknown>;

    /**
     * Should the save/cancel buttons be shown for this configuration page
     */
    shouldShowSaveButtons(): boolean;
}

@Injectable()
export class ConfigurationService implements OnDestroy {
    /**
     * A hot observable which will emit each time configuration pages
     * should be reinitialised.
     */
    public initialiseConfigPage$ = new Subject<void>();

    private customHandlers: ICustomConfigurationHandler[] = [];
    private hasEntityChanges = false;
    private breezeChangesValid = true;
    private destroyed$ = new Subject<void>();

    public constructor(
        private commonDataService: CommonDataService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        // We could just use commonDataService.hasChanges() in hasChanges(), however
        // there's quite a performance penalty as it is constantly doing other logic
        // in that check, so just update the value each time an entity changes
        rxjsBreezeService.breezeEntityChanged$.pipe(
            // tap in the next digest cycle as this callback is inline within
            // breeze call stack not yet updating entities used by hasChanges()
            delay(10),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.hasEntityChanges = commonDataService.hasChanges();
            this.breezeChangesValid = this.commonDataService.entitiesAreValid();
        });
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public registerCustomHandler(handler: ICustomConfigurationHandler) {
        this.customHandlers.push(handler);
    }

    public removeCustomHandler(handler: ICustomConfigurationHandler) {
        ArrayUtilities.removeElementFromArray(handler, this.customHandlers);
    }

    public shouldShowSaveButtons() {
        return this.customHandlers.some((h) => h.shouldShowSaveButtons());
    }

    public hasChanges() {
        return this.hasEntityChanges
            || this.customHandlers.some((h) => h.hasChanges());
    }

    public changesAreValid() {
        return this.hasChanges()
            && this.breezeChangesValid
            && this.customHandlers.every((h) => h.changesAreValid());
    }

    @Autobind
    public save() {
        return this.commonDataService.save().pipe(
            switchMap(() => this.executeOnAllCustomHandlers((h) => h.onSave())),
        );
    }

    @Autobind
    public cancel() {
        return this.commonDataService.cancel().pipe(
            switchMap(() => this.executeOnAllCustomHandlers((h) => h.onCancel())),
        );
    }

    private executeOnAllCustomHandlers(execute: (h: ICustomConfigurationHandler) => ObservableInput<unknown>) {
        const allExecutions = this.customHandlers.map((h) => {
            return from(execute(h)).pipe(
                // Ensure all executions complete, if one doesn't emit then forkJoin won't emit
                defaultIfEmpty({}),
            );
        });
        return forkJoin(allExecutions);
    }
}
