import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Currency } from "../embed/currency";
import { PricingModel } from "../embed/pricing-model";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Organisation } from "../organisation/organisation";
import { Partner } from "../partner/partner";
import { Person } from "../person/person";
import { AccountExtensions } from "./account-extensions";
import { AccountModule } from "./account-module";
import { Invoice } from "./invoice";

export enum OrganisationType {
    Standard = "Standard",
    Sandbox = "Sandbox",
    Template = "Template",
}

export enum SubscriptionStatus {
    Active = "Active",
    Inactive = "Inactive",
    Trial = "Trial",
    PendingInactive = "PendingInactive",
}

export enum SubscriptionSubStatus {
    None = "None",
    TrialEnded = "TrialEnded",
    TrialExtended = "TrialExtended",
    SubscriptionCancelled = "SubscriptionCancelled",
    SubscriptionResumed = "SubscriptionResumed",
    NonPayment = "NonPayment",
}

export enum AccountChargeState {
    NoCharge = "NoCharge",
    ChargeSuccess = "ChargeSuccess",
    ChargeAttemptFailed1 = "ChargeAttemptFailed1",
    ChargeAttemptFailed2 = "ChargeAttemptFailed2",
    ChargeAttemptFailed3 = "ChargeAttemptFailed3",
    FinalChargeAttemptFailed = "FinalChargeAttemptFailed",
}

export const AccountChargeStateDisplayMap: Record<AccountChargeState, string> = {
    [AccountChargeState.NoCharge]: "No Charge",
    [AccountChargeState.ChargeSuccess]: "Charge Successful",
    [AccountChargeState.ChargeAttemptFailed1]: "1st failed charge attempt",
    [AccountChargeState.ChargeAttemptFailed2]: "2nd failed charge attempt",
    [AccountChargeState.ChargeAttemptFailed3]: "3rd failed charge attempt",
    [AccountChargeState.FinalChargeAttemptFailed]: "Final charge failed",
};

export enum PaymentMethod {
    CreditCard = "CreditCard",
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Invoice = "Invoice",
}

export class Account extends BaseEntity<Account> {
    public accountId!: number;
    public organisationId!: number;
    public organisationType!: OrganisationType;
    public status!: SubscriptionStatus;
    public subStatus!: SubscriptionSubStatus;
    public chargeState!: AccountChargeState;
    public paymentMethod!: PaymentMethod;
    public billingPeriod!: number;
    public paymentProcessorCustomerId?: string;
    public taxExempt!: boolean;
    public contactName?: string;
    public contactAddress?: string;
    public contactEmail?: string;
    public monthlyFeeDollars!: number;
    public remainingCreditDollars!: number;
    public eulaPersonId!: number | null;
    public eulaAcceptedDate!: Date | null;
    public subscriptionCommencedDate!: Date | null;
    public nextSubscriptionInvoiceDate!: Date | null;
    public currencyId!: number;
    public pricingModelId!: number | null;

    public currency!: Currency | null;
    public invoices!: Invoice[];
    public organisation!: Organisation | null;
    public partner!: Partner | null;
    public eulaPerson!: Person | null;
    public pricingModel!: PricingModel | null;
    public accountModules!: AccountModule[];

    @EntityExtensions(AccountExtensions)
    public extensions!: AccountExtensions;
}

export const AccountBreezeModel = new BreezeModelBuilder("Account", Account)
    .hasSource()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("accountModules")
    .build();
