import { Component, Input, OnInit } from "@angular/core";
import { ContactType } from "@common/ADAPT.Common.Model/embed/contact-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact } from "@common/ADAPT.Common.Model/person/person-contact";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { EMPTY } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-configure-personal-login-email",
    templateUrl: "./configure-personal-login-email.component.html",
})
export class ConfigurePersonalLoginEmailComponent extends BaseComponent implements OnInit {
    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    @Input({ required: true }) public person!: Person;

    public editingEmail = false;
    private editingEmailUpdater = this.createThrottledUpdater<boolean>((editingEmail) => this.editingEmail = editingEmail);

    public errorMessage?: string;
    public emailChanged = false;

    public primaryContact?: PersonContact;
    public loading = true;

    public constructor(
        private directorySharedService: DirectorySharedService,
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public async ngOnInit() {
        await this.initialiseData();
    }

    private async initialiseData() {
        await this.directorySharedService.promiseToGetContactDetailsByPersonId(this.person.personId);
        this.primaryContact = this.person.getLoginEmail();
        this.loading = false;
    }

    @Autobind
    public saveEmail() {
        if (!this.primaryContact || !this.person) {
            return EMPTY;
        }

        this.errorMessage = undefined;
        this.emailChanged = false;

        const newPrimaryContactValue = this.primaryContact.value;
        const entities = [this.primaryContact];

        // make an existing contact the preferred one if exists
        const duplicateContact = this.getDuplicateContact();
        if (duplicateContact) {
            this.primaryContact.entityAspect.rejectChanges();
            this.primaryContact.isPreferred = false;
            duplicateContact.isPreferred = true;
            entities.push(duplicateContact);
        }

        return this.commonDataService.saveEntities(entities).pipe(
            switchMap(() => this.initialiseData()),
            tap(() => {
                // using updater so that [adaptBlockingClick] does not error
                this.editingEmailUpdater.next(false);
                this.emailChanged = true;
            }),
            catchError((err) => {
                // undo any changes we made due to dupe contacts
                this.primaryContact?.entityAspect.rejectChanges();
                duplicateContact?.entityAspect.rejectChanges();

                // then set the contact value again
                this.primaryContact!.value = newPrimaryContactValue;

                let errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                if (this.primaryContact && errorMessage.includes("already in use")) {
                    errorMessage = `Login email '${this.primaryContact.value}' is already in use.`;
                }

                this.errorMessage = errorMessage;
                return EMPTY;
            }),
        );
    }

    public cancelEditingEmail() {
        this.primaryContact?.entityAspect.rejectChanges();
        this.editingEmailUpdater.next(false);
    }

    private getDuplicateContact() {
        if (!this.person || !this.primaryContact) {
            return undefined;
        }

        return this.person.personContacts.find((otherContact) => otherContact.contactType === ContactType.Email
            && otherContact !== this.primaryContact
            && otherContact.value === this.primaryContact?.value,
        );
    }
}
