<p>Update the email used to log in to your {{projectLabel}} account.</p>

@if (errorMessage) {
    <div class="alert alert-danger"
         role="alert">{{errorMessage}}</div>
}

@if (emailChanged) {
    <bs-alert type="success"
              [dismissible]="true"
              (onClosed)="emailChanged = false">You have successfully changed the email used to log in to your {{projectLabel}} account.</bs-alert>
}

<ng-container *adaptLoading="loading">
    @if (primaryContact) {
        <div class="d-md-flex gap-3 align-items-center">
            <label for="email">Email</label>

            <div class="d-md-flex align-items-center">
                @if (!editingEmail) {
                    <strong [style.width.px]="300">{{primaryContact.value}}</strong>
                    <button adaptButton="edit"
                            class="ms-2"
                            (click)="editingEmail = true"></button>
                } @else {
                    <dx-text-box #emailTextBox
                                 id="email"
                                 class="me-2"
                                 placeholder="Enter your email"
                                 mode="email"
                                 [width]="300"
                                 [(value)]="primaryContact.value">
                        <dx-validator>
                            <dxi-validation-rule type="email"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                    <button adaptButton="save"
                            [disabled]="primaryContact.entityAspect.entityState.isUnchanged() || !primaryContact.entityAspect.validateEntity() || !emailTextBox.isValid"
                            [adaptBlockingClick]="saveEmail"></button>
                    <button adaptButton="cancel"
                            (click)="cancelEditingEmail()"></button>
                }
            </div>
        </div>
    } @else {
        <div class="alert alert-danger"
             role="alert">Failed to load your {{projectLabel}} account email.</div>
    }
</ng-container>
