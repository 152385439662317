import { Injectable, InjectionToken, Injector, Provider } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { AbstractGuard } from "@common/route/abstract.guard";
import { GuardFailureType } from "@common/route/route-events.service";
import { Observable, of, switchMap } from "rxjs";
import { OrganisationService } from "../organisation/organisation.service";

export type PostOrganisationGuardValidator = (injector: Injector, canActivate: boolean, targetRouteId?: string) => Observable<any>;
export const POST_ORGANISATION_GUARD_VALIDATOR = new InjectionToken<PostOrganisationGuardValidator>("POST_ORGANISATION_GUARD_VALIDATOR");
export function providePostOrganisationGuardValidator(task: PostOrganisationGuardValidator): Provider {
    return {
        provide: POST_ORGANISATION_GUARD_VALIDATOR,
        useValue: task,
        multi: false,
    };
}

@Injectable({
    providedIn: "root",
})
export class OrganisationGuard extends AbstractGuard {
    public static readonly Id = "OrganisationGuard";

    constructor(
        private orgService: OrganisationService,
        private injector: Injector,
    ) {
        super(injector);
    }

    public canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
        return (super.canActivate(route, routerState) as Observable<boolean>).pipe(
            switchMap((canActivate) => {
                // use default value of null - undefined means no default value and will throw exception if no provider defined
                // - cannot have the check implementation here as guard is loaded before routes built - will cause compile error. Use provider works.
                const validatePostOrganisationGuard = this.injector.get(POST_ORGANISATION_GUARD_VALIDATOR, null);
                if (validatePostOrganisationGuard) {
                    return validatePostOrganisationGuard(this.injector, canActivate, route.data.id);
                } else {
                    return of(canActivate);
                }
            }),
        );
    }

    public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypassEvent: boolean) {
        return this.wrapActivationResult(
            PromiseUtilities.promiseToValidatePromiseResolution(this.orgService.validateOrganisation()),
            GuardFailureType.OrganisationGuardFailed,
            route,
            state,
            bypassEvent,
        );
    }
}
