<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>Update payment method</h3>

    <ng-container adapt-dialog-content>
        <div class="row">
            <adapt-update-credit-card-details [updateCreditCardInput]="updateCreditCardInput"
                                              (cardSet)="stripeCardComplete = $event"></adapt-update-credit-card-details>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!stripeCardComplete"
                                   saveText="Save payment method"
                                   [saveBlockingClick]="saveCreditCard"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
