import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { IdentityUxService } from "@common/identity/ux/identity-ux.service";
import { resetPasswordPageRoute } from "@common/identity/ux/reset-password-page/reset-password-page.route";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChangePasswordDialogComponent } from "@org-common/lib/configuration/personal/change-password-dialog/change-password-dialog.component";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-configure-personal-change-password",
    templateUrl: "./configure-personal-change-password.component.html",
})
export class ConfigurePersonalChangePasswordComponent extends BaseComponent {
    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    @Input({ required: true }) public person!: Person;
    @Input() public userHasPassword = true;

    public isError = false;
    public submitMessage?: string;

    public constructor(
        private identityUxService: IdentityUxService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();
    }

    public changePassword(person: Person) {
        this.dialogService.open(ChangePasswordDialogComponent, person).subscribe();
    }

    @Autobind
    public async resetPassword() {
        const email = this.person.getLoginEmail()?.value;
        if (!email) {
            return;
        }

        const route = await lastValueFrom(resetPasswordPageRoute.getRoute());
        const redirectUrl = window.location.origin + route;

        try {
            await this.identityUxService.promiseToSendForgotPassword({ email, redirectUrl });
            this.submitMessage = `A password reset email has been sent to <b>"${email}"</b>.`
                + ` Please check your email and follow the instructions in the email.`;
            this.isError = false;
        } catch (e) {
            this.submitMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
            this.isError = true;
        }
    }
}
