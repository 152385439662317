import { Injector } from "@angular/core";
import { Meeting, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAttendee } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { buttonPreset } from "@common/ux/button/button-preset";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { MeetingsUiService } from "@org-common/lib/meetings/meetings-ui.service";
import { IWorkflowStepFooterTemplate } from "@org-common/lib/workflow/workflow-component-registry";
import { BehaviorSubject, EMPTY, filter, merge, of, startWith, Subject, switchMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { WorkflowStepComponentAdapter } from "../../workflow/workflow-step-component-adapter";
import { IScheduleMeetingWorkflowRunData } from "./schedule-meeting-workflow";

export class ScheduleMeetingWorkflowStepBaseComponent extends WorkflowStepComponentAdapter {
    public runData!: IScheduleMeetingWorkflowRunData;

    protected isValid = new BehaviorSubject<boolean>(false);
    public workflowStepCompleted = this.isValid.asObservable();
    public workflowStepFooterTemplates = new Subject<IWorkflowStepFooterTemplate[]>();

    public nonStartableMeetingInfo?: string;
    public submitting = false;

    protected meetingsService: MeetingsService;
    protected meetingsUiService: MeetingsUiService;
    protected dialogService: AdaptCommonDialogService;
    protected rxjsBreezeService: RxjsBreezeService;

    private updateNonStartableMeetingInfoSubject = new Subject<Meeting>();

    public constructor(injector: Injector) {
        super();

        this.meetingsService = injector.get(MeetingsService);
        this.meetingsUiService = injector.get(MeetingsUiService);
        this.dialogService = injector.get(AdaptCommonDialogService);
        this.rxjsBreezeService = injector.get(RxjsBreezeService);
    }

    public workflowStepOnInit() {
        const runData: IScheduleMeetingWorkflowRunData = this.workflowStep?.workflow.runData;
        if (!runData || !runData.meeting) {
            throw new Error("Meeting needs to be provided to the workflow");
        }

        if (!runData.entitiesToConfirm) {
            runData.entitiesToConfirm = [];
        }

        this.runData = runData;

        // update nonStartableMeetingInfo when meeting entities change
        merge(
            this.rxjsBreezeService.entityTypeChanged(Meeting),
            this.rxjsBreezeService.entityTypeChanged(MeetingAttendee),
            this.rxjsBreezeService.entityTypeChanged(MeetingAgendaItem),
            this.updateNonStartableMeetingInfoSubject.asObservable(),
        ).pipe(
            startWith(this.runData.meeting),
            // allow through other meeting changes so we can detect the active meeting for a user changing
            filter((ent) => ent instanceof Meeting || ent.meetingId === this.runData.meeting.meetingId),
            switchMap(() => this.meetingsService.getNonStartableMeetingInfo(this.runData.meeting, this.allowStartingMeetingWithoutAgenda)),
            this.takeUntilDestroyed(),
        ).subscribe((nonStartableMeetingInfo) => this.nonStartableMeetingInfo = nonStartableMeetingInfo);

        this.validateEntities();
    }

    public get allowStartingMeetingWithoutAgenda() {
        return true;
    }

    public get showRunMeetingNowButton() {
        if (!this.runData) {
            return true;
        }

        return !this.meetingIsRescheduling;
    }

    public get runMeetingNowButtonText() {
        return this.runData.rescheduling
            ? "Restart meeting"
            : buttonPreset.runMeetingNow.buttonText;
    }

    public get meetingIsRescheduling() {
        return this.runData.meeting.extensions.isNotStarted
            && this.runData.meeting.entityAspect.originalValues?.status === MeetingStatus.InProgress;
    }

    public emitMeetingEntitiesChanged() {
        this.onEntitiesChanged(this.runData.meeting.extensions.getAllMeetingEntities());
    }

    public onEntitiesChanged(entities: IBreezeEntity[]) {
        // filter out any null entities. this can happen when removing an entity (like removing an attendee)
        // filter out detached entities too - this can happen when cancelling added agenda items
        this.runData.entitiesToConfirm = ArrayUtilities.addElementIfNotAlreadyExists(this.runData.entitiesToConfirm, ...entities)
            .filter((e) => !!e && !e.entityAspect?.entityState?.isDetached());
        this.runData.entitiesToConfirm.forEach((ent) => this.emitEntityChange(ent));
        this.validateEntities();
    }

    public validateEntities() {
        this.isValid.next(this.stepIsValid);
        this.updateNonStartableMeetingInfoSubject.next(this.runData.meeting);
    }

    public get allEntitiesAreValid() {
        return this.runData.entitiesToConfirm.every((e) => e && e.entityAspect.validateEntity());
    }

    public get stepIsValid() {
        return this.allEntitiesAreValid;
    }

    @Autobind
    public runMeetingNow(updateDuration = false) {
        if (!this.runData.meeting) {
            return of(undefined);
        }

        // disable any changes while saving
        this.submitting = true;
        this.isValid.next(false);

        if (updateDuration) {
            this.meetingsService.updateMeetingDurationFromAgendaItems(this.runData.meeting);
        }

        return this.meetingsService.saveEntities(this.runData.entitiesToConfirm).pipe(
            switchMap(() => this.meetingsService.getNonStartableMeetingInfo(this.runData.meeting)),
            switchMap((meetingInfo) => {
                if (meetingInfo) {
                    return this.dialogService.showErrorDialog("Cannot start meeting", meetingInfo);
                }

                return this.meetingsUiService.startMeeting(this.runData.meeting);
            }),
            catchError((err) => {
                const message = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(message);

                this.submitting = false;
                this.validateEntities();
                return EMPTY;
            }),
        );
    }
}
