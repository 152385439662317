<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.UnderstandingTheEconomicEngine"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-inactive [entity]="valueStream"
                        type="value stream"></adapt-toolbar-inactive>

<h1 adaptToolbarContent
    alignment="left">
    {{valueStream?.name}}
</h1>

<div adaptToolbarContent
     adaptBlockingButtonGroup>
    <button *adaptIfAuthorised="editValueStreamContents"
            adaptButtonIcon="edit"
            [adaptBlockingClick]="editValueStream"
            data-test="edit-value-stream-button">
        Edit value stream details
    </button>
</div>

<adapt-layout-manager innerClass="h-100 m-0"
                      [allowEditing]="false"
                      [fullPage]="true"
                      *adaptLoading="!valueStream">

    <adapt-layout-column class="col-md-7 col-lg-9"
                         [ngClass]="{'bg-white': true}">
        <adapt-layout-component id="value-stream-purpose"
                                class="d-flex col-md-12">
            <div class="card card-borderless w-100"
                 data-test="value-stream-purpose">
                <div class="card-header p-0">
                    <h2 class="card-title h2">
                        <span adaptTooltip="The economic purpose of the value stream.">
                            Purpose</span>
                        <button *adaptIfAuthorised="editValueStreamContents"
                                class="btn btn-link"
                                adaptButtonIcon="edit"
                                [adaptBlockingClick]="editValueStream"></button>
                    </h2>
                </div>
                <div class="card-body p-0">
                    <ng-container *adaptLoadingUntilFirstEmit="let purpose of primePurpose$">
                        <p *ngIf="valueStream?.purpose?.content as purposeContent else noPurposeTemplate"
                           [froalaView]="purposeContent"></p>
                        <ng-template #noPurposeTemplate>
                            <adapt-call-to-action-inline>
                                {{CallToActionText.valueStream.purpose}}

                                <button *adaptIfAuthorised="editValueStreamContents"
                                        adaptCallToActionButton
                                        adaptButtonIcon="edit"
                                        [adaptBlockingClick]="editValueStream">
                                    Define your purpose
                                </button>
                            </adapt-call-to-action-inline>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </adapt-layout-component>
        <adapt-layout-component id="value-stream-market"
                                class="col-md-12">
            <div class="card card-borderless">
                <div class="card-header p-0">
                    <h2 class="card-title h2">Market</h2>
                </div>
                <div class="row card-body p-0">
                    <div class="col-md-6 market-description">
                        <!-- Market Description -->
                        <adapt-display-market-description [valueStream]="valueStream"></adapt-display-market-description>
                    </div>
                    <div class="col-md-6">
                        <!-- Stakeholder Map -->
                        <adapt-display-customer-stories [valueStream]="valueStream"></adapt-display-customer-stories>
                    </div>
                </div>
            </div>
        </adapt-layout-component>

        <adapt-layout-component id="value-stream-offering">
            <div class="card card-borderless">
                <div class="card-header p-0">
                    <h2 class="card-title h2">Offering</h2>
                </div>
                <div class="card-body p-0">
                    <div class="row">
                        <!-- Product/Services -->
                        <div [ngClass]="offeringPartColumnClasses">
                            <adapt-display-products-services [valueStream]="valueStream"></adapt-display-products-services>
                        </div>

                        <!-- Point Of Difference-->
                        <div [ngClass]="offeringPartColumnClasses">
                            <adapt-display-point-of-difference [valueStream]="valueStream"></adapt-display-point-of-difference>
                        </div>

                        <!-- Commercial Model Synopsis -->
                        <div [ngClass]="offeringPartColumnClasses">
                            <div class="value-stream-dashboard-element-title">
                                <span
                                      adaptTooltip="The method by which the customer is charged. For instance is it people/time rate, a throughput rate, an annuity or some other approach.">
                                    <i class="fal fa-fw fa-dollar-sign"></i>
                                    Commercial model</span>
                            </div>
                            <div *adaptLoadingUntilFirstEmit="let businessModel of businessModel$">
                                <ng-container *ngIf="businessModel?.synopsis; else noSynopsisTemplate">
                                    <p [froalaView]="businessModel!.synopsis"></p>
                                    <a [routerLink]="commercialModelUrl">Explore commercial model</a>
                                </ng-container>
                                <ng-template #noSynopsisTemplate>
                                    <adapt-call-to-action-inline [featureUrl]="commercialModelUrl">
                                        {{CallToActionText.valueStream.commercialModel}}
                                    </adapt-call-to-action-inline>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </adapt-layout-component>
    </adapt-layout-column>

    <adapt-layout-column #rightCol
                         class="col-md-5 col-lg-3">
        <adapt-layout-component id="value-stream-leader"
                                class="d-flex col-md-12">
            <div class="card card-borderless w-100">
                <div class="card-header p-0">
                    <h2 class="card-title">Leader
                        <button *adaptIfAuthorised="editValueStreamContents"
                                class="btn btn-link"
                                adaptButtonIcon="edit"
                                [adaptBlockingClick]="editValueStream"></button>
                    </h2>
                </div>
                <div class="card-body p-0">
                    <adapt-display-role-allocation [role]="leaderRole$ | async"
                                                   [showLabel]="false"></adapt-display-role-allocation>
                </div>
            </div>
        </adapt-layout-component>

        <adapt-layout-component id="key-functions"
                                class="col-md-12">
            <div class="card card-borderless">
                <div class="card-header p-0">
                    <h2 class="card-title">Key functions</h2>
                </div>
                <div class="card-body p-0">
                    <div *adaptLoadingUntilFirstEmit="let keyFunctions of keyFunctions$">
                        <adapt-key-function-card *ngFor="let keyFunction of keyFunctions"
                                                 [keyFunction]="keyFunction"
                                                 class="mb-1"></adapt-key-function-card>
                        <adapt-call-to-action-inline *ngIf="keyFunctions.length === 0"
                                                     [featureUrl]="keyFunctionRouteUrl | async">
                            {{CallToActionText.keyFunctions}}
                        </adapt-call-to-action-inline>
                    </div>
                </div>
            </div>
        </adapt-layout-component>
    </adapt-layout-column>
</adapt-layout-manager>
