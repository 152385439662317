<adapt-feature-guidance [configItem]="configItem">
    <p>Configure security options relating to your {{projectLabel}} account.</p>
</adapt-feature-guidance>

<div *adaptLoading="!person"
     class="d-flex flex-column gap-2">
    <div>
        <h4>Login email address</h4>
        <adapt-configure-personal-login-email [person]="person"></adapt-configure-personal-login-email>
    </div>
    <hr>

    <ng-container *adaptIfAuthorised="ChangePasswordForPerson;Entity:person">
        <div>
            <h4>Change password</h4>
            <adapt-configure-personal-change-password [person]="person"
                                                      [userHasPassword]="userHasPassword"></adapt-configure-personal-change-password>
        </div>
        <hr>
    </ng-container>

    <ng-container *adaptIfAlto="false">
        <div>
            <h4>Single sign-on (SSO)</h4>
            <adapt-configure-personal-single-sign-on [person]="person"
                                                     (userHasPassword)="userHasPassword = $event"></adapt-configure-personal-single-sign-on>
        </div>
        <hr>
    </ng-container>

    <div>
        <h4>Two-factor authentication</h4>
        <adapt-configure-personal-mfa></adapt-configure-personal-mfa>
    </div>
</div>

