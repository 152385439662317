<div *adaptLoadingUntilFirstEmit="let agendaItems of agendaItems$">
    <div [ngClass]="{'agenda-item-list-container': !saveOnChange}"
         *adaptLoading="savingImport">
        <div *ngIf="agendaItems.agendaItems.length > 0 || agendaItems.preWork; else noItem">
            <div *ngIf="agendaItems.preWork"
                 class="agenda-items dx-widget mb-1">
                <div class="dx-item dx-list-item pre-work">
                    <ng-container [ngTemplateOutlet]="agendaItem"
                                  [ngTemplateOutletContext]="{item: agendaItems.preWork}"></ng-container>
                </div>
            </div>

            <dx-list *ngIf="agendaItems.agendaItems.length > 0"
                     class="agenda-items"
                     [dataSource]="agendaItems.agendaItems"
                     (onItemReordered)="updateOrdinals($event)"
                     [hoverStateEnabled]="false"
                     [focusStateEnabled]="false"
                     [activeStateEnabled]="false"
                     [allowItemDeleting]="false">
                <dxo-item-dragging [allowReordering]="!isReadonly">
                </dxo-item-dragging>
                <ng-container *dxTemplate="let item of 'item'"
                              [ngTemplateOutlet]="agendaItem"
                              [ngTemplateOutletContext]="{item: item}"></ng-container>
            </dx-list>

            <ng-template #agendaItem
                         let-item="item">
                <adapt-edit-agenda-item class="dx-list-item-content"
                                        [item]="item"
                                        [readonly]="isReadonly"
                                        [hideProgressIcon]="isReadonly"
                                        [saveOnChange]="saveOnChange"
                                        [hideNotesAndItems]="hideNotesAndItems"
                                        [expandOnInit]="expandItemsOnInit"
                                        (expandDetailsChange)="expandChanged($event)"
                                        (canExpandChange)="canExpandChanged($event)"
                                        (itemChange)="emitAgendaItemsChange()"
                                        (itemDelete)="onItemDeleted($event)"></adapt-edit-agenda-item>
            </ng-template>

            <div class="total-duration-container">
                <i class="fal fa-clock pe-1"></i> <strong>Total Time:</strong> {{totalPlannedDurationInMinutes | adaptMinutesToDuration}}
            </div>
        </div>
        <ng-template #noItem>
            <div *ngIf="!newAgendaItem">
                Currently, there is no meeting agenda.
                <ng-container *ngIf="meeting; else templateNoItems">You need to add an agenda item before you can schedule this
                    meeting.</ng-container>
                <ng-template #templateNoItems>You need to add an agenda item before you can save this agenda.</ng-template>
            </div>
        </ng-template>
    </div>
    <ng-container *ngIf="!isReadonly">
        <ng-container *ngIf="!newAgendaItem; else addAgendaItem">
            <div class="mt-3 d-flex flex-row gap-2">
                <!-- div because tooltip on disabled button does not work -->
                <div [adaptTooltip]="hasPreWork ? 'This agenda already has pre-work.' : undefined">
                    <button adaptButtonIcon="far fa-plus-square"
                            class="btn btn-default btn-add-pre-work"
                            [disabled]="hasPreWork"
                            [adaptBlockingClick]="createNewAgendaItem"
                            [adaptBlockingClickParam]="true"
                            data-test="add-pre-work-agenda-item-button">Add pre-work
                    </button>
                </div>
                <button adaptButtonIcon="far fa-plus-square"
                        class="btn btn-default btn-add-agenda-item"
                        [adaptBlockingClick]="createNewAgendaItem"
                        data-test="add-agenda-item-button">Add agenda item
                </button>

                <button adaptButton="downloadLink"
                        class="ms-auto"
                        (click)="onImportClicked()"
                        data-test="import-agenda-template">Import meeting agenda
                </button>
            </div>
        </ng-container>
        <ng-template #addAgendaItem>
            <div class="mt-1 mb-5 d-flex">
                <dx-text-box class="flex-grow-1 me-2"
                             placeholder="Type the new meeting agenda item name"
                             (onInitialized)="onInitialized($event)"
                             [(value)]="newAgendaItem!.name"
                             [adaptValidateEntity]="newAgendaItem"
                             adaptValidateEntityProperty="name"></dx-text-box>
                <button [disabled]="newAgendaItem!.entityAspect.hasValidationErrors"
                        [adaptBlockingClick]="addNewAgendaItem"
                        adaptButton="add"
                        data-test="save-agenda-item-button"></button>
                <button adaptButton="cancel"
                        [adaptBlockingClick]="cancelNewAgendaItem"></button>
            </div>
        </ng-template>
    </ng-container>
</div>
