import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSelectCountryModule } from "@common/ux/select-country/select-country.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxCheckBoxModule, DxDateBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { DxiValidationRuleModule } from "devextreme-angular/ui/nested";
import { EditPersonContactsDialogComponent } from "./edit-person-contacts-dialog/edit-person-contacts-dialog.component";
import { EditStartDateDialogComponent } from "./edit-start-date-dialog/edit-start-date-dialog.component";
import { PersonProfileContactsComponent } from "./person-profile-contacts/person-profile-contacts.component";
import { PersonProfileItemsComponent } from "./person-profile-items/person-profile-items.component";

@NgModule({
    exports: [
        PersonProfileContactsComponent,
        PersonProfileItemsComponent,
        EditStartDateDialogComponent,
    ],
    declarations: [
        EditPersonContactsDialogComponent,
        PersonProfileContactsComponent,
        PersonProfileItemsComponent,
        EditStartDateDialogComponent,
    ],
    imports: [
        CommonModule,

        DxValidationGroupModule,
        DxValidatorModule,
        DxiValidationRuleModule,
        DxTextBoxModule,
        DxCheckBoxModule,

        AdaptHtmlEditorModule,
        AdaptCommonDialogModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        AdaptDashboardElementModule,
        AdaptLoadingSpinnerModule,
        AdaptCommonUserModule,
        AdaptDateModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptEntityValidatorModule,
        AdaptSelectCountryModule,
        DxDateBoxModule,
        DxTextAreaModule,
    ],
})
export class AdaptPersonProfileModule { }
