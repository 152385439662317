<ng-container *adaptIfAlto="false">
    <adapt-page-with-splash-image leftColumnWidthClasses="col-12 col-md-8 col-xl-6 overflow-hidden h-100"
                                  rightColumnWidthClasses="col d-sm-none d-md-block"
                                  [fitHeight]="true">
        <ng-container *ngTemplateOutlet="pageContent"></ng-container>
    </adapt-page-with-splash-image>
</ng-container>
<div *adaptIfAlto="true"
     class="d-flex w-100 h-100 justify-content-center">
    <div class="alto-survey-container col-xl-8 col-lg-10">
        <ng-container *ngTemplateOutlet="pageContent"></ng-container>
    </div>
</div>
<ng-template #pageContent>
    <div *ngIf="!errorMessage && surveyDetails; else showErrorBlockTemplate"
         class="d-flex flex-column h-100">

        <a *ngIf="!isLoggedIn && !surveyDetails!.organisationImageIdentifier"
           href="https://theadaptway.com"
           aria-label="Go to adapt website"
           target="_blank">
            <img width="140"
                 src="/content/shell/images/adaptplatformlogo.svg"
                 alt="adapt logo" />
        </a>

        <div class="d-flex survey-header">
            <!-- TODO: reinstate when CM-6236 is done
                <adapt-storage-image *ngIf="!isLoggedIn && surveyDetails!.organisationImageIdentifier"
                                 class="me-3 mb-3"
                                 [imageIdentifier]="surveyDetails!.organisationImageIdentifier"></adapt-storage-image> -->

            <h1>{{title}}</h1>
        </div>

        <ng-container *ngIf="!errorMessage && surveyDetails; else showErrorBlockTemplate">
            <dx-scroll-view showScrollbar="always"
                            (onScroll)="onScrolled($event)"
                            [scrollByContent]="true"
                            [scrollByThumb]="true"
                            [useNative]="true"
                            data-test="survey-questions-scroll">
                <div *ngIf="submitted; else showQuestions">
                    <h3 class="mt-3">Thank you for completing the survey!</h3>
                    <p>Your responses have been submitted successfully.
                        When the survey has ended you will be able to see the anonymised results and analysis.</p>
                    <p *ngIf="isLoggedIn">
                        <a routerLink="/"><i class="fal fa-chevron-left"></i> Back to dashboard</a>
                    </p>
                </div>
                <ng-template #showQuestions>
                    <div class="survey-details">
                        <ng-container *ngIf="surveyDetails!.surveyEndTime">
                            <h3>Open until {{surveyDetails!.surveyEndTime | adaptDateTime}}</h3>
                            <br>
                        </ng-container>
                        <div [innerHTML]="surveyDescription"
                             *ngIf="!introArticle; else showIntroArticle"></div>
                        <ng-template #showIntroArticle>
                            <adapt-implementation-kit-content [articleId]="introArticle"
                                                              (contentLoaded)="scrollViewInstance?.update()">
                            </adapt-implementation-kit-content>
                        </ng-template>
                    </div>

                    <ng-container *ngIf="surveyQuestionsGroupedByCategory.length < 1; else showQuestionsGroupedByCategory">
                        <div class="survey-question"
                             *ngFor="let question of surveyQuestions"
                             [ngClass]="getAdditionalQuestionStyle(question.ordinal)"
                             [attr.id]="'question_' + question.ordinal"
                             (click)="onItemClick($event)"
                             data-test="survey-question">
                            <div class="question-text mb-2">
                                <span>{{question.questionText}}</span>
                            </div>
                            <dx-radio-group [items]="questionResponses"
                                            [(value)]="question.response"
                                            (onValueChanged)="onAnswered(question, $event)"
                                            valueExpr="value"
                                            displayExpr="text"
                                            class="ms-3">
                            </dx-radio-group>
                        </div>
                    </ng-container>
                    <ng-template #showQuestionsGroupedByCategory>
                        <ng-container *ngFor="let category of surveyQuestionsGroupedByCategory">
                            <hr />
                            <h2 class="category-header">{{category.categoryName}}</h2>
                            <div class="survey-question"
                                 *ngFor="let question of category.questions"
                                 [ngClass]="getAdditionalQuestionStyle(question.ordinal)"
                                 [attr.id]="'question_' + question.ordinal"
                                 (click)="onItemClick($event)">
                                <div class="question-text mb-2">
                                    <span>{{question.questionText}}</span>
                                </div>
                                <dx-radio-group [items]="questionResponses"
                                                [(value)]="question.response"
                                                (onValueChanged)="onAnswered(question, $event)"
                                                valueExpr="value"
                                                displayExpr="text"
                                                class="ms-3">
                                </dx-radio-group>
                            </div>
                        </ng-container>
                    </ng-template>
                    <div class="d-flex justify-content-center me-3">
                        <button adaptButtonIcon="save"
                                *ngIf="!isTokenlessSurvey"
                                [disabled]="incomplete || isSubmitting"
                                class="btn btn-primary"
                                (click)="submitResponse()"
                                data-test="submit-survey-button">Submit responses</button>
                        <button adaptButtonIcon="fal fa-fw fa-sign-in"
                                *ngIf="isTokenlessSurvey"
                                [disabled]="incomplete || isSubmitting"
                                class="btn btn-primary"
                                (click)="analyseResults()"
                                data-test="submit-survey-button">Sign up to analyse my results</button>
                    </div>
                    <div class="mt-3 alert alert-danger"
                         *ngIf="submitErrorMessage">
                        <span>{{submitErrorMessage}}</span>
                    </div>
                </ng-template>
            </dx-scroll-view>
            <div class="mt-3 me-3 question-progress"
                 *ngIf="!submitted">
                <div class="text-center">{{answeredQuestionCount}} / {{totalQuestionCount}}</div>
                <dx-progress-bar width="100%"
                                 [min]="0"
                                 [max]="totalQuestionCount"
                                 [value]="answeredQuestionCount"
                                 [showStatus]="false"></dx-progress-bar>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #showErrorBlockTemplate>
    <adapt-dashboard-element>
        <div adapt-dashboard-title
             *ngIf="!isLoggedIn">
            <h2>Survey: {{surveyNameFromParam}}</h2>
        </div>

        <div class="alert alert-danger"
             role="alert">{{errorMessage}}</div>
        <div *ngIf="isLoggedIn">
            <a routerLink="/"><i class="fal fa-chevron-left"></i> Back to dashboard</a>
        </div>
    </adapt-dashboard-element>
</ng-template>
