import { KeyValue } from "@angular/common";
import { Component, Input, OnChanges } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { Module } from "@common/ADAPT.Common.Model/embed/module";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { AdaptClientConfiguration, AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaymentProcessingService } from "../payment-processing.service";

@Component({
    selector: "adapt-display-pricing-breakdown",
    templateUrl: "./display-pricing-breakdown.component.html",
    styleUrls: ["./display-pricing-breakdown.component.scss"],
})
export class DisplayPricingBreakdownComponent extends BaseComponent implements OnChanges {
    @Input() public account?: Account;
    // If billing cycle is reset, it will show the credit from previous payment
    @Input() public resetBillingCycle = false;

    public readonly UserTypeExtensions = UserTypeExtensions;

    public pricingModelUserCounts = new Map<UserType, number>();
    public addonModules: Module[] = [];
    public platformModules: Module[] = [];
    public accountCredits = 0;

    public projectLabel: string;

    constructor(
        private commonDataService: CommonDataService,
        private paymentProcessingService: PaymentProcessingService,
    ) {
        super();

        this.projectLabel = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Nimbus
            // this is on nimbus - so figure out if that's embed or HQ
            ? AdaptProjectLabel[AdaptClientConfiguration.IsCurrentEmbedApiBaseUri ? AdaptProject.Cumulus : AdaptProject.Alto]
            // simply use AdaptProjectLabel for embed or HQ
            : AdaptClientConfiguration.AdaptProjectLabel;
    }

    public ngOnChanges() {
        let connections$: Observable<Connection[]>;
        if (this.account) {
            const predicate = new MethodologyPredicate<Connection>("organisationId", "==", this.account.organisationId);
            connections$ = this.commonDataService.getByPredicate(ConnectionBreezeModel, predicate);
        } else {
            connections$ = this.commonDataService.getAll(ConnectionBreezeModel);
        }

        const pricingModelUserTypes = this.account?.pricingModel
            ? this.account?.pricingModel.pricingModelUsers.map((i) => i.userType)
            : [];
        const excludedUserTypes = [UserType.None, UserType.Coach];
        const excludedConnectionTypes = [ConnectionType.Coach];
        this.pricingModelUserCounts.clear();
        connections$.pipe(
            map((connections) => connections.filter((conn) => conn.isActive() &&
                pricingModelUserTypes.includes(conn.userType) &&
                !excludedUserTypes.includes(conn.userType) &&
                !excludedConnectionTypes.includes(conn.connectionType))),
        ).subscribe((connections) => {
            for (const connection of connections) {
                const count = this.pricingModelUserCounts.get(connection.userType) ?? 0;
                this.pricingModelUserCounts.set(connection.userType, count + 1);
            }
        });

        if (this.account) {
            this.platformModules = this.account.extensions.platformModules;
            this.addonModules = this.account.extensions.addOnModules;

            // need to prime invoices first or the totalCredit may be incorrect depending on whether the last invoice was primed
            this.paymentProcessingService.getInvoices(this.account.organisationId)
                .then(() => this.accountCredits = this.account?.extensions.totalCredit ?? 0);
        }
    }

    public orderByUserOrdinal(a: KeyValue<UserType, number>, b: KeyValue<UserType, number>) {
        return UserTypeExtensions.priceOrdinal(a.key) - UserTypeExtensions.priceOrdinal(b.key);
    }

    public price(userType: UserType) {
        const pricingModel = this.getPricingModelForUserType(userType);
        return pricingModel?.monthlyFeeDollars;
    }

    public subtotal(userType: UserType, value: number) {
        const pricingModel = this.getPricingModelForUserType(userType);
        return pricingModel
            ? pricingModel!.monthlyFeeDollars * value
            : 0;
    }

    public get basePlatformFee() {
        return (this.account?.pricingModel?.monthlyFeeDollars ?? 0) + (this.account?.monthlyFeeDollars ?? 0);
    }

    public get totalExGst() {
        return Math.floor((this.account?.extensions.monthlySubscriptionCost ?? 0) * (this.account?.billingPeriod ?? 1));
    }

    public get total() {
        return this.account!.extensions.getTotalCost(this.totalExGst);
    }

    public get tax() {
        return this.account!.extensions.getTax(this.totalExGst);
    }

    public get creditedTotal() {
        return this.total > this.accountCredits
            ? this.total - this.accountCredits
            : 0;
    }

    public get remainingCredit() {
        return this.total < this.accountCredits
            ? this.accountCredits - this.total
            : 0;
    }

    public get monthlyTotal() {
        return Math.floor(this.account?.extensions.monthlySubscriptionCost ?? 0);
    }

    public get undiscountedMonthlyTotal() {
        const monthlyCost = this.account?.extensions.monthlySubscriptionCost ?? 0;
        return this.account?.extensions.appliedAnnualDiscountPercentage
            ? Math.round(monthlyCost / (100 - this.account?.extensions.appliedAnnualDiscountPercentage) * 100)
            : monthlyCost;
    }

    private getPricingModelForUserType(userType: UserType) {
        const pricingModel = this.account?.pricingModel?.pricingModelUsers.find((pmu) => pmu.userType === userType);
        return pricingModel;
    }
}
