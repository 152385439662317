<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Import meeting agenda</h3>

    <ng-container adapt-dialog-content>
        <div class="mb-3">
            <h3>Import from</h3>
            <dx-radio-group [items]="ImportTypes"
                            [value]="selectedImportType"
                            (valueChange)="onImportTypeChange($event)"
                            valueExpr="type"
                            displayExpr="text"
                            layout="horizontal"></dx-radio-group>
        </div>

        @switch (selectedImportType) {
            @case (ImportType.FromTemplate) {
                <div *adaptLoadingUntilFirstEmit="let dataSource of templatesDataSource$">
                    <dx-select-box [dataSource]="dataSource"
                                   [grouped]="true"
                                   [searchEnabled]="true"
                                   [searchExpr]="$any(['name','team.name'])"
                                   [showClearButton]="false"
                                   (valueChange)="templateSelected($event)"
                                   displayExpr="name"
                                   placeholder="Select a meeting template to import..."
                                   noDataText="No other meeting template found. Create new template from 'Organisation Configuration / 'Meetings' or 'Configure Team' / 'Meetings' "
                                   data-test="select-agenda-template">
                    </dx-select-box>
                </div>
            }
            @case (ImportType.FromMeeting) {
                <div *adaptLoadingUntilFirstEmit="let dataSource of recentMeetingsDataSource$">
                    <dx-select-box [dataSource]="dataSource"
                                   [showClearButton]="false"
                                   [searchEnabled]="true"
                                   searchExpr="name"
                                   (valueChange)="meetingSelected($event)"
                                   displayExpr="name"
                                   itemTemplate="item"
                                   [grouped]="true"
                                   groupTemplate="group"
                                   placeholder="Select a meeting to import..."
                                   noDataText="No other meeting found."
                                   data-test="select-meeting-agenda">
                        <div *dxTemplate="let data of 'group'">
                            <i class="fal fa-users"></i>
                            {{data.key.substring(6)}}
                        </div>
                        <div *dxTemplate="let meeting of 'item'"
                             class="d-flex">
                            <span>{{meeting.name}}</span>
                            <small class="ms-auto text-muted">{{meeting.meetingDateTime | adaptDate}}</small>
                        </div>
                    </dx-select-box>
                </div>
            }
        }

        @if (selectedMeeting || selectedTemplate) {
            <h3 class="mt-4">Agenda items</h3>
            <div *adaptLoading="loadingItems">
                <adapt-display-agenda-items [agendaItems]="agendaItems"
                                            [hideProgressIcon]="true"
                                            [hideNotesAndItems]="true"
                                            class="import-agenda-items"></adapt-display-agenda-items>
            </div>
        }
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   [saveIsDisabled]="(!selectedMeeting && !selectedTemplate) || loadingItems"
                                   saveButtonPreset="add"
                                   [saveText]="dialogData.saveOnClose ? 'Import & save' : 'Import'"
                                   [saveBlockingClick]="importItems"></adapt-standard-dialog-buttons>
</adapt-dialog>
