import { Component, Input } from "@angular/core";
import { ConfigureFeatureBase } from "../../base-components/configure-feature-base";
import { IConfigItem } from "../../configuration.interfaces";
import { ConfigurationService } from "../../configuration.service";

@Component({
  selector: "adapt-configure-generic-feature",
  templateUrl: "./configure-generic-feature.component.html",
})
export class ConfigureGenericFeatureComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        return undefined;
    }
}
