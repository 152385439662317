import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { Account, AccountBreezeModel, SubscriptionStatus, SubscriptionSubStatus } from "@common/ADAPT.Common.Model/account/account";
import { AccountModuleBreezeModel } from "@common/ADAPT.Common.Model/account/account-module";
import { Module, ModuleBreezeModel } from "@common/ADAPT.Common.Model/embed/module";
import { ModuleFeatureBreezeModel } from "@common/ADAPT.Common.Model/embed/module-feature";
import { PricingModelBreezeModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SUBSCRIPTION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { RouteEventsService } from "@common/route/route-events.service";
import { BaseService } from "@common/service/base.service";
import { IBannerSpec } from "@common/shell/shell.interface";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { CancelSubscriptionDialogComponent } from "@org-common/lib/configuration/organisation/cancel-subscription-dialog/cancel-subscription-dialog.component";
import moment from "moment";
import { BehaviorSubject, merge, of, Subject } from "rxjs";
import { catchError, filter, first, map, switchMap, tap } from "rxjs/operators";
import { OrganisationService } from "../organisation.service";
import { SetInitialSubscriptionDialogComponent } from "./set-initial-subscription-dialog/set-initial-subscription-dialog.component";

@Injectable({
    providedIn: "root",
})
export class AccountService extends BaseService {
    private readonly subscriptionRedirectParams = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto
        ? { showSubscriptionDialog: true }
        : undefined;
    private readonly subscriptionBanner: IBannerSpec = {
        class: "account-subscription-banner",
        text: "",
        buttonText: "Start subscription",
        buttonAction: () => this.subscriptionPageRoute.gotoRoute(undefined, this.subscriptionRedirectParams).subscribe(),
        isDismissible: true,
    };

    private accountStatusUpdated$ = new Subject<void>();
    private firstPaymentReceived$ = new BehaviorSubject<boolean>(false);

    constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
        private authorisationNotificationService: AuthorisationNotificationService,
        private httpClient: HttpClient,
        private shellUiService: ShellUiService,
        private commonDialogService: AdaptCommonDialogService,
        private organisationService: OrganisationService,
        private routeService: RouteService,
        private routeEventsService: RouteEventsService,
        @Inject(SUBSCRIPTION_PAGE) private subscriptionPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);
    }

    public get accountStatusChanged$() {
        return this.accountStatusUpdated$.asObservable();
    }

    public get firstSubscriptionPaymentReceived$() {
        return this.firstPaymentReceived$.asObservable();
    }

    public addAccountBanner() {
        // remove the banner when logging out
        this.organisationService.organisationEntityUpdated$.subscribe((org) => {
            if (!org) {
                this.shellUiService.removeBanner(this.subscriptionBanner);
            }
        });

        // need authorisationChanged$ for promiseToGetHasAccess to work as expected
        merge(
            this.authorisationNotificationService.authorisationChanged$,
            this.accountStatusUpdated$,
        ).pipe(
            switchMap(() => this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ConfigureOrganisationBilling)),
            filter((hasAccess) => hasAccess),
            switchMap(() => this.getAccount()),
            tap((account) => this.updateSubscriptionBanner(account)),
        ).subscribe();
    }

    public updateSubscriptionBanner(account?: Account) {
        this.shellUiService.removeBanner(this.subscriptionBanner);

        if (!account
            // account must be one of: trial, inactive, cancelled
            || (!account.extensions.isTrial && !account.extensions.isInactive && !account.extensions.isPendingCancellation)) {
            return;
        }

        const now = moment();
        const nextInvoiceDate = moment(account.nextSubscriptionInvoiceDate);
        const ended = account.extensions.isInactive || now.isAfter(nextInvoiceDate);

        const days = nextInvoiceDate.diff(now, "days", true);
        const roundedDays = Math.round(days);
        const dayText = roundedDays === 1 ? "day" : "days";

        // if they've cancelled, or have ever subscribed before, say "Resume" instead.
        this.subscriptionBanner.buttonText = account.extensions.isPendingCancellation || account.subStatus === SubscriptionSubStatus.SubscriptionCancelled
            ? "Resume subscription"
            : "Start subscription";

        if (ended) {
            if (account.extensions.isZeroDayTrial) {
                this.subscriptionBanner.text = `To grow your business, start your ${AdaptClientConfiguration.AdaptProjectLabel} subscription now!`;
            } else {
                this.subscriptionBanner.text = account.extensions.isTrial || account.extensions.isExpiredTrial
                    ? `The trial period for ${AdaptClientConfiguration.AdaptProjectLabel} has ended. To continue growing your business, start your subscription now!`
                    : `Your subscription for ${AdaptClientConfiguration.AdaptProjectLabel} has ended.`;
            }
        } else if (account.extensions.isPendingCancellation) {
            // no need to show the banner at this point if there is a lot of time remaining on their cancelled sub
            if (days > 31) {
                return;
            }

            const timeSuffix = days < 1
                ? `today`
                : `in ${roundedDays} ${dayText}`;
            this.subscriptionBanner.text = `Your subscription for ${AdaptClientConfiguration.AdaptProjectLabel} ends ${timeSuffix}.`;
        } else {
            const message = `Start your subscription now to continue access to ${AdaptClientConfiguration.AdaptProjectLabel} after the trial ends.`;
            this.subscriptionBanner.text = days < 1
                ? `Your free trial is ending today. ${message}`
                : `You have ${roundedDays} ${dayText} remaining on your free trial. ${message}`;
        }

        if (this.routeService.currentControllerId === this.subscriptionPageRoute.id) {
            // don't show the button if we are already at the subscription page
            this.subscriptionBanner.buttonText = undefined;
        }

        this.shellUiService.addBanner(this.subscriptionBanner);
    }

    public updateSubscriptionBannerOnPageChange(account?: Account) {
        this.routeEventsService.navigationEnd$.pipe(
            first(),
        ).subscribe(() => this.updateSubscriptionBanner(account));
    }

    public createAccountModule(account: Account, module: Module) {
        return this.commonDataService.create(AccountModuleBreezeModel, {
            account,
            module,
            organisationId: account.organisationId,
            quantity: module.defaultQuantity ?? 1,
        });
    }

    public getAccount() {
        const key = "allAccountsPrimed";
        return this.commonDataService.getWithOptions(AccountBreezeModel, key, {
            navProperty: "currency, eulaPerson, pricingModel.pricingModelModules, pricingModel.pricingModelUsers, accountModules.module",
            forceRemote: true,
        }).pipe(
            switchMap((accounts) => this.getModules().pipe(map(() => accounts))), // prime modules
            map((accounts) => ArrayUtilities.getSingleFromArray(accounts)),
        );
    }

    public getPricingModels() {
        return this.commonDataService.getAll(PricingModelBreezeModel);
    }

    public getModules() {
        return this.commonDataService.getAll(ModuleBreezeModel);
    }

    public getEnabledFeaturesForAccount() {
        return this.getAccount().pipe(
            switchMap((account) => account?.pricingModel?.pricingModelModules?.length
                ? this.getModuleFeaturesForModules(account.accountModules.map((accountModule) => accountModule.moduleId))
                : of([])),
        );
    }

    public forceUpdateAccount(account: Account) {
        return this.commonDataService.getById(AccountBreezeModel, account.accountId, true);
    }

    @Autobind
    public showSubscriptionDialog(showIntroOnly?: boolean, skipIntro?: boolean) {
        return this.getAccount().pipe(
            // skip the start sub dialog if they are a cancelled subscription
            emptyIfUndefinedOrNull(),
            switchMap((account) => {
                const paymentMade = account!.extensions.estimateTotalDue > 0.004; // server is using decimal - anything less than 0.005 is treated as 0
                return this.commonDialogService.open(
                    SetInitialSubscriptionDialogComponent,
                    { account, showIntroOnly, skipIntro }).pipe(
                        map((originalStatus) => ({ originalStatus, paymentMade })));
            }),
            switchMap(({ originalStatus, paymentMade }) => {
                let message = "";
                switch (originalStatus) {
                    case SubscriptionStatus.PendingInactive:
                        message = `<p>You have resumed your subscription to ${AdaptClientConfiguration.AdaptProjectLabel}. `;
                        break;
                    case SubscriptionStatus.Active:
                        message = "<p>You have updated your subscription. ";
                        break;
                    default: // from trial or inactive
                        message = "<p>You have activated your subscription. ";
                        break;
                }

                message += paymentMade
                    ? "Your payment has been received and a receipt has been sent to your email.</p>"
                    : "No charges have been made to your credit card.</p>";
                message += "<p>We're looking forward to helping your business get to the next level!</p>";
                return this.commonDialogService.showMessageDialog("Subscription updated", message);
            }),
            // Do this after the above message dialog is dismissed or that dialog will be dismissed by the emit.
            // This subscription dialog will now be spawn from the subscription in addAccountBanner() for 0 day trial,
            // it will switchMap to showSubscriptionDialog. The followings emits will restart
            // the observable chain, switching back to the banner check, which will cancel the message dialog.
            // Is better to re-emit after the confirmation dialog closed anyway - giving user the context of where this is from,
            // i.e. banner still appearing -> need subscription -> that's why we are subscribing and hence this confirmation dialog.
            // After closing that message dialog, the permissions will be refreshed and banner gone.
            tap(() => this.authorisationNotificationService.refreshPermissions()),
            tap(() => this.accountStatusUpdated$.next()),
        );
    }

    public emitFirstPaymentReceived(value: boolean) {
        this.firstPaymentReceived$.next(value);
    }

    @Autobind
    public cancelSubscriptionDialog() {
        return this.getAccount().pipe(
            switchMap((account) => this.commonDialogService.open(CancelSubscriptionDialogComponent, account)),
            switchMap((result) => this.cancelSubscription(this.organisationService.getOrganisationId(), result.reason, result.freeText)),
            switchMap(() => this.commonDialogService.showMessageDialog("Success", "You have cancelled your subscription. You will receive an email confirming this.", "OK")),
            catchError((e) => this.commonDialogService.showErrorDialog("Failed To Save", ErrorHandlingUtilities.getHttpResponseMessage(e))),
            tap(() => this.accountStatusUpdated$.next()),
            switchMap(() => this.getAccount()),
        );
    }

    private cancelSubscription(organisationId: number, reason: string, freeText: string) {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/CancelSubscription`;
        return this.httpClient.post(uri, null, {
            params: { organisationId, reason, freeText },
        });
    }

    private getModuleFeaturesForModules(moduleIds: number[]) {
        if (moduleIds?.length) {
            return this.commonDataService.getAll(ModuleFeatureBreezeModel).pipe(
                map((moduleFeatures) => moduleFeatures
                    .filter((mf) => moduleIds.includes(mf.moduleId))
                    .map((mf) => mf.feature.name)),
            );
        } else {
            return of([]);
        }
    }
}
