<div class="card">
    <div class="card-header"
         *ngIf="showHeader">
        <h3 class="card-title">Billing details</h3>
    </div>
    <div class="card-body"
         *adaptLoadingUntilFirstEmit="let account of account$">

        <div *ngIf="contactDetailsSet"
             class="d-flex flex-column flex-md-row gap-3 align-items-stretch">
            <div class="d-flex flex-column flex-grow-1">
                <label>Contact name</label>
                <div>{{account!.contactName}}</div>
            </div>
            <div class="d-flex flex-column flex-grow-1">
                <label>Email address</label>
                <div>{{account!.contactEmail}}</div>
            </div>
        </div>

        <ng-container *ngIf="billingAddressSet">
            <label class="mt-3">Postal address</label>
            <div class="address">{{account!.contactAddress}}</div>
        </ng-container>

        <p *ngIf="!contactDetailsSet && !billingAddressSet">No billing details have been set</p>

        <button adaptButton="primary"
                class="mt-4"
                [adaptBlockingClick]="showUpdateBillingDetailsDialog">Update billing details
        </button>
    </div>
</div>
