<div class="d-flex flex-column align-items-stretch gap-2 px-3"
     *adaptLoading="!account">

    <h3 class="ms-3">Subscription summary</h3>
    <div class="card">
        <div class="card-body d-flex flex-column gap-2">
            <div class="d-flex flex-column flex-lg-row justify-content-lg-between gap-2 align-items-center align-items-lg-start">
                @if (this.allowsModuleSelection) {
                    <div class="d-flex flex-column gap-3">
                        @if (!hasChanges || !(account!.extensions.isTrial || account!.extensions.isZeroDayTrial)) {
                            <div>
                                <h4 class="mb-0">{{account!.extensions.subscriptionHeader}} subscription</h4>
                                <div><span>{{currentBillingPeriodLabel}} plan, </span>
                                    <span>total price per month: </span>
                                    <span>{{currentMonthlySubscriptionCost | currency: account!.extensions.currencyCode}}</span>
                                    @if (isCurrentAnnualPlan) {
                                        <span>, total annual cost: </span>
                                        <span>{{currentAnnualSubscriptionCost | currency: account!.extensions.currencyCode}}</span>
                                        @if (currentAnnualDiscountPercentage! > 0) {
                                            <span> (you're saving {{currentAnnualDiscountPercentage}}% per year)</span>
                                        }
                                    }
                                </div>
                            </div>
                        }

                        @if (hasChanges) {
                            <div>
                                <h4 class="mb-0">Selected subscription</h4>
                                <div><span>{{newBillingPeriodLabel}} plan, </span>
                                    <span>total price per month: </span>
                                    <span>{{newMonthlySubscriptionCost | currency: account!.extensions.currencyCode}}</span>
                                    @if (isNewAnnualPlan){
                                        <span>, total annual cost: </span>
                                        <span>{{newAnnualSubscriptionCost | currency: account!.extensions.currencyCode}}</span>
                                        @if (newAnnualDiscountPercentage! > 0) {
                                            <span> (you're saving {{newAnnualDiscountPercentage}}% per year)</span>
                                        }
                                    }
                                </div>
                                @if (currentMonthlySubscriptionCost && !(account!.extensions.isTrial || account!.extensions.isZeroDayTrial)) {
                                    <small>Note: changes to a subscription will apply immediately. Any remaining portion from the previous
                                        subscription will be credited to your account on a pro-rata basis.</small>
                                }
                            </div>
                        }

                        <button *ngIf="account!.extensions.isActive"
                                [adaptBlockingClick]="cancelSubscription"
                                [unsubscribeOnDestroy]="false"
                                adaptButton="iconlessLink">Cancel subscription</button>
                    </div>
                } @else {
                    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start w-100 gap-3">
                        <div>
                            <h4>{{account!.extensions.subscriptionHeader}} subscription</h4>
                            @if (!account!.extensions.isBilledUsingCreditCard) {
                                <div>Your organisation is invoiced directly by the accounts team at adapt.</div>
                            } @else if (account?.pricingModel?.pricingModelUsers?.length) {
                                <div>Your subscription is based on the number of users.</div>
                            } @else {
                                @if (account?.extensions?.isFree){
                                    <div>Your organisation is on a free plan.</div>
                                } @else {
                                    <div>Your subscription is a fixed monthly fee.</div>
                                }
                            }
                            @if (!account?.extensions?.isFree) {
                                <adapt-display-pricing-breakdown [account]="account"></adapt-display-pricing-breakdown>
                            }
                        </div>
                        <button *ngIf="account!.extensions.isActive"
                                [adaptBlockingClick]="cancelSubscription"
                                [unsubscribeOnDestroy]="false"
                                adaptButton="iconlessLink">Cancel subscription</button>
                    </div>

                }
                <!-- Not showing this button for embed if active -->
                <button adaptButton="primary"
                        class="text-nowrap"
                        *ngIf="this.allowsModuleSelection || !account!.extensions.isActive"
                        [disabled]="!hasChanges && account!.extensions.isActive"
                        [adaptBlockingClick]="saveChanges"
                        data-test="update-subscription-button">{{saveButtonText}}
                </button>
            </div>
        </div>
    </div>

    @if (this.allowsModuleSelection) {
        <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-2">
            <span>Select modules or add-ons to the subscription below and click on the <b>{{saveButtonText}}</b> button above to confirm. <a
                   href="https://theadaptway.com/pricing"
                   target="_blank">Compare plans <i class="fal fa-arrow-up-right-from-square"></i></a>
            </span>

            <dx-button-group #billingPeriodButtons
                             [items]="BillingPeriodButtons"
                             [disabled]="!account!.pricingModel?.annualDiscountPercentage || !newMonthlySubscriptionCost || hasAddonModule"
                             keyExpr="billingPeriod"
                             class="adapt-button-group"
                             buttonTemplate="buttonTemplate"
                             [selectedItemKeys]="[account!.billingPeriod]"
                             (onItemClick)="onBillingPeriodChanged($event.itemData.billingPeriod, true)">
                <div class="py-1 px-4 d-flex gap-2"
                     *dxTemplate="let buttonData of 'buttonTemplate'"
                     [attr.data-test]="'payment-frequency-' + buttonData.billingPeriod">
                    {{buttonData.text}}
                    <span *ngIf="annualDiscountPercentage > 0 && buttonData.billingPeriod === 12"
                          class="discount-badge">-{{annualDiscountPercentage}}%</span>
                </div>
            </dx-button-group>
        </div>
        @if (hasAddonModule) {
            <small class="align-self-end">Coaching is billed monthly, so only the monthly payment option is available.</small>
        } @else if (!newMonthlySubscriptionCost) {
            <small class="align-self-end">There is no payment required.</small>
        }

        @if (platformModules?.length) {
            <h3 class="ms-3">Platform</h3>
            <div class="card">
                <div class="card-body d-flex flex-column flex-lg-row justify-content-lg-between gap-4">
                    @for (categorisedGroup of categorisedPlatformModules; track $index) {
                        <h4 class="mt-lg-5">{{categorisedGroup.category}}</h4>
                        <div
                             class="flex-grow-1 d-flex flex-column flex-lg-row flex-wrap justify-content-lg-center align-items-stretch gap-3">
                            <div *ngFor="let module of categorisedGroup.modules"
                                 class="platform-module">
                                <h4>{{module.name}} {{account!.extensions.monthlyModuleFee(module.moduleId) | currency:
                                            account!.extensions.currencyCode}}/month
                                </h4>
                                <adapt-model-selector [isSelected]="isModuleSelected(module)"
                                                      [isCurrent]="isCurrentPlan(module)"
                                                      [isNew]="isNewPlan(module)"
                                                      [addText]="'To set this plan, click on the `' + saveButtonText + '` button'"
                                                      (isSelectedChange)="onPlatformModuleChanged($event, module)">
                                    {{module.description}}
                                </adapt-model-selector>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }

        @if (addOnModules?.length) {
            <h3 class="ms-3">Add-ons</h3>
            <div class="d-flex flex-column gap-2">
                <div *ngFor="let module of addOnModules"
                     class="card mb-0">
                    <div class="card-body d-flex flex-column">
                        <div class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center gap-3">
                            <h4 class="addon-model-name">{{module.name}}</h4>
                            <div class="addon-content"
                                 [innerHTML]="module.description"></div>
                            <div class="d-flex flex-column gap-2 align-items-center addon-model-selector">
                                <h4>{{account!.extensions.monthlyModuleFee(module.moduleId) | currency:
                                account!.extensions.currencyCode}}/month</h4>
                                <adapt-model-selector [required]="false"
                                                      newText="New add-on"
                                                      currentText="You currently have this add-on"
                                                      [addText]="'To include this add-on, click on the `' + saveButtonText + '` button'"
                                                      [isSelected]="isAddonSelected(module)"
                                                      [isCurrent]="account!.extensions.isActive && isInitialAddon(module)"
                                                      [isNew]="isNewAddon(module)"
                                                      (isSelectedChange)="onAddonSelectionChanged($event, module)"
                                                      [isUpdated]="isUpdatedAddon(module)"
                                                      [updateText]="'Click on the `' + saveButtonText + '` button above to update'">
                                    <div class="d-flex align-items-baseline gap-3">
                                        <b>{{module.name}}</b>
                                        @if (module.defaultQuantity && getAccountModule(module); as accountModule) {
                                            <dx-number-box [class.mb-3]="isUpdatedAddon(module)"
                                                           [format]="'#0' + (module.unit ? ' ' + module.unit : '')"
                                                           [(value)]="accountModule.quantity"
                                                           (valueChange)="updateHasChanges()"
                                                           [showSpinButtons]="true"
                                                           [width]="110"
                                                           [min]="1"
                                                           [max]="module.maxQuantity"></dx-number-box>
                                        }
                                    </div>
                                </adapt-model-selector>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        }

        <div class="d-flex flex-column flex-lg-row justify-content-between align-items-start mt-3">
            <adapt-configure-eula class="configure-eula"
                                  [account]="account"></adapt-configure-eula>
            <small *ngIf="this.allowsModuleSelection"
                   class="ms-3">Please note that all prices displayed on this page do not include GST</small>
        </div>
</div>
