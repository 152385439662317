import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "adapt-model-selector",
    templateUrl: "./model-selector.component.html",
    styleUrl: "./model-selector.component.scss",
})
export class ModelSelectorComponent {
    @Input() public isCurrent = false;
    @Input() public isNew = false;
    @Input() public isSelected = false;
    @Input() public currentText = "Current plan";
    @Input() public newText = "New plan";
    @Input() public required = true;
    @Input() public addText = "To set this plan, click on the `Update plan` button";
    @Input() public removeText = "To remove this add-on, click on the `Update plan` button";
    @Input() public isUpdated = false;
    @Input() public updateText = "";
    @Output() public isSelectedChange = new EventEmitter<boolean>();

    public toggleSelection() {
        if (!this.isSelected || !this.required) { // can only un-tick if not required
            this.isSelected = !this.isSelected;
            this.isSelectedChange.emit(this.isSelected);
        }
    }
}
