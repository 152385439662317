import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Invoice } from "./invoice";

export enum InvoiceItemType {
    Charge = "Charge",
    Credit = "Credit",
    Deduction = "Deduction",
    RemainingCredit = "RemainingCredit",
}

export class InvoiceItem extends BaseEntity<InvoiceItem> {
    public invoiceItemId!: number;
    public invoiceId!: number;
    public quantity!: number;
    public amount!: number;
    public description!: string;
    public invoice!: Invoice | null;
    public type!: InvoiceItemType;
}

export const InvoiceItemBreezeModel = new BreezeModelBuilder("InvoiceItem", InvoiceItem)
    .build();
