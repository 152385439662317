import { Component } from "@angular/core";
import { IdentityService } from "@common/identity/identity.service";
import { ITwoFactorWorkflowRunData } from "@org-common/lib/configuration/personal/two-factor-workflow/two-factor-workflow";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { saveAs } from "file-saver";
import moment from "moment";
import { BehaviorSubject, firstValueFrom } from "rxjs";

@WorkflowStepComponent("adapt-two-factor-workflow-recovery-codes")
@Component({
    selector: "adapt-two-factor-workflow-recovery-codes",
    templateUrl: "./two-factor-workflow-recovery-codes.component.html",
})
export class TwoFactorWorkflowRecoveryCodesComponent extends WorkflowStepComponentAdapter {
    public workflowStepCompleted = new BehaviorSubject<boolean>(true);

    public runData?: ITwoFactorWorkflowRunData;

    public copiedRecoveryCodes = false;

    public constructor(private identityService: IdentityService) {
        super();
    }

    public async workflowStepOnInit() {
        this.runData = this.workflowStep?.workflow?.runData as ITwoFactorWorkflowRunData;
    }

    public print() {
        window.print();
    }

    public async copyToClipboard(text: string) {
        await navigator.clipboard.writeText(text);
        this.copiedRecoveryCodes = true;
    }

    public async downloadCodes(text: string) {
        const sessionData = await firstValueFrom(this.identityService.identity$);
        if (sessionData) {
            const blob = new Blob([text], { type: "text/plain;charset=utf-8;" });
            const date = moment().format("YYYY-MM-DD");
            saveAs(blob, `adapt-${sessionData.userName}-${date}.txt`);
        }
    }
}
