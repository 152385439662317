import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

export interface IIdentityAction {
    httpMethod: string;
    partialUri: string;
    redirectUrl?: string;
    headers?: { [key: string]: string };
    dataTransform?: (...args: any[]) => any;
    preInitialisation?: boolean;
    withCredentials?: boolean;
    onSuccess?: (response: HttpResponse<any>) => void;
    onError?: (response: HttpErrorResponse) => void;
}

export enum IdentityAction {
    Login,
    RegisterOrganisation,
    ForgotPassword,
    ResetPassword,
    IsRegistered,
    GetExternalLoginStatus,
    ExternalLoginCallback,
    ManageTwoFactor,
    Logout,
    ChangePassword,
    GetServerSecurityRoles,
    GetHelpjuiceToken,
    AddPeople,
    SendWelcomeEmail,
    ValidateAuthenticationToken,
}

export class IdentityActions {
    public static Actions: Record<keyof typeof IdentityAction, IIdentityAction> = {
        Login: {
            partialUri: "/Token",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            dataTransform: $.param,
            httpMethod: "post",
        },
        RegisterOrganisation: {
            partialUri: "/Account/RegisterOrganisation",
            httpMethod: "post",
        },
        ForgotPassword: {
            partialUri: "/Account/ForgotPassword",
            httpMethod: "post",
        },
        ResetPassword: {
            partialUri: "/Account/ResetPassword",
            httpMethod: "post",
        },
        IsRegistered: {
            partialUri: "/Account/IsRegistered",
            httpMethod: "post",
        },
        GetExternalLoginStatus: {
            partialUri: "/Account/GetExternalLoginStatus",
            httpMethod: "get",
        },
        ExternalLoginCallback: {
            partialUri: "/Account/ExternalLoginCallback",
            httpMethod: "post",
            withCredentials: true,
        },
        ManageTwoFactor: {
            partialUri: "/Account/ManageTwoFactor",
            httpMethod: "post",
        },
        Logout: {
            partialUri: "/Account/Logout",
            httpMethod: "post",
        },
        ChangePassword: {
            partialUri: "/Account/ChangePassword",
            httpMethod: "post",
        },
        GetServerSecurityRoles: {
            partialUri: "/Account/GetServerSecurityRoles",
            httpMethod: "get",
        },
        GetHelpjuiceToken: {
            partialUri: "/Account/GetHelpjuiceToken",
            httpMethod: "get",
        },
        AddPeople: {
            partialUri: "/Account/AddPeople",
            httpMethod: "post",
            redirectUrl: "/account/set-password",
        },
        SendWelcomeEmail: {
            partialUri: "/Account/SendWelcomeEmail",
            httpMethod: "post",
        },
        ValidateAuthenticationToken: {
            partialUri: "/Account/ValidateAuthenticationToken",
            httpMethod: "get",
            preInitialisation: true,
            withCredentials: true,
        },
    };
}
