<div class="card">
    <div class="card-header"
         *ngIf="showHeader">
        <h3 class="card-title">Payment details</h3>
    </div>
    <div class="card-body"
         *adaptLoadingUntilFirstEmit="let account of account$">
        <ng-container *ngIf="!account?.extensions?.isActive && !account?.extensions?.isTrial; else activeSubscription">
            <p *ngIf="!account?.extensions?.isPendingCancellation">Your organisation's subscription status is currently not
                active.</p>

            <ng-container *ngIf="account?.extensions?.isPendingCancellation">
                <p>You have cancelled your subscription for {{AdaptProjectLabel}}.</p>
                <p>Your subscription ends on <strong>{{account?.nextSubscriptionInvoiceDate | adaptDate}}</strong>.</p>
                <p>After this date, your organisation will be in a read-only state, and you can log in at any time to retrieve your data.
                    You may resume your subscription at any time, you will not be charged again until the above date.</p>
            </ng-container>

            <button class="btn btn-primary"
                    (click)="showUpdateInvoicePaymentMethodDialog(account)">{{getSubscriptionButtonText(account)}}</button>
        </ng-container>
        <ng-template #activeSubscription>
            <ng-container *ngIf="account && account.extensions.isBilledUsingCreditCard">
                <adapt-configure-credit-card [organisationId]="account.organisationId"></adapt-configure-credit-card>
            </ng-container>
            <ng-container *ngIf="account && !account.extensions.isBilledUsingCreditCard">
                <p>Your organisation is being invoiced.</p>
                <button class="btn btn-primary"
                        (click)="showUpdateInvoicePaymentMethodDialog(account)">Update payment method</button>
            </ng-container>
        </ng-template>
    </div>
</div>
