<div class="d-flex flex-column align-items-center overflow-hidden">
    <adapt-see-more-popup [minWidth]="500"
                          popupTitle="Bullseye"
                          buttonText="View bullseye">
        <ng-template>
            <div class="d-flex flex-column align-items-center gap-3">
                <adapt-strategic-guidance area="bullseye"></adapt-strategic-guidance>

                <ng-container *adaptIfAuthorised="ReadBullseye; else notAuthorised">
                    <adapt-bullseye-view *ngIf="bullseye?.extensions?.areQuadrantsCompleted()"
                                         [hideOuter]="true"
                                         [showZoom]="false"
                                         class="mt-3 fixed-bullseye-width"></adapt-bullseye-view>
                    <div class="my-4 fixed-bullseye-width"
                         *ngIf="bullseye?.statement">
                        <label>Bullseye statement</label>
                        <div class="mt-2 purpose-statement lead"
                             [froalaView]="bullseye!.statement"></div>
                    </div>
                </ng-container>
                <button *adaptIfAuthorised="EditBullseye"
                        adaptButton="primary"
                        class="ms-4"
                        [adaptBlockingClick]="runBullseyeWorkflow">Define bullseye
                </button>
                <ng-template #notAuthorised>
                    <span>This feature is enabled only for the Growth+ platform subscription.</span>
                </ng-template>
            </div>
        </ng-template>
    </adapt-see-more-popup>
</div>
