import { Component, Input } from "@angular/core";
import { ConfigureFeatureBase } from "../../base-components/configure-feature-base";
import { IConfigItem } from "../../configuration.interfaces";
import { ConfigurationService } from "../../configuration.service";

@Component({
    selector: "adapt-configure-meetings",
    templateUrl: "./configure-meetings.component.html",
})
export class ConfigureMeetingsComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }
    
    public async initialiseData() {
        return Promise.resolve();
    }
}
