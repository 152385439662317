<ng-container *adaptLoading="!runData.meeting">
    <adapt-schedule-meeting (entitiesChange)="onEntitiesChanged($event)"
                            (meetingLocationChange)="onMeetingLocationChange($event)"
                            (hasConflictsChange)="runData.hasConflicts = $event"
                            (organiserInfoChange)="runData.organiserInfo = $event"
                            [(sendInvitations)]="runData.sendInvitations"
                            [(createTeamsMeeting)]="runData.createTeamsMeeting"
                            [meeting]="runData.meeting"
                            [editableDetails]="true"
                            [disabled]="submitting"></adapt-schedule-meeting>
</ng-container>

<ng-template #startMeetingNowButton>
    <div [adaptTooltip]="nonStartableMeetingInfo"
         [class.d-none]="!showRunMeetingNowButton">
        <button type="button"
                class="btn btn-secondary"
                adaptButtonIcon="runMeetingNow"
                data-test="run-meeting-now-button"
                [disabled]="!isValid.value || !!nonStartableMeetingInfo || submitting"
                [adaptBlockingClick]="runMeetingNow"
                [unsubscribeOnDestroy]="false"
                [enableButtonOnFinalize]="false">{{runMeetingNowButtonText}}</button>
    </div>
</ng-template>
