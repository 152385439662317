<adapt-dialog [maxWidth]="720">
    <h3 adapt-dialog-title>{{isSystem ? "View" : (isNew ? "Add" : "Edit")}} meeting template</h3>

    <div adapt-dialog-content
         class="d-flex flex-column gap-3">
        <div class="alert alert-info m-0">
            Give your template a name, description and agenda items or import them from another meeting.
        </div>

        <div>
            <h3>Template name</h3>
            <dx-text-box [(value)]="template.name"
                         [readOnly]="isSystem"
                         placeholder="Enter a name"
                         [adaptValidateEntity]="template"
                         adaptValidateEntityProperty="name"
                         data-test="template-name-input"></dx-text-box>
        </div>

        @if (!isSystem || !!template.meetingDescription) {
            <div>
                <h3>Description</h3>
                <adapt-html-editor [(contents)]="template.meetingDescription"
                                   [readonly]="isSystem"
                                   placeholderText="Describe any desired outcomes of the meeting (optional)"
                                   forceSize="compact"
                                   data-test="template-description-input"></adapt-html-editor>
            </div>
        }

        <div>
            <h3>Agenda items</h3>
            <adapt-edit-agenda [agendaTemplate]="template"
                               [hideNotesAndItems]="true"
                               [saveOnChange]="false"
                               (agendaItemsChange)="agendaItems = $event"></adapt-edit-agenda>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"
                                   [saveIsDisabled]="!hasAgendaItems"></adapt-standard-dialog-buttons>
</adapt-dialog>
