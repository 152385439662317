import { Component, Injector, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { OrganisationService } from "../../organisation/organisation.service";
import { EulaService } from "../eula.service";

@Component({
    templateUrl: "./eula-acceptance-page.component.html",
})
export class EulaAcceptancePageComponent extends BaseRoutedComponent implements OnInit {
    public requestorName?: string;
    public organisationName?: string;
    public eulaAccepted = false;
    public eulaAcceptanceSaved = false;
    public errorMessage?: string;
    public isValidLink$: Observable<boolean>;
    public token$ = new BehaviorSubject<string | undefined>(undefined);
    public isEulaAccepted?: boolean;
    public readonly defaultErrorMessage = "The license agreement link is invalid or has expired. Please see your coach to resend the invitation";

    public constructor(
        injector: Injector,
        private eulaService: EulaService,
        private organisationService: OrganisationService,
    ) {
        super(injector);

        this.isValidLink$ = this.token$.pipe(
            switchMap((token) => {
                return token
                    ? eulaService.validateEulaToken(token)
                    : of(false);
            }),
        );

        this.organisationService.currentOrganisation$.pipe(
            switchMap((org) => org ? this.eulaService.getEulaIsAccepted(org) : of(false)),
        ).subscribe((isEulaAccepted) => this.isEulaAccepted = isEulaAccepted);
    }

    public ngOnInit() {
        this.requestorName = this.getSearchParameterValue("requestorFirstName");
        this.organisationName = this.getSearchParameterValue("organisationName");
        this.token$.next(this.getSearchParameterValue("token"));
        this.notifyActivated();
    }

    @Autobind
    public acceptEula() {
        this.errorMessage = undefined;

        return this.token$.pipe(
            switchMap((token) => {
                return token
                    ? this.eulaService.acceptEula(token)
                    : throwError(() => new Error("Token must be defined"));
            }),
            tap(() => {
                this.eulaAcceptanceSaved = true;
                this.token$.complete();
            }),
            catchError((e) => {
                if (e.status === 400) {
                    this.errorMessage = this.defaultErrorMessage;
                } else {
                    this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
                }

                return of(null);
            }),
        );
    }
}
