import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { RouteService } from "@common/route/route.service";
import { RouteEventsService } from "@common/route/route-events.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChangeAction, ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { EMPTY, lastValueFrom } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { IConfigGroup, IConfigItem } from "../../configuration.interfaces";
import { ConfigArea } from "../../configuration-types";

@Component({
    selector: "adapt-configuration-page-base",
    templateUrl: "./configuration-page-base.component.html",
    styleUrls: ["./configuration-page-base.component.scss"],
    providers: [
        ConfigurationService,
    ],
})
export class ConfigurationPageBaseComponent extends BaseComponent implements OnChanges, OnInit {
    public static ConfigurationSectionParam = "section";

    public configItems: IConfigGroup[] = [];
    // have to change bindingPropertyName to avoid clash with dom's title, which will cause the title tooltip to be shown on the entire page
    @Input("configTitle") public title = "Configuration Area";
    @Input() public configGroups: IConfigGroup[] = [];
    @Output() public selectedItemChange = new EventEmitter<IConfigItem>();
    @Input() public team?: Team;
    public selectedItem?: IConfigItem;
    public saveError?: string;
    private ignoreListSelectionChange = false;

    public constructor(
        private authorisationService: AuthorisationService,
        private commonDataService: CommonDataService,
        protected featuresService: FeaturesService,
        public configurationService: ConfigurationService,
        private changeManager: ChangeManagerService,
        private routeService: RouteService,
        private routeEventsService: RouteEventsService,
        private accountService: AccountService,
    ) {
        super();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.configGroups && this.configGroups) {
            for (const group of this.configGroups) {
                for (const item of group.items) {
                    if (item.accessVerifier) {
                        if (this.team) {
                            item.hasPermission = (!item.featureName || (item.featureName && await this.featuresService.promiseToCheckIfFeatureActive(item.featureName)))
                                && await this.authorisationService.promiseToGetHasAccess(item.accessVerifier, this.team);
                        } else {
                            item.hasPermission = await this.authorisationService.promiseToGetHasAccess(item.accessVerifier);
                        }
                    } else {
                        item.hasPermission = true;
                    }
                }
            }

            // pricing model has module -> config items with feature not in the list will be filtered out
            const filteredFeatures = await lastValueFrom(this.accountService.getEnabledFeaturesForAccount());
            this.configGroups.forEach((group) => {
                group.items = group.items.filter((item) => item.hasPermission);
                if (filteredFeatures.length) {
                    group.items = group.items.filter((item) => !item.featureName || filteredFeatures.includes(item.featureName));
                }
            });
            this.configItems = this.configGroups.filter((group) => group.items.length > 0);

            if (this.updateConfigurationSection()) {
                return;
            }

            this.setSelectedItem(this.configItems.length && this.configItems[0].items.length
                ? this.configItems[0].items[0]
                : undefined);
        }
    }

    public ngOnInit() {
        this.routeEventsService.searchParameterChanged$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateConfigurationSection());
    }

    private updateConfigurationSection() {
        const section = this.routeService.getSearchParameterValue(ConfigurationPageBaseComponent.ConfigurationSectionParam);
        if (section && this.configItems?.length) {
            if (section in ConfigArea) {
                const areaId = ConfigArea[section as keyof typeof ConfigArea];
                const item = this.configItems
                    .flatMap((group) => group.items)
                    .find((i) => i.area === areaId);
                if (item) {
                    this.setSelectedItem(item);
                    return true;
                }
            }

            // remove parameter if invalid
            this.routeService.deleteSearchParameter(ConfigurationPageBaseComponent.ConfigurationSectionParam);
        }

        return false;
    }

    public async onSelectionChanged(e: IDxListSelectionChangedEvent<IConfigItem>) {
        if (this.ignoreListSelectionChange) {
            this.ignoreListSelectionChange = false;
            return;
        }

        if (this.commonDataService.hasChanges()) {
            // save the current changes so that we can reference them after the promise finishes
            const newSelection = [...e.addedItems];
            const oldSelection = [...e.removedItems];
            const changeAction = await lastValueFrom(this.changeManager.checkForChangesAndPrompt());
            if (changeAction === ChangeAction.Stay) {
                e.component!.scrollToItem(oldSelection[0]);

                // this will trigger a new selection changed event, so set a flag to ignore the next time this function is called
                this.ignoreListSelectionChange = true;
                e.component!.option("selectedItems", e.removedItems);
            } else if (changeAction === ChangeAction.DiscardAndContinue) {
                await lastValueFrom(this.commonDataService.cancel());
                this.setSelectedItem(newSelection[0]);
            } else if (changeAction === ChangeAction.SaveAndContinue) {
                await lastValueFrom(this.commonDataService.save());
                this.setSelectedItem(newSelection[0]);
            }

            // besides stay, all other changeAction will set blockRoute to false (to allow route change). So need to set block
            // again to intercept any changes when moving away from this page
            // - no harm calling it again even if is stay as it is just setting the blockRoute flag to true, which is already
            //   set for all pages from base controller factory
            this.changeManager.blockRouteOnUnsavedChanges();
        } else {
            this.setSelectedItem(e.addedItems.length ? e.addedItems[0] : undefined);
        }
    }

    private setSelectedItem(newSelection?: IConfigItem) {
        this.selectedItem = newSelection;
        this.selectedItemChange.emit(this.selectedItem);
        if (newSelection) {
            this.routeService.updateSearchParameterValue(ConfigurationPageBaseComponent.ConfigurationSectionParam, ConfigArea[newSelection.area]);
        }
    }

    @Autobind
    public saveChanges() {
        if (!this.configurationService.changesAreValid()) {
            return EMPTY;
        }

        this.saveError = undefined;

        return this.configurationService.save().pipe(
            catchError((e) => this.saveError = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    @Autobind
    public cancelChanges() {
        if (!this.configurationService.hasChanges()) {
            return EMPTY;
        }

        return this.configurationService.cancel().pipe(
            tap(() => this.saveError = undefined),
            tap(() => this.configurationService.initialiseConfigPage$.next()),
        );
    }
}
