<adapt-dialog [maxWidth]="900"
              [disallowCancel]="!!dialogData.disallowCancel"
              [fitHeight]="true">
    <h3 adapt-dialog-title>{{titleAction}} {{ProductLabel}} subscription</h3>
    <ng-container adapt-dialog-content>
        <ng-container *ngIf="isIntroPage">
            <adapt-styled-implementation-kit [articleId]="StartSubscriptionArticle"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>
        </ng-container>

        <ng-container *ngIf="!isIntroPage">
            <div *ngIf="promptingEula"
                 class="d-flex flex-column gap-3 h-100">
                <p class="m-0 border-bottom pb-3"><strong>
                        Please read the {{ProductLabel}} customer license agreement below,
                        tick the checkbox and accept on behalf of {{organisation?.name}}.</strong>
                </p>
                <adapt-eula (loadedChange)="eulaLoaded = $event"></adapt-eula>
                <dx-check-box class="d-block border-top pt-3"
                              text="I have read and agree to the terms of the customer license agreement"
                              [disabled]="!eulaLoaded"
                              [value]="eulaAccepted"
                              (valueChange)="eulaAccepted = $any($event)"></dx-check-box>
            </div>
            <div *ngIf="!promptingEula"
                 class="d-flex flex-column flex-lg-row-reverse gap-3">
                <div *ngIf="account"
                     class="d-flex flex-column flex-grow-1">
                    <ng-container *ngIf="!account.extensions.isFree && account.extensions.isBilledUsingCreditCard; else freeAccount">
                        <adapt-display-pricing-breakdown [account]="account"
                                                         [resetBillingCycle]="true"></adapt-display-pricing-breakdown>
                        <div class="small ms-2 d-flex flex-column gap-2">
                            <span *ngIf="account.extensions.canResumeSubscriptionWithoutCharge()">
                                Your previous subscription will remain active until <strong>{{account.nextSubscriptionInvoiceDate |
                                    adaptDate}}</strong>.
                                You will not be charged again until after this date.
                            </span>
                        </div>
                    </ng-container>
                    <ng-template #freeAccount>
                        <div class="alert alert-secondary">
                            <ng-container *ngIf="account.extensions.isBilledUsingCreditCard; else invoiceMessage">
                                Your credit card will not be charged with your free account.
                            </ng-container>
                            <ng-template #invoiceMessage>
                                Please note that your credit card will not be charged as your payment method is set to 'invoice.'
                                You will receive a detailed invoice for your account and can make payment according to the terms specified.
                            </ng-template>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="account.extensions.hasSubscriptionCost"
                     class="credit-card-column">
                    <h4>Credit card details</h4>
                    <ng-container *adaptLoading="cardDetailsLoading">
                        <adapt-update-credit-card-details *ngIf="!cardDetails"
                                                          [updateCreditCardInput]="creditCardInput"
                                                          (cardSet)="cardSetCorrectly = $event"></adapt-update-credit-card-details>

                        <adapt-configure-credit-card *ngIf="cardDetails"
                                                     [(cardDetails)]="cardDetails"
                                                     [organisationId]="creditCardInput.organisationIdentifier.organisationId"
                                                     [eulaToken]="creditCardInput.organisationIdentifier.eulaToken"
                                                     [allowEditing]="true"
                                                     (cardIsSet)="cardSetCorrectly = $event"></adapt-configure-credit-card>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!-- track this when coming across from signup with 0-day trial -->
        <adapt-marketing-tracker *ngIf="dialogData.disallowCancel"
                                 pageName="/set-initial-subscription"></adapt-marketing-tracker>
    </ng-container>

    <div adapt-dialog-footer
         class="w-100 d-flex gap-1"
         *ngIf="isIntroPage; else acceptEulaAndSubscribe">
        <ng-container *ngIf="!dialogData.showIntroOnly; else customSubscription">
            <button adaptButton="workflowNext"
                    class="ms-auto"
                    (click)="isIntroPage = false"
                    data-test="next-page-button">Next</button>
            <button *ngIf="!dialogData.disallowCancel"
                    adaptButton="iconlessCancel"
                    (click)="cancel()"></button>
        </ng-container>
        <ng-template #customSubscription>
            <button adaptButton="primary"
                    class="ms-auto"
                    (click)="cancel()"
                    data-test="next-page-button">Select subscription</button>
        </ng-template>
    </div>
    <ng-template #acceptEulaAndSubscribe>
        <div adapt-dialog-footer
             class="w-100 d-flex">
            <button adaptButton="workflowPrevious"
                    (click)="isIntroPage = true">Back</button>
            <ng-container *ngIf="promptingEula; else promptSubscribe">
                <adapt-standard-dialog-buttons adapt-dialog-footer
                                               (cancelClick)="cancel()"
                                               saveButtonPreset="primary"
                                               [saveBlockingClick]="acceptEula"
                                               [saveIsDisabled]="!eulaAccepted"
                                               [hideCancelButton]="!!dialogData.disallowCancel"
                                               saveText="Accept"></adapt-standard-dialog-buttons>
            </ng-container>
            <ng-template #promptSubscribe>
                <adapt-standard-dialog-buttons adapt-dialog-footer
                                               (cancelClick)="cancel()"
                                               saveButtonPreset="primary"
                                               [saveBlockingClick]="saveAndClose"
                                               [saveIsDisabled]="(!cardSetCorrectly || !account.contactName || !account.contactEmail) && account.extensions.hasSubscriptionCost"
                                               [hideCancelButton]="!!dialogData.disallowCancel"
                                               [saveText]="titleAction + ' subscription'"></adapt-standard-dialog-buttons>
            </ng-template>
        </div>
    </ng-template>
</adapt-dialog>
