import { DOCUMENT } from "@angular/common";
import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";
import { ExternalLoginProviders, IExternalLoginProvider } from "@common/identity/external-login/external-login-provider.interface";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ContentReadyEvent } from "devextreme/ui/text_box";
import { DxValidationGroupComponent } from "devextreme-angular";
import { EMPTY, from, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { forgotPasswordPageRoute } from "../forgot-password-page/forgot-password-page.route";
import { IdentityUxService, ILoginPayload, IReason } from "../identity-ux.service";

@Component({
    selector: "adapt-login-page",
    templateUrl: "./login-page.component.html",
})
export class LoginPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly ExternalLoginProviders = Object.values(ExternalLoginProviders)
        .filter((p) => p.enabled);

    @ViewChild("validationGroup") public validationGroup: DxValidationGroupComponent | undefined;

    public email = "";
    public password = "";
    public twoFactorCode = "";

    public needsTwoFactor = false;

    public isDisabled = false;
    public submitMessage?: string;

    public reason?: IReason;
    public forgotPasswordUrl$: Observable<string>;

    public constructor(
        public injector: Injector,
        public identityUxService: IdentityUxService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(injector);

        const reasonCode = this.getSearchParameterValue("reasonCode");

        if (reasonCode) {
            this.reason = identityUxService.getReason(reasonCode);
        }

        this.forgotPasswordUrl$ = forgotPasswordPageRoute.getRoute();
    }

    public get isNimbus() {
        return AdaptClientConfiguration.AdaptProjectName === AdaptProject.Nimbus;
    }

    public get projectLabel() {
        if (this.isNimbus) {
            // use alto as the project label when nimbus is not pointing at embed api
            return AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
                ? AdaptProjectLabel[AdaptProject.Cumulus]
                : AdaptProjectLabel[AdaptProject.Alto];
        }

        return AdaptClientConfiguration.AdaptProjectLabel;
    }

    public get swapProjectLabel() {
        return !AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
            ? AdaptProjectLabel[AdaptProject.Cumulus]
            : AdaptProjectLabel[AdaptProject.Alto];
    }

    public ngOnInit() {
        this.notifyActivated();
        // this is to handle the case where user has been removed from server and get user failed after login successful
        // Can't use navigationCancel as redirect from base.guard (goHome to /) will also cause a cancel
        // which will make this page visible again for a brief duraction before the new page is loaded.
        this.routeEventsService.userNavigationError$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((errorMessage) => {
            this.submitMessage = errorMessage;
            this.notifyActivated();
        });

        // previously with adapt-alto-only and adapt-not-alto-only which use content projection, contents from both cases
        // were loaded, where cumulus's two-column-page removing the shell padding, causing alto's login page to vertically
        // aligned nicely. Now they are separate and correct - login page won't vertically centered without this.
        this.shellUiService.removeDefaultShellPadding();
    }

    public changeBaseApi() {
        AdaptClientConfiguration.CurrentApiBaseUri = AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
            ? AdaptClientConfiguration.AltoApiBaseUri
            : AdaptClientConfiguration.EmbedApiBaseUri;
        window.location.reload();
    }

    public validateGroup() {
        this.isDisabled = !this.validationGroup?.instance.validate().isValid;
    }

    public onTwoFactorInputContentReady(event: ContentReadyEvent) {
        event.component.focus();
        setTimeout(() => this.validateGroup());
    }

    @Autobind
    public submit() {
        this.submitMessage = undefined;

        const payload: ILoginPayload = {
            userName: this.email,
            password: this.password,
        };

        if (this.needsTwoFactor) {
            const code = this.twoFactorCode.replace(/\s/g,"").trim();

            if (code.length > 6) {
                payload.twoFactorRecoveryCode = code;
            } else {
                payload.twoFactorCode = code;
            }
        }

        // Use observable so that if the page changes before the promise returns
        // we don't set the view to be loading
        // (this sometimes happens during e2e runs)
        return from(this.identityUxService.promiseToLogin(payload)).pipe(
            tap(() => this.shellUiService.setViewIsLoading(true)),
            catchError((e) => {
                // this is MethodologyConstants.TwoFactorRequiredType
                if (e === "two_factor_required") {
                    this.needsTwoFactor = true;

                    // clear out the reason so we're only showing the 2fa warning
                    this.reason = undefined;
                    return EMPTY;
                }

                this.submitMessage = e;
                return EMPTY;
            }),
        );
    }

    @Autobind
    public async signInProvider(provider: IExternalLoginProvider) {
        let signInUrl = provider.signInUrl;

        // pass the returnUrl along to the webapi so we get it back on /external-login
        const returnUrl = this.routeService.getReturnUrl();
        if (returnUrl) {
            signInUrl = UrlUtilities.setQueryParam(signInUrl, "returnUrl", returnUrl);
        }

        this.document.location.assign(signInUrl);
    }
}
