import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Route, Router } from "@angular/router";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { from, lastValueFrom } from "rxjs";
import { filter, take } from "rxjs/operators";

export interface IAdaptLinkObject {
    path: string;
    queryParams: Params;
    url: string;
}

export type SearchParamValue = string | number | boolean | undefined;

@Injectable({
    providedIn: "root",
})
export class RouteService {
    private activatedRoute?: ActivatedRoute;
    private activatingRouteSnapshot?: ActivatedRouteSnapshot;

    private skipDialogCloseAllOnRouteChange = false;

    public constructor(
        private router: Router,
    ) {
        // this is required to avoid removing history state from 'back' if guard returning false
        // resulting in a NavigationCancel
        // - Private option not exposed (from https://github.com/angular/angular/issues/13586#issuecomment-872592435)
        (this.router as any).canceledNavigationResolution = "computed";
    }

    public get routes() {
        return this.router.config;
    }

    public get currentRoute() {
        return this.currentActivatedRoute?.routeConfig;
    }

    public get currentControllerId(): string {
        return this.currentRoute?.data?.id;
    }

    public get currentRouterState() {
        return this.router.routerState.snapshot;
    }

    // use this to fall back to router url (while not in navigation)
    public get currentUrl() {
        return this.currentNavigationUrl ?? this.router.url;
    }

    // this has to be during a navigation (before navigation end)
    public get currentNavigationUrl() {
        return this.router.getCurrentNavigation()?.extractedUrl.toString();
    }

    public get previousNavigationUrl() {
        return this.router.getCurrentNavigation()?.previousNavigation?.extractedUrl.toString();
    }

    public get currentNavigationState() {
        return this.router.getCurrentNavigation()?.extras?.state;
    }

    public get previousNavigationState() {
        return this.router.getCurrentNavigation()?.previousNavigation?.extras?.state;
    }

    public get currentActivatedRouteSnapshot() {
        return this.activatingRouteSnapshot ?? this.currentActivatedRoute?.snapshot;
    }

    public get currentActivatedRoute() {
        return this.activatedRoute;
    }

    public set currentActivatedRoute(currentRoute: ActivatedRoute | undefined) {
        this.activatedRoute = currentRoute;
    }

    public get skipDialogCloseAll() {
        return this.skipDialogCloseAllOnRouteChange;
    }

    public set skipDialogCloseAll(value: boolean) {
        this.skipDialogCloseAllOnRouteChange = value;
    }

    // this is not the actual activated route -> just the intended route needed to evaluate CanActivate
    public setActivatingRouteSnapshot(snapshot?: ActivatedRouteSnapshot) {
        this.activatingRouteSnapshot = snapshot;
    }

    public getRoute<TParams extends Params, TInput>(route: IAdaptRoute<TParams, TInput>, namedParams?: TParams, searchParams?: Params) {
        return from(this.getControllerRoute(route.id, namedParams, searchParams));
    }

    public getRouteObject<TParams extends Params, TInput>(route: IAdaptRoute<TParams, TInput>, namedParams?: TParams, searchParams?: Params) {
        return from(this.getControllerRouteObject(route.id, namedParams, searchParams));
    }

    public gotoRoute<TParams extends Params, TInput>(route: IAdaptRoute<TParams, TInput>, namedParams?: TParams, searchParams?: Params, redirect?: boolean, replaceUrlIfSamePath?: boolean) {
        return from(this.gotoControllerRoute(route.id, namedParams, searchParams, redirect, replaceUrlIfSamePath));
    }

    // argument types matching @angular/router/Params (string, any)
    public async updateSearchParameterValue(key: string, value: SearchParamValue, replaceUrl = true) {
        const queryParams: Params = {};
        queryParams[key] = value ?? null;
        // [] is to create from current path
        const urlTree = this.router.createUrlTree([], {
            queryParams,
            queryParamsHandling: "merge", // either "merge" or "preserve" - to update, use merge
        });
        return await this.router.navigateByUrl(urlTree, { replaceUrl });
    }

    public getRouteParamForSnapshot(snapShot: ActivatedRouteSnapshot | undefined, paramName: string) {
        let paramValue = snapShot?.paramMap.get(paramName);
        if (!paramValue && snapShot) {
            if (!paramName.endsWith("?")) {
                // try the optional param
                paramName += "?";
                paramValue = snapShot?.paramMap.get(paramName);
            }
        }

        return paramValue === null
            ? undefined
            : paramValue;
    }

    public getRouteParam(paramName: string) {
        return this.getRouteParamForSnapshot(this.currentActivatedRouteSnapshot, paramName);
    }

    public getRouteParamInt(paramName: any) {
        return NumberUtilities.parseNumber(this.getRouteParam(paramName));
    }

    public getSearchParameters() {
        return this.currentActivatedRouteSnapshot?.queryParams;
    }

    public getSearchParameterValue(paramName: string) {
        const paramValue = this.currentActivatedRouteSnapshot?.queryParamMap.get(paramName);
        return paramValue === null
            ? undefined
            : paramValue;
    }

    public getSearchParameterIntValue(paramName: string) {
        return NumberUtilities.parseNumber(this.getSearchParameterValue(paramName));
    }

    public deleteSearchParameter(key: string, replaceUrl = true) {
        const queryParams: Params = {};
        queryParams[key] = null;
        const urlTree = this.router.createUrlTree([], {
            queryParams,
            queryParamsHandling: "merge",
        });
        return this.router.navigateByUrl(urlTree, { replaceUrl });
    }

    public deleteSearchParameters(params: string[], replaceUrl = true) {
        const queryParams: Params = {};
        for (const param of params) {
            queryParams[param] = null;
        }
        const urlTree = this.router.createUrlTree([], {
            queryParams,
            queryParamsHandling: "merge",
        });
        return this.router.navigateByUrl(urlTree, { replaceUrl });
    }

    public navigateByUrl(url: string, state?: Params) {
        return this.router.navigateByUrl(url, { state });
    }

    public isLinkObject(object: any) {
        return ObjectUtilities.isObject(object) && "path" in object && "queryParams" in object;
    }

    public parseRouterObject(url?: IAdaptLinkObject | string) {
        if (!url) {
            return undefined;
        }

        if (this.isLinkObject(url)) {
            return url as IAdaptLinkObject;
        }

        const urlTree = this.router.parseUrl(url as string);
        return {
            path: this.stripQueryParams(url as string),
            queryParams: urlTree.queryParams,
            url,
        } as IAdaptLinkObject;
    }

    public async getControllerRoute(controllerId: string, namedParams?: Params, searchParams?: Params) {
        const linkObject = await this.getControllerRouteObject(controllerId, namedParams, searchParams);
        return linkObject.path + this.queryParamsToString(linkObject.queryParams);
    }

    private queryParamsToString(queryParams?: Params) {
        let queryParamsSegment = "";
        let searchMarker = "?";
        if (ObjectUtilities.isNotNullOrUndefined(queryParams)) {
            for (const [key, value] of Object.entries(queryParams!)) {
                if (value !== undefined && value !== null) { // not adding to query param if value is not defined
                    queryParamsSegment += searchMarker + key + "=" + encodeURIComponent(value);
                    searchMarker = "&";
                }
            }
        }

        return queryParamsSegment;
    }

    public async getControllerRouteObject(controllerId: string, namedParams?: Params, searchParams?: Params) {
        let url = "/";
        const route = this.getControllerRouteConfig(controllerId);

        if (!route) {
            return {
                path: url,
                url,
            } as IAdaptLinkObject;
        }

        url = await this.evaluateRouteUrlParams(route, route.data!.url, namedParams);

        // If a parameter is missing, then we'll end up with a double slash, so clear that out
        url = url.replace("//", "/");

        return {
            path: url,
            queryParams: searchParams,
            url: url + this.queryParamsToString(searchParams),
        } as IAdaptLinkObject;
    }

    public async getRouteUrl(route: Route, url: string, namedParams?: Params, queryParams?: Params) {
        return await this.evaluateRouteUrlParams(route, url, namedParams) + this.queryParamsToString(queryParams);
    }

    public async evaluateRouteUrlParams(route: Route, url: string, namedParams?: Params) {
        // evaluate params getter
        const params: Params = {};
        if (route.data!.paramGetters) {
            const keys = Object.keys(route.data!.paramGetters);
            for (const key of keys) {
                const getter = route.data!.paramGetters[key];
                const value = await lastValueFrom(getter.pipe(
                    filter((orgUrl) => !!orgUrl),
                    take(1),
                ));
                params[key] = value;
            }
        }

        if (!namedParams) {
            namedParams = {};
        }

        return url.replace(/:(\w+)(\??)/g, (match, namedParam, isOptional) => {
            // namedParams should take precedence over params set using the link configuration
            const replacement = namedParams![namedParam] || params[namedParam] || "";
            if (!isOptional && !replacement) {
                // This will obviously look wrong, but will trigger the intended route at least so we don't
                // get spurious error messages
                return match;
            }
            return replacement;
        });
    }

    public getReturnUrl() {
        const routeSnapshot = this.currentActivatedRouteSnapshot;
        return routeSnapshot?.queryParamMap.get("returnUrl");
    }

    public gotoRedirect() {
        const returnUrl = this.getReturnUrl();
        if (returnUrl) {
            const queryParams: Params = {};
            queryParams.returnUrl = null;
            queryParams.reasonCode = null;

            // set by /external-login
            queryParams.provider = null;
            queryParams.error = null;

            // add in the query params from the return URL
            const parsedUrl = this.router.parseUrl(returnUrl);
            Object.assign(queryParams, parsedUrl.queryParams);

            const urlTree = this.router.createUrlTree([this.stripQueryParams(returnUrl)], {
                queryParams,
                queryParamsHandling: "merge",
            });
            return this.router.navigateByUrl(urlTree, { replaceUrl: true });
        }

        return this.gotoHome();
    }

    public gotoHome() {
        return this.router.navigateByUrl("/");
    }

    public async gotoControllerRoute(controllerId: string, namedParams?: Params, searchParams?: Params, redirect?: boolean, replaceUrlIfSamePath?: boolean) {
        const url = await this.getControllerRoute(controllerId, namedParams, searchParams);
        if (replaceUrlIfSamePath) {
            if (this.stripQueryParams(this.currentUrl) === this.stripQueryParams(url)) {
                redirect = true;
            }
        }
        return this.router.navigateByUrl(url, { replaceUrl: redirect });
    }

    public getControllerRouteConfig(controllerId?: string) {
        // some routes don't have controllers (ie dropdowns or function calls) so filter these out beforehand
        if (!controllerId) {
            return undefined;
        }

        return this.router.config.find((value) => value.data?.id === controllerId);
    }

    public stripQueryParams(url: string | undefined) {
        if (url) {
            return url.split("?")[0];
        } else {
            return "";
        }
    }
}
