<ng-container *ngIf="account && account.eulaAcceptedDate; else eulaNotAcceptedTemplate">
    <p class="ms-3">Click <button adaptButton="iconlessLink"
                (click)="showEula()">here</button> to view the customer license agreement.</p>
</ng-container>

<ng-template #eulaNotAcceptedTemplate>
    <div class="d-flex flex-column gap-2">
        <h3 class="ms-3">Customer license agreement</h3>
        <div class="alert alert-warning"
             role="alert">
            <span class="fal fa-info-circle"></span>
            The customer license agreement has not been accepted for this organisation.
        </div>

        <!-- TODO Only coach? Another permission for this? -->
        An organisation leader must sign the agreement before any user access is allowed.
        Please select the organisation leader from the list below to accept the agreement.
        <adapt-select-person [(person)]="eulaPerson"
                             [userTypes]="allowedEulaPersonUserTypes"
                             [filter]="isEmployee"></adapt-select-person>
        <button adaptButtonIcon="fal fa-file-signature"
                [disabled]="!eulaPerson"
                [adaptBlockingClick]="sendEulaInvite"
                [adaptBlockingClickParam]="eulaPerson">Send License Agreement Email</button>

        <div *ngIf="emailSentSuccessfully"
             class="alert alert-info"
             role="alert">Email sent successfully</div>

        <div *ngIf="sendError"
             class="alert alert-danger"
             role="alert">{{sendError}}</div>

        <div *ngIf="account?.eulaPerson && !emailSentSuccessfully">
            The customer license agreement has previously been sent to {{account!.eulaPerson!.fullName}}.
        </div>
    </div>
</ng-template>
