import moment from "moment";
import { EventCadenceCycle } from "./event-cadence-cycle";

export class EventCadenceCycleExtensions {
    // the threshold for month difference where we should add an extra 12 months
    // e.g. October -> January, only 3 months difference, so 12 months will be added, total 15 months.
    public readonly shortCycleThreshold = 4;
    public readonly longCycleThreshold = 16;

    public constructor(private eventCadenceCycle: EventCadenceCycle) { }

    public getCycleLength(startDate: Date) {
        const currentMonth = startDate.getMonth() + 1;

        // desired start date is this month, normal cadence cycle
        if (this.eventCadenceCycle.month === currentMonth) {
            return 12;
        }

        const endDate = moment([
            // add a year if the cycle month is before the provided month (i.e. next year)
            moment(startDate).year() + (this.eventCadenceCycle.month < currentMonth ? 1 : 0),
            this.eventCadenceCycle.month - 1,
            1,
        ]);

        let monthDifference = endDate.diff(startDate, "months", true);

        // short cycle, wrap around to next year
        if (monthDifference <= this.shortCycleThreshold) {
            monthDifference += 12;
        }

        return Math.min(this.longCycleThreshold, Math.ceil(monthDifference));
    }

    public getEndDate(startDate: Date) {
        const month = startDate.getMonth();
        const cycleLength = this.getCycleLength(startDate);

        return moment({ year: moment(startDate).year(), month })
            .add(cycleLength, "months")
            .startOf("month")
            .add(-1, "day")
            .toDate();
    }

    public getNextCycleStartDate(startDate: Date) {
        return moment(this.getEndDate(startDate))
            .add(1, "day")
            .toDate();
    }

    public get nextCycleStartDate() {
        return this.getNextCycleStartDate(this.startDate.toDate());
    }

    public get nextCycleMonthYear() {
        return moment(this.nextCycleStartDate)
            .format("MMMM YYYY");
    }

    public get startMonthYear() {
        return this.startDate.format("MMMM YYYY");
    }

    public get endMonthYear() {
        return this.endDate.format("MMMM YYYY");
    }

    public get firstCycleLength() {
        return this.getCycleLength(this.startDate.toDate());
    }

    private get endDate() {
        return moment(this.getEndDate(this.startDate.toDate()));
    }

    private get startDate() {
        return moment().startOf("month");
    }
}
