import { Injector } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { from, ObservableInput } from "rxjs";
import { tap } from "rxjs/operators";
import { IGuard } from "./guard.interface";
import { GuardFailureType, RouteEventsService } from "./route-events.service";

export abstract class AbstractGuard implements IGuard {
    public abstract canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypass: boolean): ReturnType<CanActivateFn>;

    private routeEventsService?: RouteEventsService;

    protected constructor(
        injector: Injector,
    ) {
        this.routeEventsService = injector.get(RouteEventsService);
    }

    public canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
        return this.canActivateWithBypassEvent(route, routerState, false);
    }

    protected wrapActivationResult<O extends ObservableInput<boolean>>(
        result: O,
        type: GuardFailureType,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        bypassEvent: boolean,
    ) {
        return from(result).pipe(
            tap((passed) => {
                if (!bypassEvent && !passed && this.routeEventsService) {
                    // only emitting if not bypassing and result is negative
                    this.routeEventsService.emitGuardFailureEvent(type, route, state);
                }
            }),
        );
    }
}
