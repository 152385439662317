import { Component, ElementRef } from "@angular/core";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { DefineBullseyeWorkflow } from "../bullseye-workflow";

@MeetingAgendaItemComponent("adapt-setting-up-bullseye-agenda-item")
@Component({
    selector: "adapt-setting-up-bullseye-agenda-item",
    templateUrl: "./setting-up-bullseye-agenda-item.component.html",
    styleUrls: ["./setting-up-bullseye-agenda-item.component.scss"],
})
export class SettingUpBullseyeAgendaItemComponent extends BaseComponent {
    public bullseye?: Bullseye;
    public readonly EditBullseye = StrategyAuthService.EditBullseye;
    public readonly ReadBullseye = StrategyAuthService.ReadBullseye;

    public constructor(
        elementRef: ElementRef,
        private dialogService: AdaptCommonDialogService,
        bullseyeService: BullseyeService,
    ) {
        super(elementRef);

        bullseyeService.getBullseye().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((bullseye) => this.bullseye = bullseye);
    }

    @Autobind
    public runBullseyeWorkflow() {
        return this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: DefineBullseyeWorkflow,
        });
    }
}
