import moment from "moment/moment";
import { ModuleType } from "../embed/module";
import { ConnectionType } from "../organisation/connection-type";
import { Account, OrganisationType, PaymentMethod, SubscriptionStatus, SubscriptionSubStatus } from "./account";
import { InvoiceType } from "./invoice-type";

// duplicated in subscription.cy.ts
export enum BillingPeriod {
    Monthly = 1,
    Quarterly = 3,
    Annually = 12
}

export const BillingPeriodButtons: any[] = [{
    billingPeriod: BillingPeriod.Monthly,
    text: "Monthly",
}, {
    billingPeriod: BillingPeriod.Annually,
    text: "Annually",
}];

export class AccountExtensions {
    public constructor(private account: Account) { }

    public get currencyCode() {
        return this.account.currency?.code.toUpperCase();
    }

    public get isActive() {
        return this.account.status === SubscriptionStatus.Active;
    }

    public get isTrial() {
        return this.account.status === SubscriptionStatus.Trial;
    }

    public get isZeroDayTrial() {
        // account would have been archived by WebJob overnight and becomes inactive
        // - just need to verify this is a 0 day trial account - provided the subscription is not active
        // - after subscribing, nextSubscriptionInvoiceDate will be moved forward and this won't be true anymore
        return this.account.pricingModel?.trialLength === 0 && // will be false if no pricingModel
            this.account.status !== SubscriptionStatus.Active &&
            !!this.account.nextSubscriptionInvoiceDate &&
            // Date1 === Date2 returns false even if Date1.getTime() === Date2.getTime()
            this.account.subscriptionCommencedDate?.getTime() === this.account.nextSubscriptionInvoiceDate.getTime(); // this is already set by org builder
    }

    public get isExpiredTrial() {
        const expiredTrialSubStatuses = [SubscriptionSubStatus.TrialEnded];
        return expiredTrialSubStatuses.includes(this.account.subStatus) && this.isInactive;
    }

    public get isPendingCancellation() {
        return this.account.status === SubscriptionStatus.PendingInactive;
    }

    public get isInactive() {
        return this.account.status === SubscriptionStatus.Inactive;
    }

    public get isBilledUsingCreditCard() {
        return this.account.paymentMethod === PaymentMethod.CreditCard;
    }

    public get isCreditCardSet() {
        return !!this.account.paymentProcessorCustomerId;
    }

    public get isStandardOrganisation() {
        return this.account.organisationType === OrganisationType.Standard;
    }

    public get isUsingPricingModel() {
        return !!this.account.pricingModelId;
    }

    public get hasSubscriptionSubStatus() {
        return this.account.subStatus !== SubscriptionSubStatus.None;
    }

    /**
     * The monthly cost for the module ex GST.
     */
    public monthlyModuleFee(moduleId: number) {
        if (!this.account?.pricingModel) {
            return 0;
        }

        const module = this.account?.pricingModel?.pricingModelModules.find((pmm) => pmm.moduleId === moduleId);
        if (!module) {
            return 0;
        }

        const accountModule = this.account?.accountModules.find((m) => m.moduleId === moduleId);
        return module?.monthlyFeeDollars * (accountModule?.quantity ?? module?.module.defaultQuantity ?? 1);
    }

    /**
      * Get the annual cost of this subscription ex GST.
      * Both fixed and per user components are calculated.
    */
    public get annualSubscriptionCost() {
        if (this.account.paymentMethod == PaymentMethod.Invoice) {
            return undefined;
        }

        const monthlyCost = this.monthlySubscriptionCost;
        if (!monthlyCost) {
            return 0;
        }

        return Math.floor(monthlyCost * BillingPeriod.Annually);
    }

    /**
     * Get the monthly cost of this subscription ex GST.
     * Both fixed, module, add-on and per user components are calculated.
     * This also takes into account the reduced cost if an annual subscription discount is being applied.
     */
    public get monthlySubscriptionCost() {
        if (this.account.paymentMethod == PaymentMethod.Invoice) {
            return undefined;
        }

        let monthlyFee = 0;
        let annualDiscountPercentage = 0;

        if (this.account.pricingModel) {
            annualDiscountPercentage = this.account.pricingModel.annualDiscountPercentage;

            // fixed monthly fee
            monthlyFee = this.account.pricingModel.monthlyFeeDollars;

            // per user costs
            if (this.account.pricingModel.pricingModelUsers?.length) {
                this.account.pricingModel.pricingModelUsers.forEach((pricingModelUser) => monthlyFee +=
                    (this.account.organisation?.connections.filter((c) =>
                        c.isActive() && c.userType === pricingModelUser.userType && c.connectionType !== ConnectionType.Coach).length ?? 0)
                    * pricingModelUser.monthlyFeeDollars);
            }

            // module costs
            if (this.account.accountModules?.length) {
                monthlyFee += this.account.accountModules.reduce((acc, accountModule) => {
                    const pricingModelModule = this.account.pricingModel!.pricingModelModules.find((pm) => pm.moduleId == accountModule.moduleId);
                    if (!pricingModelModule) {
                        return acc;
                    }

                    return acc + (pricingModelModule!.monthlyFeeDollars * (accountModule.quantity ?? 1));
                }, 0);
            }
        }

        if (this.account.monthlyFeeDollars > 0) {
            monthlyFee += this.account.monthlyFeeDollars;
        }

        if (this.account.billingPeriod === BillingPeriod.Annually) {
            monthlyFee = monthlyFee * (1 - (annualDiscountPercentage / 100));
        }

        return monthlyFee;
    }

    /**
     * Gets the actual discount percentage applied for the account (ONLY applies for annual plans)
     */
    public get appliedAnnualDiscountPercentage() {
        return this.account.billingPeriod === BillingPeriod.Annually
            ? this.annualDiscountPercentage
            : 0;
    }

    /**
     * Gets the annual discount percentage that is set in the pricing model
     */
    public get annualDiscountPercentage() {
        return this.account.pricingModel?.annualDiscountPercentage ?? 0;
    }

    /**
     * Whether this account is free or not (free plan or being charged zero dollars)
     */
    public get isFree() {
        if (this.account.pricingModel) {
            return this.account.pricingModel.extensions.isFree && this.account.monthlyFeeDollars === 0;
        }

        return this.account.monthlyFeeDollars === 0;
    }

    /**
     * Get subscription cost, based on the billing period.
     * Only supports Monthly & Annually billing periods
     */
    public get subscriptionCost() {
        switch (this.account.billingPeriod) {
            case BillingPeriod.Monthly:
                return this.account.extensions.monthlySubscriptionCost;
            case BillingPeriod.Annually:
                return this.account.extensions.annualSubscriptionCost;
            default:
                throw new Error(`We don't currently support calculating cost for billing period: ${this.account.billingPeriod}`);
        }
    }

    // This is different than free account - this can be an account with no monthly payment due to the selected modules
    public get hasSubscriptionCost() {
        return Math.floor(this.subscriptionCost ?? 0) > 0;
    }

    /**
     * Gets the estimate of the total due (including tax) based on the subscription cost for the next period and any remaining credit on the account
     */
    public get estimateTotalDue() {
        const totalCost = this.account.extensions.getTotalCost(this.account.extensions.subscriptionCost ?? 0);
        return totalCost - this.account.extensions.totalCredit;
    }

    /**
     * Based on the current date, calculate how much credit is remaining from the last invoice's period on a pro-rata basis
     */
    public get proRataCredit() {
        // no invoice will not generate an adjustment
        const previousInvoice = this.account.extensions.latestInvoice;
        if (!previousInvoice) {
            return 0;
        }

        const periodStart = moment.utc(previousInvoice.periodStartDate).startOf("day");
        const periodEnd = moment.utc(previousInvoice.periodEndDate).startOf("day");
        const today = moment.utc().startOf("day");

        // if today is before or after the latest invoice, then don't generate an adjustment
        if (today.isBefore(periodStart) && today.isAfter(periodEnd)) {
            return 0;
        }

        const adjustment = previousInvoice.totalCharges * (1 + periodEnd.diff(today, "days")) / (1 + periodEnd.diff(periodStart, "days"));
        return adjustment;
    }

    /**
     * Calculate the total credit on this account (past + new credit)
     * NOTE: Credits are always including GST
     */
    public get totalCredit() {
        const lastInvoiceRemainingCredit = this.account.extensions.latestInvoice?.remainingCredit ?? 0;
        return this.account.remainingCreditDollars !== lastInvoiceRemainingCredit
            ? this.account.remainingCreditDollars // remaining credit update with invoice without charge and hence invoice deleted but credit added to account
            : this.account.remainingCreditDollars + this.account.extensions.proRataCredit;
    }

    /**
     * Based on the passed in cost, calculates the cost including tax (if applicable), and rounds to 2 DP
     */
    public getTotalCost(cost: number) {
        const taxRate = this.account.taxExempt
            ? 1
            : 1.1;

        // convert the value to 2 DP
        return parseFloat((cost * taxRate).toFixed(2));
    }

    /**
     * Based on the passed in cost, calculates the potential tax, and rounds to 2 DP
     * Depending on whether this account is taxExempt or now will determine whether there is tax to pay
     */
    public getTax(cost: number) {
        if (this.account.taxExempt) {
            return 0;
        }

        return parseFloat((cost * 0.1).toFixed(2));
    }

    /**
     * Gets a list of the ADD-ON modules is active for this account
     */
    public get addOnModules() {
        return this.account.accountModules
            .map((accountModule) => accountModule.module)
            .filter((module) => module.moduleType === ModuleType.AddOn);
    }

    /**
     * Gets a list of the STANDARD modules is active for this account
     */
    public get platformModules() {
        return this.account.accountModules
            .map((accountModule) => accountModule.module)
            .filter((module) => module.moduleType === ModuleType.Platform);
    }

    public get billingMethodAndPeriodLabel() {
        const billingMethod = this.isBilledUsingCreditCard
            ? "credit card payments"
            : "invoices";
        switch (this.account.billingPeriod) {
            case BillingPeriod.Monthly:
                return `${this.billingPeriodLabel()} ${billingMethod}`;
            case BillingPeriod.Quarterly:
                return `${this.billingPeriodLabel()} ${billingMethod}`;
            case BillingPeriod.Annually:
                return `${this.billingPeriodLabel(true)} ${billingMethod}`;
            default:
                return `${billingMethod} every ${this.account.billingPeriod} months`;
        }
    }

    public billingPeriodLabel(annualAsAdverb = false) {
        switch (this.account.billingPeriod) {
            case BillingPeriod.Monthly:
                return "monthly";
            case BillingPeriod.Quarterly:
                return "quarterly";
            case BillingPeriod.Annually:
                return annualAsAdverb ? "annually" : "annual";
            default:
                throw new Error(`invalid billing period: ${this.account.billingPeriod}`);
        }
    }

    public get subscriptionHeader() {
        if (this.account.extensions.isActive) {
            return "Current";
        } else if (this.account.extensions.isTrial || this.account.extensions.isZeroDayTrial) {
            return "Selected";
        } else {
            return "Previous";
        }
    }

    /**
     * Gets the latest invoice that is assigned to this account
     * Latest invoice is defined by the ID (which is an ever increasing number in the database)
     */
    public get latestInvoice() {
        const platformInvoices = this.account.invoices?.filter((i) => i.type === InvoiceType.Platform);
        if (!platformInvoices || !platformInvoices.length) {
            return undefined;
        }

        const latestInvoice = platformInvoices.reduce((latest, invoice) =>
            invoice.invoiceId > latest.invoiceId ? invoice : latest, platformInvoices[0]);
        return latestInvoice;
    }

    public canResumeSubscriptionWithoutCharge(originalStatus?: SubscriptionStatus) {
        const previousInvoice = this.latestInvoice;

        return (originalStatus ?? this.account.status) === SubscriptionStatus.PendingInactive
            && moment.utc().isBefore(this.account.nextSubscriptionInvoiceDate)
            && (!previousInvoice ||
                Math.floor(previousInvoice.totalCharges) === Math.floor(this.getTotalCost(this.subscriptionCost ?? 0)));
    }
}
