<dx-scroll-view #scrollView
                direction="both"
                showScrollbar="always"
                class="me-1"
                [height]="verticalLaneHeight">
    <div *adaptLoading="loadingItems"
         class="board-container">
        <div *ngFor="let group of itemCollectionGroups; trackBy: itemCollectionGroupTrackBy"
             class="swimlane">
            <h3 *ngIf="columnGroup !== undefined"
                class="swimlane-header"
                data-test="swimlane-header"
                (click)="group.collapsed = !group.collapsed">
                <i class="fal {{group.collapsed ? 'fa-chevron-down' : 'fa-chevron-up'}}"></i>
                <adapt-link-person *ngIf="columnGroup === KanbanGroup.Assignee"
                                   [person]="$any(group.key)"
                                   [showImage]="true"
                                   nullValueText="Unassigned"
                                   imageSize="sm"></adapt-link-person>
                <adapt-display-label *ngIf="columnGroup === KanbanGroup.Label && group.key"
                                     [label]="$any(group.key)"></adapt-display-label>
                <ng-container *ngIf="columnGroup === KanbanGroup.Board && asBoard(group.key) as board">
                    <span class="badge item-code"
                          [class.personal-board]="board.isPersonalBoard">
                        {{board.itemPrefix}}
                        <i *ngIf="board.isPersonalBoard; else privateTeamIcon"
                           class="fal fa-lock-alt"
                           aria-hidden="true"
                           adaptTooltip="Board is only visible to you">
                        </i>
                         <ng-template #privateTeamIcon>
                            <adapt-team-private-icon [team]="board.team"></adapt-team-private-icon>
                        </ng-template>
                    </span>
                    {{board.name}}
                    <!-- assignees are only shown when team board. we only want to show the team link on personal boards -->
                    <span *ngIf="board.team && !showAssignees">(<adapt-link-team [team]="board.team"></adapt-link-team>)</span>
                </ng-container>
                <ng-container *ngIf="columnGroup !== KanbanGroup.Assignee && !group.key">Unassigned</ng-container>
                <span class="text-muted">{{group.itemCount}} action{{group.itemCount !== 1 ? "s" : ""}}</span>
            </h3>

            <div class="vertical-lane-collapse-container"
                 [collapse]="group.collapsed"
                 (collapsed)="updateScrollView()"
                 (expanded)="updateScrollView()"
                 [isAnimated]="true">
                <div class="vertical-lane-container">
                    <div *ngFor="let status of showStatuses; trackBy: statusTrackBy"
                         class="vertical-lane">
                        <div class="lane-header-container">
                            <div class="lane-header">
                                <div class="d-flex align-items-center gap-1">
                                    <span [ngClass]="ItemStatuses[status].iconClass"></span>
                                    <span>{{ItemStatuses[status].name}}</span>
                                </div>
                                <ng-container *ngIf="headerItemsForStatus[status] as headerItems">
                                    <div *ngFor="let headerItem of headerItems; let idx = index"
                                         class="d-flex align-items-center"
                                         id="suggestionSource-{{status}}-{{idx}}"
                                         (mouseenter)="headerItem.tooltipVisible = true">
                                        <i *ngIf="headerItem.icon"
                                           [ngClass]="headerItem.icon"
                                           (mouseenter)="headerItem.tooltipVisible = true"></i>

                                        <span *ngIf="headerItem.badge as badge"
                                              class="badge rounded-1 p-1"
                                              [ngClass]="badge.badgeClass">{{badge.content}}</span>

                                        <ng-container *ngIf="headerItem.tooltip as tooltip">
                                            <!-- Use popover instead of tooltip here to allow more control over clickable content -->
                                            <dx-popover target="#suggestionSource-{{status}}-{{idx}}"
                                                        position="top"
                                                        showEvent="dxpointerenter"
                                                        [enableBodyScroll]="false"
                                                        [hideEvent]="{ name: 'dxpointerleave', delay: 300 }"
                                                        [width]="300"
                                                        [(visible)]="headerItem.tooltipVisible"
                                                        (onContentReady)="onPopoverContentReady($event, headerItem)"
                                                        (onShowing)="popoverCancelHiding = false"
                                                        (onHiding)="onPopoverHiding($event)">
                                                <div *dxTemplate="let content of 'content'">
                                                    <div [froalaView]="tooltip.content"></div>

                                                    <button *ngIf="tooltip.contentButton as btn"
                                                            class="mt-3"
                                                            [ngClass]="btn.buttonClass"
                                                            (click)="btn.onClick?.()"
                                                            [adaptButtonIcon]="btn.iconClass">{{btn.buttonText}}
                                                    </button>
                                                </div>
                                            </dx-popover>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <button *ngIf="status === Backlog"
                                        adaptButton="collapseLeft"
                                        adaptTooltip="Hide backlog column"
                                        (click)="hideBacklog()"></button>
                                <button *ngIf="inMultipleSelectionMode && itemCountForStatus[status] > 0"
                                        class="ms-auto btn btn-link"
                                        [adaptButtonIcon]="hasSelectionForStatus[status] ? 'fal fa-fw fa-square-minus' : 'fal fa-fw fa-square-dashed-circle-plus'"
                                        [adaptTooltip]="isMobileView ? hasSelectionForStatus[status] ? 'Clear selection' : 'Select all' : undefined"
                                        (click)="toggleSelectAll(status)">
                                    <span class="d-none d-md-inline">{{hasSelectionForStatus[status] ? "Clear selection" : "Select all"}}</span>
                                </button>
                            </div>
                        </div>

                        <dx-sortable group="adaptKanban-{{group.keyString}}"
                                     [attr.data-test]="'vertical-lane-' + status"
                                     [class]="getVerticalLaneContentClass(status, group)"
                                     [data]="group.items[status]"
                                     (onDragMove)="onDragMoved($event)"
                                     (onDragStart)="onDragStart($event, group)"
                                     (onDragEnd)="onDragEnd($event, group)"
                                     (onReorder)="onOrderChanged($event)">
                            <adapt-kanban-card *ngFor="let item of group.items[status]; trackBy: itemTrackBy"
                                               [ngClass]="{'selected-item': item === selectedItem}"
                                               (itemSelected)="onItemSelected($event)"
                                               [item]="item"
                                               [columnItems]="group.items[status]"
                                               [showAssignee]="showAssignees"
                                               [highlight]="item.extensions.isOverdue"
                                               (dialogOpened)="itemDialogOpened.emit($event)"
                                               (dialogClosed)="itemDialogClosed.emit($event)"
                                               (contentRendered)="refreshRequired = true; scrollView.instance.update()"
                                               [inMultipleSelectionMode]="inMultipleSelectionMode"
                                               [inMultipleSelection]="checkedItems.includes(item)"
                                               (inMultipleSelectionChange)="inMultipleSelectionChange(item, $event)"
                                               data-test="kanban-item"></adapt-kanban-card>
                        </dx-sortable>
                    </div>
                </div>
            </div>
        </div>

        <div class="ms-3"
             *ngIf="!items?.length">There are no items matching the filter criteria
        </div>
    </div>
</dx-scroll-view>
