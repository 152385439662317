import { Component, Input } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { map, Observable, of, switchMap, tap } from "rxjs";
import { UpdateBillingDetailsDialogComponent } from "../update-billing-details-dialog/update-billing-details-dialog.component";

@Component({
    selector: "adapt-configure-billing-details",
    templateUrl: "./configure-billing-details.component.html",
    styleUrls: ["./configure-billing-details.component.scss"],
})
export class ConfigureBillingDetailsComponent extends ConfigureFeatureBase {
    @Input() public showHeader = true;
    @Input() public configItem?: IConfigItem;

    public account$: Observable<Account | undefined> = of(undefined);

    public contactDetailsSet = false;
    public billingAddressSet = false;

    constructor(
        configurationService: ConfigurationService,
        private accountService: AccountService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(configurationService);
    }

    public shouldShowSaveButtons() {
        return false;
    }

    @Autobind
    public async initialiseData() {
        this.account$ = this.accountService.getAccount();

        this.account$.subscribe((account: Account) => this.updateBillingDetails(account));
    }

    @Autobind
    public showUpdateBillingDetailsDialog() {
        let savedAccount: Account | undefined;
        return this.account$.pipe(
            tap((account) => savedAccount = account),
            switchMap((account) => this.dialogService.open(UpdateBillingDetailsDialogComponent, account)),
            map(() => this.updateBillingDetails(savedAccount!)),
        );
    }

    private updateBillingDetails(account: Account) {
        this.contactDetailsSet = !StringUtilities.isEmpty(account.contactEmail) || !StringUtilities.isEmpty(account.contactName);
        this.billingAddressSet = !StringUtilities.isEmpty(account.contactAddress);
    }
}
