export enum FeatureName {

    CulturalNetworkCulturalLeadershipCohort = "CulturalNetwork.CulturalLeadership.Cohort",
    CulturalNetworkCulturalIndex = "CulturalNetwork.CulturalIndex",
    CulturalNetworkCareerValuation = "CulturalNetwork.CareerValuation",
    CulturalNetworkEmployeeEngagement = "CulturalNetwork.EmployeeEngagement",
    CulturalNetworkTeamAssessment = "CulturalNetwork.TeamAssessment",
    CulturalNetworkResilientBusinessAssessment = "CulturalNetwork.ResilientBusinessAssessment",

    PeoplePeerCatchUp = "People.PeerCatchUp",

    PeopleProfile = "People.Profile",
    PeoplePosition = "People.Position",

    PurposeAndVision = "Compass.GuidingPhilosophy",
    CompassValuesConstitution = "Compass.ValuesConstitution",
    CompassResilientBusinessGoals = "Compass.ResilientBusinessGoals",
    CompassOwnerAlignment = "Compass.OwnerAlignment",

    StewardshipWorkKanban = "Stewardship.Work.Kanban",
    StewardshipWorkMeetings = "Stewardship.Work.Meetings",
    StewardshipWorkCadence = "Stewardship.Work.Cadence",
    StewardshipObjectives = "Stewardship.Objectives",

    OrganisationTeam = "Organisation.Team",
    OrganisationTeamGuidance = "Organisation.Team.Guidance",
    Organisation = "Organisation",
    OrganisationAccessManagement = "Organisation.AccessManagement",
    OrganisationBilling = "Organisation.Billing",
    OrganisationExternalDashboard = "Organisation.ExternalDashboard",
    OrganisationApi = "Organisation.Api",
    OrganisationDiagnostic = "Organisation.Diagnostic",

    ArchitectureTier1 = "Architecture.Tier1",
    ArchitectureTier2 = "Architecture.Tier2",
    ArchitectureValueStreamContents = "Architecture.ValueStreamContents",

    PlatformStandard = "Platform.Standard",

    PlatformActivityFeeds = "Platform.ActivityFeeds",
    PlatformEntitySync = "Platform.EntitySync",
    PlatformBilling = "Platform.Billing",
    PlatformWorkflow = "Platform.Workflow",

    StakeholdersPeople = "Stakeholders.People",

    Bullseye = "Strategy.Bullseye",
    StrategyBoard = "Strategy.StrategyBoard",
    StrategicInputs = "Strategy.StrategicInputs",
    StrategicGoals = "Strategy.StrategicGoals",
    StrategicAnchors = "Strategy.StrategicAnchors",
}
