<h3 class="mt-3">Meeting templates</h3>
<ng-container *adaptLoading="loadingTemplates">
    <adapt-call-to-action-inline *ngIf="agendaTemplates.length < 1; else existingAgendaTemplate">
        <p>
            There are no meeting templates defined for this {{team ? 'team' : 'organisation'}}.
        </p>
    </adapt-call-to-action-inline>
    <ng-template #existingAgendaTemplate>
        <dx-list [dataSource]="agendaTemplates"
                 [hoverStateEnabled]="false"
                 [focusStateEnabled]="false"
                 [activeStateEnabled]="false"
                 class="agenda-items">
            <div *dxTemplate="let template of 'item'"
                 class="d-flex flex-row ps-3">
                <h3 class="align-self-center mb-0">
                    <adapt-display-agenda-name [meetingAgendaTemplate]="template"></adapt-display-agenda-name>
                </h3>
                <div class="ms-auto d-flex align-self-center align-items-center">
                    <button [adaptButton]="template.code ? 'viewBorderless' : 'editBorderless'"
                            [adaptTooltip]="template.code ? 'This is a system-defined template that cannot be edited or deleted.' : 'Edit meeting template'"
                            [adaptBlockingClick]="editAgendaTemplate"
                            [adaptBlockingClickParam]="template"
                            data-test="edit-agenda-template-item"></button>
                    <button class="ms-2"
                            adaptButton="deleteBorderless"
                            adaptTooltip="Delete meeting template"
                            [attr.disabled]="template.code ? true : undefined"
                            [adaptBlockingClick]="deleteAgendaTemplate"
                            [adaptBlockingClickParam]="template"
                            data-test="delete-agenda-template-item"></button>
                </div>
            </div>
        </dx-list>
    </ng-template>
    <button class="mt-3"
            adaptButton="addLink"
            [adaptBlockingClick]="addNewAgendaTemplate"
            data-test="add-agenda-template">Add meeting template
    </button>
</ng-container>
