import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { ModuleFeature } from "./module-feature";

export enum ProductCode {
    Embed = "Embed",
    HQ = "HQ",
}

export enum PlatformCategory {
    Strategy = "Strategy",
    // Culture = 1,
    // Teams = 2,
    // Systems = 3,
    // Leadership = 4,
}

export enum ModuleType {
    Platform = "Platform",
    AddOn = "AddOn",
}

export class Module extends BaseEntity<Module> {
    public moduleId!: number;
    public name!: string;
    public productCode!: ProductCode;
    public platformCategory?: PlatformCategory;
    public moduleType!: ModuleType;
    public ordinal!: number;
    public description?: string;
    public unit?: string;
    public defaultQuantity?: number;
    public maxQuantity?: number;

    public moduleFeatures!: ModuleFeature[];
}

export const ModuleBreezeModel = new BreezeModelBuilder("Module", Module)
    .hasSource()
    .build();
