import { Component } from "@angular/core";
import { IdentityService } from "@common/identity/identity.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { DxTextBoxComponent } from "devextreme-angular";
import { BehaviorSubject, Subject } from "rxjs";
import { ITwoFactorWorkflowRunData } from "../two-factor-workflow";

@WorkflowStepComponent("adapt-two-factor-workflow-confirm-password")
@Component({
    selector: "adapt-two-factor-workflow-confirm-password",
    templateUrl: "./two-factor-workflow-confirm-password.component.html",
})
export class TwoFactorWorkflowConfirmPasswordComponent extends WorkflowStepComponentAdapter {
    public workflowStepCompleted = new BehaviorSubject<boolean>(true);

    public runData?: ITwoFactorWorkflowRunData;
    public password = "";

    public constructor(private identityService: IdentityService) {
        super();
    }

    public workflowStepOnInit() {
        this.runData = this.workflowStep?.workflow?.runData as ITwoFactorWorkflowRunData;
    }

    public validatePassword(passwordInput: DxTextBoxComponent) {
        this.workflowStepCompleted.next(passwordInput.isValid);
    }

    public async workflowStepNext(interruptShortcut: Subject<void>) {
        this.workflowStepErrorMessage.next(undefined);

        if (!this.runData || !this.workflowStep) {
            return;
        }

        try {
            const response = await this.identityService.manageTwoFactor({
                ...this.runData.viewModel,
                password: this.password,
            });

            this.runData.response = response.body ?? undefined;
            this.workflowStep.workflow.runData = this.runData;
        } catch (e) {
            const error = ErrorHandlingUtilities.getHttpResponseMessage(e);
            this.workflowStepErrorMessage.next(error);
            interruptShortcut.next();
        }
    }
}
